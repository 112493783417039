<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header">
          <h1>ตั้งค่าการจัดส่ง</h1>
        </div>
        <div class="custom-card mt-3">
            <h3 class="card-header">ช่องทางการจัดส่ง</h3>
          <div class="card-body">
              <div class="">
                  <div
                    class="custom-control custom-checkbox shipping-checkbox pb-1"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="check_1"
                      v-model="deliveryTypes.atStore"
                      :value="deliveryTypes.atStore"
                    />
                    <label class="custom-control-label" for="check_1">รับที่ร้าน</label>
                  </div>
                  <div
                    class="custom-control custom-checkbox shipping-checkbox pb-1"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="check_2"
                      v-model="deliveryTypes.carrier"
                      :value="deliveryTypes.carrier"
                    />
                    <label class="custom-control-label" for="check_2">บริการจัดส่ง</label>
                  </div>
                </div>
          </div>
        </div>
        <div v-if="deliveryTypes.carrier" class="custom-card mt-3">
            <h5 class="card-header">
                บริการจัดส่ง
            </h5>
          <div class="card-body">
              <div class="form-group row mb-2">
                <label for="" class="col-sm-3">เลือกผู้ให้บริการขนส่ง</label>
                <!-- <div class="col-sm-8">
                  <div v-for="(broker,i) in shippingBrokers" :key="i" class="custom-checkbox shipping-checkbox mr-3 ml-3 form-check-inline">
                    <input type="radio" class="custom-control-input" v-model="shippingType" :value="broker.id" :id="'shipping-'+ i">
                    <label class="custom-control-label pt-1" :for="'shipping-'+ i">ผู้ให้บริการ {{i+1}}</label>
                  </div>
                </div> -->
              </div>
              <div class="form-group mb-2">
                <div class="col-lg-6">
                  <div
                    v-for="(vender, i) in shippingVenders"
                    :key="i"
                    class="custom-control custom-checkbox shipping-checkbox pb-1"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="'vender' + i"
                      v-model="selectShippingVenders"
                      :value="vender.courierCode"
                    />
                    <label class="custom-control-label" :for="'vender' + i">{{
                      vender.name
                    }}</label>
                  </div>
                </div>
                <div class="col-md-12 mt-4">
                  <div class="card-body bg-light">
                    <div
                      class="mb-3"
                      data-toggle="collapse"
                      data-target="#testShipping"
                    >
                      ทดลองคำนวณค่าจัดส่งสินค้าแบบอัตโนมัติ
                      <span class="text-muted"
                        >(การคำนวณค่าจัดส่งนี้มีผลในการตรวจสอบเป็นไปตามเงื่อนไขของระบบขึ้นอยู่กับ
                        น้ำหนัก ขนาดสินค้า ที่อยู่ต้นทางและปลายทาง)</span
                      >
                    </div>
                    <div id="testShipping" class="row collapse">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <label for="" class="col-sm-4">น้ำหนัก</label>
                          <div class="col-sm-8">
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="น้ำหนัก"
                                v-model.number="shippingTest.weight"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text">g</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="" class="col-sm-4"
                            >ขนาดสินค้า (กxยxส)</label
                          >
                          <div class="col-sm-8">
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="กว้าง"
                                v-model.number="shippingTest.width"
                              />
                              <input
                                type="text"
                                class="form-control"
                                placeholder="ยาว"
                                v-model.number="shippingTest.length"
                              />
                              <input
                                type="text"
                                class="form-control"
                                placeholder="สูง"
                                v-model.number="shippingTest.height"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text">cm</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="form-group row">
                            <label for="" class="col-sm-4">จำนวนสินค้า</label>
                            <div class="col-sm-8">
                              <input type="number" class="form-control" v-model.number="shippingTest.quantity" placeholder="จำนวน / ชิ้น">
                            </div>
                          </div> -->
                        <div class="form-group row">
                          <label for="" class="col-sm-4"
                            >รหัสไปรษณีย์ต้นทาง</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="number"
                              class="form-control"
                              v-model.number="shippingTest.fromZipcode"
                              placeholder="รหัสไปรษณีย์ต้นทาง"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="" class="col-sm-4"
                            >รหัสไปรษณีย์ปลายทาง</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="number"
                              class="form-control"
                              v-model.number="shippingTest.toZipcode"
                              placeholder="รหัสไปรษณีย์ปลายทาง"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="" class="col-sm-4"
                            >ค่าบริการบวกเพิ่ม</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="number"
                              class="form-control"
                              v-model.number="shippingTest.addPrice"
                              placeholder="ค่าบริการบวกเพิ่ม"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-sm-8 offset-sm-4 text-center">
                            <button
                              class="btn btn-primary"
                              @click.prevent="getShippingPrice"
                              :disabled="onCalculated"
                            >
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                v-show="onCalculated"
                              ></span>
                              คำนวณค่าจัดส่ง
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card-body bg-white">
                          <div class="row mb-0">
                            <div
                              v-for="(shipping,
                              index) in calculatedShippingPrice"
                              :key="index"
                              class="col-12 font-weight-normal"
                            >
                              {{ shipping.name }}
                              <div
                                class="col-md-2 text-orange float-right text-right"
                              >
                                {{ shipping.price }}
                                <span class="text-muted">฿</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-2 mt-4">
                <label for="" class="col-12"
                  >ตั้งค่ารูปแบบการส่งสินค้าภายในประเทศ</label
                >
                <div class="col-lg-12 mt-2">
                  <div
                    class="custom-control custom-checkbox shipping-checkbox pb-1"
                  >
                    <input
                      type="radio"
                      class="custom-control-input"
                      id="shippingSettingPrice1"
                      value="1"
                      v-model="shippingSettingPrice"
                    />
                    <label
                      class="custom-control-label pt-1"
                      for="shippingSettingPrice1"
                      >ฟรี ไม่เก็บค่าจัดส่ง</label
                    >
                  </div>
                  <div
                    class="custom-control custom-checkbox shipping-checkbox pb-1"
                  >
                    <input
                      type="radio"
                      class="custom-control-input"
                      id="shippingSettingPrice2"
                      value="2"
                      v-model="shippingSettingPrice"
                    />
                    <label
                      class="custom-control-label pt-1"
                      for="shippingSettingPrice2"
                      >กำหนดค่าส่งพัสดุแบบตายตัว</label
                    >
                    <div v-if="shippingSettingPrice == 2" class="d-flex">
                      <span class="arrow-drop"></span>
                      <div class="d-flex form-group">
                        <div class="input-group col-md-10">
                          <input
                            v-model="input.type2.price"
                            type="number"
                            class="form-control text-right"
                            placeholder="ราคา"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text">฿</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="custom-control custom-checkbox shipping-checkbox pb-1"
                  >
                    <input
                      type="radio"
                      class="custom-control-input"
                      id="shippingSettingPrice3"
                      value="3"
                      v-model="shippingSettingPrice"
                    />
                    <label
                      class="custom-control-label pt-1"
                      for="shippingSettingPrice3"
                      >กำหนดค่าส่งพัสดุแปรผันตามจำนวนสินค้า (คิดเป็นชิ้น)</label
                    >
                    <div v-if="shippingSettingPrice == 3" class="row">
                      <div class="col-md-12">
                        <div class="d-flex">
                          <span class="arrow-drop"></span>
                          <span class="align-self-center col-md-2 pr-0"
                            >ค่าส่งสินค้าชิ้นแรก</span
                          >
                          <div class="input-group col-md-3">
                            <input
                              v-model="input.type3.first"
                              type="number"
                              class="form-control text-right"
                              placeholder="ราคา"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">฿</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="d-flex">
                          <span class="arrow-drop"></span>
                          <span class="align-self-center col-md-2 pr-0"
                            >ค่าส่งสินค้าชิ้นถัดไป</span
                          >
                          <div class="input-group col-md-3">
                            <input
                              v-model="input.type3.next"
                              type="number"
                              class="form-control text-right"
                              placeholder="ราคา"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">฿/ชิ้น</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="custom-control custom-checkbox shipping-checkbox pb-1"
                  >
                    <input
                      type="radio"
                      class="custom-control-input"
                      id="shippingSettingPrice4"
                      value="4"
                      v-model="shippingSettingPrice"
                    />
                    <label
                      class="custom-control-label pt-1"
                      for="shippingSettingPrice4"
                      >กำหนดค่าส่งพัสดุแปรผันตามน้ำหนักสินค้า</label
                    >
                    <div v-if="shippingSettingPrice == 4" class="row">
                      <div
                        v-for="(input4, index) in input.type4"
                        :key="index"
                        class="col-md-12 mb-3"
                      >
                        <div class="d-flex">
                          <span class="arrow-drop"></span>
                          <span class="align-self-center col-md-1 pr-0"
                            >น้อยกว่า</span
                          >
                          <div class="input-group col-md-2">
                            <input
                              v-model="input4.weight"
                              type="number"
                              class="form-control text-right"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">g</span>
                            </div>
                          </div>
                          <span
                            class="align-self-center col-md-1 pr-0 text-right"
                            >ราคา</span
                          >
                          <div class="input-group col-md-2">
                            <input
                              v-model="input4.price"
                              type="number"
                              class="form-control text-right"
                              placeholder="ราคา"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">฿</span>
                            </div>
                          </div>
                          <span
                            v-if="input.type4.length > 1"
                            @click="removeShippingType4(index)"
                            class="align-self-center ml-3 pointer"
                            ><i class="fas fa-times-circle fa-lg"></i
                          ></span>
                          <span
                            v-if="index + 1 == input.type4.length"
                            @click="addShippingType4"
                            class="align-self-center ml-3 pointer"
                            ><i class="fas fa-plus-circle fa-lg"></i
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="custom-card mt-3">
          <div class="card-body">
              <div class="col-12 text-center">
                <button
                  class="btn btn-gradient-primary btn-lg"
                  @click.prevent="updateShippingStore"
                >
                  บันทึก
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "delivery",
  components: {},
  props: {},
  data() {
    return {
      deliveryTypes: {
        atStore: false,
        carrier: false,
      },
      editSet: {
        price: undefined,
        discountPrice: undefined,
        discountPercent: undefined,
        quantity: undefined,
        update: false,
        cost: undefined,
      },
      shippingVenders: [],
      shippingSettingPrice: undefined,
      selectShippingVenders: [],
      shipping: {
        weight: 0,
        width: 0,
        length: 0,
        height: 0,
      },
      shippingType: 1,
      selectedAutoShipping: [],
      shippingTest: {
        quantity: 1,
        fromZipcode: "10400",
        toZipcode: "10400",
        addPrice: 0,
        weight: 200,
        width: 31,
        length: 36,
        height: 26,
      },
      input: {
        type1: { price: 0 },
        type2: { price: 0 },
        type3: {
          first: 0,
          next: 0,
        },
        type4: [{ weight: 0, price: 0 }],
      },
      shippingPrice: {},
      shippingVenders: [],
      selectedManualShipping: [],
      onCalculated: false,
      listSkus: [],
      defaultCheckedKeys: [],
      defaultExpandedKeys: Array(),
      setSku1: false,
      setSku2: false,
      totalShipping: false,
      categoriesSelect: [],
    };
  },
  created() {},
  mounted() {
    this.shippingSetting();
  },
  methods: {
    shippingSetting() {
      //shippingVenders
      axios.get("/api/admin/shippings").then((result) => {
        let data = result.data.data;
        this.shippingVenders = data;
      });
      axios.get("/api/admin/shipping/store").then((result) => {
        let data = result.data.data;
        this.deliveryTypes.atStore = data.deliveryTypes.atStore
        this.deliveryTypes.carrier = data.deliveryTypes.carrier
        this.shippingType = data.serviceId;
        this.shippingSettingPrice = data.id;
        this.selectShippingVenders = data.shippings;

        if (data.id == 1) {
          this.input.type1 = { price: 0 };
        } else if (data.id == 2) {
          this.input.type2 = data.type2;
        } else if (data.id == 3) {
          this.input.type3 = data.type3;
        } else if (data.id == 4) {
          if (data.type4.length < 1) {
            this.input.type4 = [{ weight: 0, price: 0 }];
          } else {
            this.input.type4 = data.type4;
          }
        }
      });
    },
    calculatedShippingPrice() {
      let prices = {
        cj: 0,
        dhl: 0,
        thp2: 0,
        tpp2: 0,
      };
      let qty = this.shippingTest.quantity > 0 ? this.shippingTest.quantity : 1;
      let charge =
        this.shippingTest.addPrice > 0 ? this.shippingTest.addPrice : 0;
      if (this.shippingPrice.CJ) {
        prices.cj = this.shippingPrice.CJ.total_price * qty + charge;
      }
      if (this.shippingPrice.DHL) {
        prices.dhl = this.shippingPrice.DHL.total_price * qty + charge;
      }
      if (this.shippingPrice.THP2) {
        prices.thp2 = this.shippingPrice.THP2.total_price * qty + charge;
      }
      if (this.shippingPrice.TPP2) {
        prices.tpp2 = this.shippingPrice.TPP2.total_price * qty + charge;
      }
      return prices;
    },
    getShippingPrice() {
      this.onCalculated = true;
      let parcel = {
        weight: this.shippingTest.weight,
        width: this.shippingTest.width,
        length: this.shippingTest.length,
        height: this.shippingTest.height,
      };
      let shippings = this.shippingVenders.map((ven) => ven.courierCode);
      let postcodeFrom = this.shippingTest.fromZipcode;
      let postcodeTo = this.shippingTest.toZipcode;
      let formData = {
        shippings,
        parcel,
        postcodeFrom,
        postcodeTo,
      };
      axios
        .post("/api/admin/shippings/price", formData, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((result) => {
          this.calculatedShippingPrice = result.data.data;
        })
        .catch((error) => this.$notify.error(error.response.errors.message))
        .finally(() => (this.onCalculated = false));
    },
    updateShippingStore() {
      let form = {};
      form["shippings"] = this.selectShippingVenders;
      let shippingPrice = {};
      if (this.shippingSettingPrice == 1) {
        shippingPrice.type1 = {};
      } else if (this.shippingSettingPrice == 2) {
        shippingPrice.type2 = this.input.type2;
      } else if (this.shippingSettingPrice == 3) {
        shippingPrice.type3 = this.input.type3;
      } else if (this.shippingSettingPrice == 4) {
        shippingPrice.type4 = this.input.type4;
      } else {
        this.$notify({
          title: "แจ้งเตือน!",
          message: "กรุณาเลือก ตั้งค่ารูปแบบการส่งสินค้า",
          type: "warning",
        });
        return;
      }
      shippingPrice.serviceId = this.shippingType;
      shippingPrice.id = Number(this.shippingSettingPrice);
      form["shippingPrice"] = shippingPrice;
      form["deliveryTypes"] = this.deliveryTypes;
      console.log(form);
      axios
        .patch("/api/admin/delivery", form)
        .then((res) => {
          this.$notify.success("อัพเดทสำเร็จ");
        })
        .catch((error) => {
          let message = error.response.data.errors.snakeCase;
          this.$notify({
            title: "Error",
            message: message,
            type: "error",
          });
        });
    },
  },
};
</script>