import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  base: __dirname,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    { path: "/", redirect: "/admin/home" },
    {
      path: "/admin",
      redirect: { name: "admins.index" },
      component: require("./views/Layout/Admin").default,
      children: [
        {
          path: "home",
          name: "admins.index",
          component: require("./views/Dashboard").default
        },
        {
          path: "categories",
          name: "categories.index",
          component: require("./views/Category").default
        },
        {
          path: "admins",
          name: "admins.list",
          component: require("./views/Admin").default
        },
        {
          path: "admins/create",
          name: "admins.create",
          component: require("./views/Admin/Create").default
        },
        {
          path: "admins/:id/edit",
          name: "admins.edit",
          component: require("./views/Admin/Edit").default
        },
        {
          path: "products",
          name: "products.index",
          component: require("./views/Product/List").default
        },
        {
          path: "products/:id/edit",
          name: "products.edit",
          component: require("./views/Product/Edit").default
        },
        {
          path: "products/create",
          name: "products.create",
          component: require("./views/Product/Create").default
        },
        {
          path: "settings/banner",
          name: "settings.banner",
          component: require("./views/Setting/Banner").default
        },
        {
          path: "settings/website",
          name: "settings.website",
          component: require("./views/Setting/Site").default
        },
        {
          path: "settings/social",
          name: "settings.social",
          component: require("./views/Setting/Social").default
        },
        {
          path: "settings/contact",
          name: "settings.contact",
          component: require("./views/Setting/Contact").default
        },
        {
          path: "settings/payments",
          name: "settings.payment",
          component: require("./views/Setting/Payment").default
        },
        {
          path: "settings/privacy",
          name: "settings.privacy",
          component: require("./views/Setting/Privacy").default
        },
        {
          path: "settings/address",
          name: "settings.address",
          component: require("./views/Setting/Address").default
        },
        {
          path: "users",
          name: "users.index",
          component: require("./views/User/").default
        },
        {
          path: "users/:id",
          name: "users.show",
          component: require("./views/User/Show").default
        },
        {
          path: "contacts",
          name: "contacts.index",
          component: require("./views/Contact/").default
        },
        {
          path: "themes",
          name: "themes.index",
          component: require("./views/Theme/").default
        },
        {
          path: "orders",
          name: "orders.index",
          component: require("./views/Order/").default
        },
        {
          path: "orders/:id",
          name: "orders.show",
          component: require("./views/Order/Show").default
        },
        {
          path: "delivery",
          name: "delivery.index",
          component: require("./views/Delivery/index").default
        },
        {
          path: "promotion",
          name: "promotion.index",
          component: require("./views/Promotion/index").default
        },
        {
          path: "promotion/create",
          name: "promotion.create",
          component: require("./views/Promotion/Create").default
        }
      ]
    },
    {
      path: "/guide",
      name: "guide",
      component: require("./views/Guide/Guide").default
    },
    {
      path: "/admin/orders/:id/print",
      name: "orders.print",
      component: require("./views/Order/Print").default,
      meta: { guest: true }
    },
    {
      path: "/admin/contents",
      name: "admins.contents.index",
      component: require("./views/Content/index.vue").default
    },
    {
      path: "/admin/login",
      name: "login",
      component: require("./views/Login/").default,
      meta: { guest: true }
    },
    {
      path: "/admin/password/reset/:token",
      name: "reset",
      component: require("./views/Password/reset.vue").default,
      meta: { guest: true }
    },
    { path: "_=_", redirect: "/" }
  ]
});

router.beforeEach((to, from, next) => {
  if (!to.meta.guest && localStorage.getItem("token") === null) {
    next({ name: "login" });
  }
  next();
});

export default router;
