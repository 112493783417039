window._ = require("lodash");

try {
  window.Popper = require("popper.js").default;
  require("bootstrap/dist/js/bootstrap");
} catch (e) {}

window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
if (process.env.NODE_ENV === "production") {
  window.axios.defaults.baseURL = window.location.origin;
}
let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
  window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
  console.error(
    "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
  );
}

window.UIModal = () => {
  $(".js-modal-btn").on("click", function() {
    var target = $(this).data("target");
    $(target).modal("show");
  });
};
