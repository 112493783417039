<template>
  <section class="admin-section">
    <div class="inner-wrap container bg white pt-4 pb-4 item-hover">
      <div class="admin-btns admin-btns--sectionedit" @click="">
        <a class="ui button white basic d-block mt-1 mb-1 d-xl-flex js-modal-btn" @click.prevent="$parent.openModal(`#titleModal-${id}`)">
          <i class="d-none d-xl-block edit outline icon"></i>แก้ไข
        </a>
        <a class="ui button white basic d-block mt-1 mb-1 d-xl-flex js-modal-btn" @click.prevent="handleDeleteComponentModal">
          <i class="d-none d-xl-block trash alternate outline icon"></i>ลบ
        </a>
      </div>
      <a class="admin-btns admin-btns--add-section-top circular ui icon button blue js-modal-btn" @click.prevent="$parent.showSectionModal(id, 'top')">
        <i class="plus icon"></i>
      </a>
      <a class="admin-btns admin-btns--add-section-bottom circular ui icon button blue js-modal-btn" @click.prevent="$parent.showSectionModal(id, 'bottom')">
        <i class="plus icon"></i>
      </a>
      <div class="row d-block">
        <div class="content p-lg-5 p-1">
          <div v-if="hasTitle" class="text-center">
            <h3 class="ui header big-xl large-md medium-sm tiny color gray-4">{{ themeData.title }}</h3>
            <p class="text-center ui text light huge-xl big-md medium-sm tiny color gray-9" style="font-weight: 600;">{{ themeData.detail }}</p>
          </div>
          <img class="w-100" src="/imgs/contents/admin-content-2.svg" v-else>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="`titleModal-${id}`" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title color black h3">แก้ไขหัวข้อและรายละเอียด</p>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="ui form col-12">
              <div class="mt-2 mb-2 align-items-center">
                <div><label class="ui text large color black">หัวข้อ<span class="color gray-9">(เช่น สินค้าใหม่,สินค้าแนะนำ,สินค้าขายดี)</span><span class="color red-1">*</span></label></div>
                <div>
                  <input type="text" v-model="edit.title">
                </div>
              </div>
              <div class="mt-4 mb-2 align-items-center">
                <div><label class="ui text large color black">รายละเอียด<span class="color gray-9">(40 ตัวอักษร)</span><span class="color red-1">*</span></label></div>
                <div>
                  <textarea v-model="edit.detail"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a class="ui button red basic text" data-><span class="ui text large">ยกเลิก</span></a>
            <button class="ui button blue text d-flex align-items-center" @click.prevent="updateData" :disabled="onSubmit">
              <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" v-show="onSubmit"></span>
              <span class="ui text large">บันทึก</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'sectionTitle',
    components: {},
    props: {
      themeData: {
        type: Object,
        default: () => ({title: undefined, detail: undefined})
      },
      id: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        edit: {
          title: '',
          detail: ''
        },
        onSubmit: false
      }
    },
    computed: {
      hasTitle() {
        if (this.themeData) {
          return this.themeData.title != null
        } else {
          return false
        }
      }
    },
    created () {
      this.setFormData()
    },
    methods: {
      updateData() {
        this.onSubmit = true
        let form = new FormData()
        form.append('data[title]', this.edit.title)
        form.append('data[detail]', this.edit.detail)
        form.append('_method', 'patch')

        axios.post(`/api/admin/themes/${this.id}`, form)
          .then((res) => {
            // this.onSubmit = false
            this.$parent.getTheme()
            $(`#titleModal-${this.id}`).modal('hide')
          })
          .catch(err => {
            this.$message.error('มีข้อผิดพลาดบางอย่างเกิดขึ้น กรุณาลองใหม่อีกครั้ง')
          }).finally(() => this.onSubmit = false)
      },
      async deleteTheme() {
        let confirm = await this.$confirm('ต้องการลบส่วนเนื้อหาหรือไม่ ?')
        if (confirm) {
          this.$emit('onDelete', this.id)
        }
      },
      setFormData () {
        if (this.hasTitle) {
          this.edit.title = this.themeData.title
          this.edit.detail = this.themeData.detail
        }
      },
      handleDeleteComponentModal () {
        this.$emit('onDelete', this.id)
        this.$parent.openModal('#deleteSectionModal')
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
