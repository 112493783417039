var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "custom-card mt-3" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6 mx-auto" }, [
                _c("form", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search,
                        expression: "search"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "ค้นหาสินค้า" },
                    domProps: { value: _vm.search },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.search = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group-append" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.searchProduct($event)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-search" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.resetSearch($event)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fas fa-redo mr-1" }),
                        _vm._v("ล้างค่า\n                  ")
                      ]
                    )
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "table-responsive" },
              [
                _c(
                  "table",
                  { staticClass: "table table-center table-bordered" },
                  [
                    _c("thead", [
                      _c("tr", { staticClass: "bg-light" }, [
                        _c(
                          "th",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "border-right-color": "transparent",
                              "vertical-align": "middle"
                            }
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectAll,
                                  expression: "selectAll"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.selectAll)
                                  ? _vm._i(_vm.selectAll, null) > -1
                                  : _vm.selectAll
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.selectAll,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selectAll = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selectAll = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selectAll = $$c
                                  }
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          { staticClass: "text-left", attrs: { colspan: "8" } },
                          [
                            _c("div", { staticClass: "d-flex" }, [
                              _c("div", [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn btn-outline-danger btn-sm mr-3",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.deleteManyProduct($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-trash-alt mr-1"
                                    }),
                                    _vm._v("ลบ\n                        ")
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "select",
                                  {
                                    staticClass:
                                      "custom-select custom-select-sm",
                                    on: { change: _vm.toggleEnableManyProduct }
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { selected: "", disabled: "" } },
                                      [_vm._v("กรุณาเลือก")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      { domProps: { value: true } },
                                      [_vm._v("แสดงสินค้า")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      { domProps: { value: false } },
                                      [_vm._v("ไม่แสดงสินค้า")]
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm._m(1)
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.laravelData.data, function(product) {
                        return _c(
                          "tr",
                          { key: product.id, staticClass: "text-center" },
                          [
                            _c("td", { staticClass: "text-center" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedProduct,
                                    expression: "selectedProduct"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: product.id,
                                  checked: Array.isArray(_vm.selectedProduct)
                                    ? _vm._i(_vm.selectedProduct, product.id) >
                                      -1
                                    : _vm.selectedProduct
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.selectedProduct,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = product.id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.selectedProduct = $$a.concat([
                                            $$v
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.selectedProduct = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.selectedProduct = $$c
                                    }
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              product.images.length > 0
                                ? _c("span", {
                                    staticClass: "avatar avatar-md",
                                    style:
                                      "background-image: url(" +
                                      product.images[0].url +
                                      ")"
                                  })
                                : _c(
                                    "span",
                                    { staticClass: "avatar avatar-md" },
                                    [_vm._v("P")]
                                  )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(product.titleTH))]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-success" }, [
                              _vm._v(_vm._s(product.netPrice.toLocaleString()))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("s", { staticClass: "text-dark" }, [
                                _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(product.price.toLocaleString()))
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(product.discountPercent) + "%")
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(product.quantity))]),
                            _vm._v(" "),
                            _c("td", [
                              product.enabled
                                ? _c(
                                    "span",
                                    { staticClass: "badge badge-success" },
                                    [_vm._v("แสดงสินค้า")]
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "badge badge-dark" },
                                    [_vm._v("ไม่แสดงสินค้า")]
                                  )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-center" }, [
                              _c("div", { staticClass: "dropdown" }, [
                                _vm._m(2, true),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown-menu dropdown-menu-right"
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "dropdown-item text-primary",
                                        attrs: {
                                          to:
                                            "/admin/products/" +
                                            product.id +
                                            "/edit"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-edit mr-1"
                                        }),
                                        _vm._v(
                                          "แก้ไข\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "dropdown-item text-danger",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            _vm.deleteProduct(product.id)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-trash-alt mr-1"
                                        }),
                                        _vm._v("ลบ")
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _vm._v(" "),
                _c("pagination", {
                  staticClass: "justify-content-end",
                  attrs: { data: _vm.laravelData },
                  on: { "pagination-change-page": _vm.getResults }
                })
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h1", [_vm._v("รายการสินค้า")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "bg-light text-center" }, [
      _c("th"),
      _vm._v(" "),
      _c("th", [_vm._v("รูปสินค้า")]),
      _vm._v(" "),
      _c("th", [_vm._v("ชื่อสินค้า")]),
      _vm._v(" "),
      _c("th", [_vm._v("ราคาขาย (บาท)")]),
      _vm._v(" "),
      _c("th", [_vm._v("ราคาปกติ (บาท)")]),
      _vm._v(" "),
      _c("th", [_vm._v("ส่วนลด")]),
      _vm._v(" "),
      _c("th", [_vm._v("จำนวนสต๊อค")]),
      _vm._v(" "),
      _c("th", [_vm._v("สถานะสินค้า")]),
      _vm._v(" "),
      _c("th", [_vm._v("จัดการ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "link-unstyled border-0",
        attrs: { href: "#", "data-toggle": "dropdown" }
      },
      [
        _c("span", { staticClass: "avatar avatar-sm" }, [
          _c("i", { staticClass: "fas fa-ellipsis-h" })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }