var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex" }, [
          _c("div", { staticClass: "form-group" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("input", {
              staticClass: "d-none",
              attrs: { type: "file", id: "logoInput" },
              on: { change: _vm.handleUploadLogoImage }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-3" }, [
            _vm.logoImage
              ? _c("figure", { staticClass: "overlay" }, [
                  _c("img", { attrs: { src: _vm.logoImage, height: "160px" } }),
                  _vm._v(" "),
                  _c(
                    "figcaption",
                    { staticClass: "delete logo-delete cursor" },
                    [
                      _c("i", {
                        staticClass: "fas fa-times-circle text-danger fa-2x",
                        on: { click: _vm.deleteLogoImage }
                      })
                    ]
                  )
                ])
              : _c("div", { staticClass: "card card-body no-logo-image" }, [
                  _c("i", { staticClass: "far fa-image fa-10x" })
                ])
          ])
        ]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("div", [
          _c(
            "label",
            {
              ref: "file",
              staticClass: "btn btn-primary",
              attrs: { for: "bannerInput", role: "button" }
            },
            [
              _c("i", { staticClass: "far fa-images mr-1" }),
              _vm._v("อัพโหลดไฟล์\n        ")
            ]
          ),
          _vm._v(" "),
          _c("input", {
            ref: "files",
            staticClass: "d-none",
            attrs: { type: "file", id: "bannerInput" },
            on: { change: _vm.handleUploadBannerImage }
          }),
          _vm._v(" "),
          _vm.hasBannerImage
            ? _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.banners, function(banner) {
                  return _c("div", { staticClass: "col-md-4 col-sm-12 mt-3" }, [
                    _c("figure", { staticClass: "overlay" }, [
                      _c("img", {
                        staticClass: "img-fluid banner-image",
                        attrs: { src: banner.url }
                      }),
                      _vm._v(" "),
                      _c(
                        "figcaption",
                        { staticClass: "delete cursor banner-delete" },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-times-circle text-danger fa-2x",
                            on: {
                              click: function($event) {
                                _vm.deleteBannerImage(banner.id)
                              }
                            }
                          })
                        ]
                      )
                    ])
                  ])
                }),
                0
              )
            : _c("div", [_vm._m(3)])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h1", [
        _vm._v(
          "\n          อัพโหลดโลโก้ รูปภาพ, ไฟล์ .JPG หรือ .PNG (ขนาดแนะนำ 200x200 pixel)\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "btn btn-primary",
        attrs: { for: "logoInput", role: "button" }
      },
      [
        _c("i", { staticClass: "far fa-images mr-1" }),
        _vm._v("อัพโหลดไฟล์\n          ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h1", [
        _vm._v(
          "\n          อัพโหลดแบนเนอร์ ไฟล์ .JPG หรือ .PNG (ขนาดแนะนำ 1923 x 954 pixel)\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card card-body border-0" }, [
      _c("i", {
        staticClass: "far fa-image fa-10x no-logo-image align-self-center"
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }