var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.loading,
          expression: "!loading"
        }
      ],
      staticClass: "admin-preview"
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "admin-preview-container" },
        [
          _c("header", { staticClass: "admin-preview__header admin-section" }, [
            _c("div", { staticClass: "inner-wrap container" }, [
              _c(
                "div",
                {
                  staticClass:
                    "[ ui borderless main ] [ m-0 pt-lg-4 pt-2 pb-lg-4 d-block ]"
                },
                [
                  _c("div", { staticClass: "[ align-items-center d-flex ]" }, [
                    _c("div", { staticClass: "d-block d-lg-none col-8" }, [
                      _c(
                        "div",
                        {
                          staticClass: "thumb--logo item item-hover",
                          class: { show: _vm.logoImage }
                        },
                        [
                          _c("div", { staticClass: "admin-item-btns" }, [
                            _c(
                              "div",
                              { staticClass: "admin-item-btns d-flex" },
                              [
                                _c("div", { staticClass: "d-flex" }, [
                                  _vm._m(1),
                                  _vm._v(" "),
                                  _vm._m(2),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn",
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          _vm.openModal("#deleteLogoModal")
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "trash alternate outline icon"
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    staticClass: "d-none",
                                    attrs: { type: "file", id: "logoImage" },
                                    on: { change: _vm.handleUploadLogoImage }
                                  })
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "w-100 d-block",
                            attrs: { src: _vm.logoImage }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col col-lg-3" }, [
                      _c(
                        "div",
                        {
                          staticClass: "thumb--logo item item-hover",
                          class: { show: _vm.logoImage }
                        },
                        [
                          _c("div", { staticClass: "admin-item-btns" }, [
                            _c(
                              "div",
                              { staticClass: "admin-item-btns d-flex" },
                              [
                                _c("div", { staticClass: "d-flex" }, [
                                  _vm._m(3),
                                  _vm._v(" "),
                                  _vm._m(4),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn",
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          _vm.openModal("#deleteLogoModal")
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "trash alternate outline icon"
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    staticClass: "d-none",
                                    attrs: {
                                      type: "file",
                                      id: "logoImage-mobile"
                                    },
                                    on: { change: _vm.handleUploadLogoImage }
                                  })
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "w-100 d-block",
                            attrs: { src: _vm.logoImage }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm._m(5)
                  ]),
                  _vm._v(" "),
                  _vm._m(6),
                  _vm._v(" "),
                  _vm._m(7)
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.themes, function(theme) {
            return [
              _c(theme.components.name, {
                tag: "component",
                attrs: {
                  "theme-data": theme.data,
                  categories: _vm.categories,
                  id: theme.id
                },
                on: { onDelete: _vm.setCurrentDeleteComponent }
              })
            ]
          }),
          _vm._v(" "),
          _vm._m(8),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal fade",
              attrs: { role: "dialog", tabindex: "-1", id: "addSectionModal" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-dialog modal-lg",
                  attrs: { role: "document" }
                },
                [
                  _c("div", { staticClass: "modal-content" }, [
                    _vm._m(9),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 steps-list" }, [
                          _c(
                            "div",
                            { staticClass: "row align-items-center mt-2" },
                            [
                              _vm._m(10),
                              _vm._v(" "),
                              _c("div", { staticClass: "col ui checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.sectionModalData.selectComponent,
                                      expression:
                                        "sectionModalData.selectComponent"
                                    }
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "step-style",
                                    value: "section-title"
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.sectionModalData.selectComponent,
                                      "section-title"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.$set(
                                        _vm.sectionModalData,
                                        "selectComponent",
                                        "section-title"
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm._m(11)
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row align-items-center mt-2" },
                            [
                              _vm._m(12),
                              _vm._v(" "),
                              _c("div", { staticClass: "col ui checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.sectionModalData.selectComponent,
                                      expression:
                                        "sectionModalData.selectComponent"
                                    }
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "step-style",
                                    value: "product-list1"
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.sectionModalData.selectComponent,
                                      "product-list1"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.$set(
                                        _vm.sectionModalData,
                                        "selectComponent",
                                        "product-list1"
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm._m(13)
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row align-items-center mt-2" },
                            [
                              _vm._m(14),
                              _vm._v(" "),
                              _c("div", { staticClass: "col ui checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.sectionModalData.selectComponent,
                                      expression:
                                        "sectionModalData.selectComponent"
                                    }
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "step-style",
                                    value: "product-list2"
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.sectionModalData.selectComponent,
                                      "product-list2"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.$set(
                                        _vm.sectionModalData,
                                        "selectComponent",
                                        "product-list2"
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm._m(15)
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row align-items-center mt-2" },
                            [
                              _vm._m(16),
                              _vm._v(" "),
                              _c("div", { staticClass: "col ui checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.sectionModalData.selectComponent,
                                      expression:
                                        "sectionModalData.selectComponent"
                                    }
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "step-style",
                                    value: "product-list3"
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.sectionModalData.selectComponent,
                                      "product-list3"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.$set(
                                        _vm.sectionModalData,
                                        "selectComponent",
                                        "product-list3"
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm._m(17)
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row align-items-center mt-2" },
                            [
                              _vm._m(18),
                              _vm._v(" "),
                              _c("div", { staticClass: "col ui checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.sectionModalData.selectComponent,
                                      expression:
                                        "sectionModalData.selectComponent"
                                    }
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "step-style",
                                    value: "product-list4"
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.sectionModalData.selectComponent,
                                      "product-list4"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.$set(
                                        _vm.sectionModalData,
                                        "selectComponent",
                                        "product-list4"
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm._m(19)
                              ])
                            ]
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _vm._m(20),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "ui button blue text",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onUserSelectedSection($event)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "ui text large" }, [
                            _vm._v("บันทึก")
                          ])
                        ]
                      )
                    ])
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal fade",
              attrs: {
                role: "dialog",
                tabindex: "-1",
                id: "deleteSectionModal"
              }
            },
            [
              _c(
                "div",
                { staticClass: "modal-dialog", attrs: { role: "document" } },
                [
                  _c("div", { staticClass: "modal-content" }, [
                    _vm._m(21),
                    _vm._v(" "),
                    _vm._m(22),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _vm._m(23),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "ui button blue text",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleDeleteComponent($event)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "ui text large" }, [
                            _vm._v("บันทึก")
                          ])
                        ]
                      )
                    ])
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal fade",
              attrs: { role: "dialog", tabindex: "-1", id: "deleteLogoModal" }
            },
            [
              _c(
                "div",
                { staticClass: "modal-dialog", attrs: { role: "document" } },
                [
                  _c("div", { staticClass: "modal-content" }, [
                    _vm._m(24),
                    _vm._v(" "),
                    _vm._m(25),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _vm._m(26),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "ui button blue text",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleDeleteLogoImage($event)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "ui text large" }, [
                            _vm._v("บันทึก")
                          ])
                        ]
                      )
                    ])
                  ])
                ]
              )
            ]
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container pt-2 pl-0" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-gradient-primary btn-lg",
          attrs: { href: "/admin/home" }
        },
        [_c("i", { staticClass: "fas fa-home mr-1" }), _vm._v("กลับหน้าหลัก")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass:
          "circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn",
        attrs: { for: "logoImage" }
      },
      [_c("i", { staticClass: "plus icon color theme" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass:
          "circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn",
        attrs: { for: "logoImage" }
      },
      [_c("i", { staticClass: "edit outline icon" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass:
          "circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn",
        attrs: { for: "logoImage-mobile" }
      },
      [_c("i", { staticClass: "plus icon color theme" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass:
          "circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn",
        attrs: { for: "logoImage-mobile" }
      },
      [_c("i", { staticClass: "edit outline icon" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "[ d-lg-flex d-none d-lg-block align-items-center ml-auto ]"
      },
      [
        _c("span", { staticClass: "ui text medium" }, [
          _vm._v("Currency:\n                "),
          _c("div", { staticClass: "[ ui inline dropdown ] [ ml-1 ]" }, [
            _c("input", { attrs: { type: "hidden", name: "Currency" } }),
            _vm._v(" "),
            _c("div", { staticClass: "text" }, [_vm._v("THB")]),
            _c("i", { staticClass: "dropdown icon" }),
            _vm._v(" "),
            _c("div", { staticClass: "menu" }, [
              _c("div", { staticClass: "item", attrs: { "data-value": "1" } }, [
                _vm._v("THB")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item active", attrs: { "data-value": "0" } },
                [_vm._v("US")]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "[ ml-4 ] ui text medium" }, [
          _vm._v("Language:"),
          _c("div", { staticClass: "[ ui inline dropdown ] [ ml-1 ]" }, [
            _c("input", { attrs: { type: "hidden", name: "language" } }),
            _vm._v(" "),
            _c("div", { staticClass: "text" }, [_vm._v("TH")]),
            _c("i", { staticClass: "dropdown icon" }),
            _vm._v(" "),
            _c("div", { staticClass: "menu" }, [
              _c("div", { staticClass: "item", attrs: { "data-value": "1" } }, [
                _vm._v("TH")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item active", attrs: { "data-value": "0" } },
                [_vm._v("EN")]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "[ ui text medium ] [ ml-4 align-items-center d-flex ] [ color black ]",
            attrs: { href: "#" }
          },
          [
            _c("i", {
              staticClass: "dollar sign icon large [ mr-0 ] [ color black ]"
            }),
            _vm._v(" Method of Payment\n              ")
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "[ ui text medium ] [ ml-4 align-items-center d-flex ] [ color black ]",
            attrs: { href: "#" }
          },
          [
            _c("i", {
              staticClass: "phone volume icon large [ mr-0 ] [ color black ]"
            }),
            _vm._v(" 096-8917824\n              ")
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "[mt-2 ] d-none d-lg-block",
        staticStyle: { padding: "3.5%" }
      },
      [
        _c("div", { staticClass: "row align-items-center" }, [
          _c(
            "div",
            {
              staticClass:
                "col col-3 [ d-lg-flex d-none d-lg-block align-items-center ]"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "[ align-items-center d-flex ] [ ui dropdown item ]",
                  attrs: { href: "#" }
                },
                [
                  _c("i", {
                    staticClass: "icon-custom-burger-menu large color black"
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "[ ml-3 ] [ ui text large color black ]" },
                    [_vm._v("หมวดหมู่สินค้า")]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-4" }, [
            _c("div", { staticClass: "[ d-flex align-items-center ]" }, [
              _c("div", { staticClass: "w-100" }, [
                _c("div", { staticClass: "w-100 ui input" }, [
                  _c("input", {
                    staticClass: "ui input black br-0",
                    attrs: { type: "text", placeholder: "" }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "ui button black br-0 d-flex align-items-center px-3 py-2"
                    },
                    [
                      _c("i", {
                        staticClass: "ui icon search massive mb-2 d-block"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ui text large" }, [
                        _vm._v("ค้นหา")
                      ])
                    ]
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col col-5" }, [
            _c(
              "div",
              { staticClass: "[ d-flex align-items-center text-right ]" },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "[ text ] [ ml-4 text-center ml-auto ] [ color gray-4 ]",
                    attrs: { href: "#" }
                  },
                  [
                    _c("i", {
                      staticClass:
                        "icon-custom-lock big [ mr-auto ml-auto d-block ]"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "text tiny d-block mt-1" }, [
                      _vm._v("ออกจากระบบ")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "[ text ] [ d-none d-lg-block ml-4 text-center ] [ color gray-4 ]",
                    attrs: { href: "#" }
                  },
                  [
                    _c("i", {
                      staticClass:
                        "icon-custom-heart big [ mr-auto ml-auto d-block ]"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "text tiny d-block mt-1" }, [
                      _vm._v("รายการที่ชอบ")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "[ text ] [ ml-4 text-center ] [ color gray-4 ]",
                    attrs: { href: "#" }
                  },
                  [
                    _c("i", {
                      staticClass:
                        "icon-custom-cart big [ mr-auto ml-auto d-block ]"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "text tiny d-block mt-1" }, [
                      _vm._v("ตะกร้า")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "[ text ] [ ml-4 text-center ] [ d-none d-lg-block color gray-4 ]",
                    attrs: { href: "#" }
                  },
                  [
                    _c("i", {
                      staticClass:
                        "icon-custom-profile big [ mr-auto ml-auto d-block ]"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "text tiny d-block mt-1" }, [
                      _vm._v("ส่วนตัว")
                    ])
                  ]
                )
              ]
            )
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-block d-lg-none" }, [
      _c("img", {
        staticClass: "w-100 d-block",
        attrs: { src: "/imgs/contents/admin-mobile-header-bottom.svg" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "footer" }, [
      _c("div", { staticClass: "container bg white" }, [
        _c("img", {
          staticClass: "d-block w-100",
          attrs: { src: "/imgs/contents/admin-footer.svg" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("p", { staticClass: "modal-title color black h3" }, [
        _vm._v("เลือกรูปแบบ")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "ui text big color black" }, [
        _vm._v("รูปแบบที่ 1")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "d-block" }, [
      _c("img", {
        staticClass: "w-100",
        attrs: { src: "/imgs/contents/admin-section-content-2.svg" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "ui text big color black" }, [
        _vm._v("รูปแบบที่ 2")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "d-block" }, [
      _c("img", {
        staticClass: "w-100",
        attrs: { src: "/imgs/contents/admin-section-products-1.svg" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "ui text big color black" }, [
        _vm._v("รูปแบบที่ 3")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "d-block" }, [
      _c("img", {
        staticClass: "w-100",
        attrs: { src: "/imgs/contents/admin-section-products-2.svg" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "ui text big color black" }, [
        _vm._v("รูปแบบที่ 4")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "d-block" }, [
      _c("img", {
        staticClass: "w-100",
        attrs: { src: "/imgs/contents/admin-section-products-3.svg" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "ui text big color black" }, [
        _vm._v("รูปแบบที่ 5")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "d-block" }, [
      _c("img", {
        staticClass: "w-100",
        attrs: { src: "/imgs/contents/admin-section-products-4.svg" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "ui button red basic text",
        attrs: { "data-dismiss": "modal" }
      },
      [_c("span", { staticClass: "ui text large" }, [_vm._v("ยกเลิก")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("p", { staticClass: "modal-title color black h3 mx-auto" }, [
        _vm._v("ลบรูปแบบ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("div", { staticClass: "text-center" }, [
        _c("i", {
          staticClass: "far fa-trash-alt color red-1 mb-3",
          staticStyle: { "font-size": "12rem" }
        })
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "ui text large color black text-center" }, [
        _vm._v("คุณต้องการลบรูปแบบหรือไม่")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "ui button red basic text",
        attrs: { "data-dismiss": "modal" }
      },
      [_c("span", { staticClass: "ui text large" }, [_vm._v("ยกเลิก")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("p", { staticClass: "modal-title color black h3 mx-auto" }, [
        _vm._v("ลบโลโก้")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("div", { staticClass: "text-center" }, [
        _c("i", {
          staticClass: "far fa-trash-alt color red-1 mb-3",
          staticStyle: { "font-size": "12rem" }
        })
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "ui text large color black text-center" }, [
        _vm._v("คุณต้องการลบโลโก้หรือไม่")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "ui button red basic text",
        attrs: { "data-dismiss": "modal" }
      },
      [_c("span", { staticClass: "ui text large" }, [_vm._v("ยกเลิก")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }