var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "custom-card" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("editor", {
                model: {
                  value: _vm.privacy,
                  callback: function($$v) {
                    _vm.privacy = $$v
                  },
                  expression: "privacy"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-5 text-center  " }, [
          _c(
            "a",
            {
              staticClass: "btn btn-lg btn-gradient-primary",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.updatePrivacy($event)
                }
              }
            },
            [_vm._v("บันทึก")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h1", [_vm._v("นโยบายการใช้งาน")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("เพิ่มนโยบายการใช้งาน")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }