var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid h-100", attrs: { id: "login-wrapper" } },
    [
      _c(
        "div",
        { staticClass: "row h-100 align-items-center justify-content-center" },
        [
          _c("div", { staticClass: "col-md-4 col-sm-12" }, [
            _c(
              "form",
              {
                staticClass: "card login-card shadow",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.sendResetPassword($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "h3",
                    { staticClass: "text-center font-weight-bold py-3" },
                    [_vm._v("Reset Password")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "input-icon" }, [
                      _c("div", { staticClass: "input-icon-addon" }, [
                        _c("i", {
                          staticClass: "fas fa-envelope",
                          class: { "text-danger": _vm.msg.email != undefined }
                        })
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email"
                          }
                        ],
                        staticClass: "form-control form-control-lg",
                        class: { "is-invalid": _vm.msg.email != undefined },
                        attrs: { type: "text", placeholder: "Email" },
                        domProps: { value: _vm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.email = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.msg.email != undefined
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.msg.email[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "input-icon" }, [
                      _c("div", { staticClass: "input-icon-addon" }, [
                        _c("i", {
                          staticClass: "fas fa-lock",
                          class: { "text-danger": _vm.msg.password }
                        })
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.password,
                            expression: "password"
                          }
                        ],
                        staticClass: "form-control form-control-lg",
                        class: { "is-invalid": _vm.msg.password },
                        attrs: { type: "password", placeholder: "Password" },
                        domProps: { value: _vm.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.password = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.msg.password
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.msg.password[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "input-icon" }, [
                      _c("div", { staticClass: "input-icon-addon" }, [
                        _c("i", {
                          staticClass: "fas fa-lock",
                          class: { "text-danger": _vm.msg.confirmPassword }
                        })
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.confirmPassword,
                            expression: "confirmPassword"
                          }
                        ],
                        staticClass: "form-control form-control-lg",
                        class: { "is-invalid": _vm.msg.confirmPassword },
                        attrs: {
                          type: "password",
                          placeholder: "Confirm Password"
                        },
                        domProps: { value: _vm.confirmPassword },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.confirmPassword = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.msg.confirmPassword
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.msg.confirmPassword[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-lg btn-block btn-orange",
                        attrs: { disabled: _vm.loading }
                      },
                      [_vm._v("\n              Reset Password\n            ")]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _vm._m(0)
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: " mt-3 d-flex align-items-center justify-content-center" },
      [
        _c("p", [
          _vm._v("Powered by "),
          _c("img", {
            attrs: { src: "/imgs/logo.png", height: "24", width: "150" }
          })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }