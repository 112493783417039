<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header"><h1>ข้อมูลเว็ปไซต์</h1></div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="card-title">ข้อมูลทั่วไป</h4>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for=""
                >ชื่อเว็ปไซต์
                <small class="text-muted">
                  (หรืออาจเป็นข้อความต้อนรับ เช่น
                  ยินดีต้อนรับสู่ร้านของเรา)</small
                ></label
              >
              <input
                type="text"
                class="form-control form-control-lg"
                v-model="title"
              />
            </div>
            <div class="form-group">
              <label for=""
                >เบอร์โทรติดต่อ
                </label
              >
              <input
                type="text"
                class="form-control form-control-lg"
                v-model="phone"
              />
            </div>
            <div class="form-group">
              <label for="">
                คำอธิบายเว็ปไซต์
                <small class="text-muted">
                  (หรือคำโฆษณาย่อๆ ไม่ควรเกิน 4 บรรทัด)</small
                >
              </label>
              <textarea
                rows="4"
                class="form-control form-control-lg"
                v-model="description"
              ></textarea>
            </div>
            <div class="form-group">
              <label for="">
                ที่อยู่ร้านค้า
                <small class="text-muted">
                  (ที่อยู่ร้านค้า ไม่ควรเกิน 2 บรรทัด)</small
                >
              </label>
              <textarea
                rows="4"
                class="form-control form-control-lg"
                v-model="address"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="custom-card mt-5">
          <div class="card-header"><h4 class="card-title">SEO</h4></div>
          <div class="card-body"><h1>SOON</h1></div>
        </div>

        <div class="mt-4 text-center">
          <a
            href="#"
            class="btn btn-lg btn-gradient-primary"
            role="button"
            @click.prevent="updateSiteSetting"
            >บันทึก</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'siteSetting',
  components: {},
  props: {},
  data() {
    return {
      title: '',
      description: '',
      address: '',
      phone: '',
    }
  },
  computed: {},
  created() {
    this.fetchAppData()
  },
  methods: {
    fetchAppData() {
      axios.get('/api/admin/applications').then(res => {
        this.title = res.data.data.site_name
        this.description = res.data.data.site_description
        this.address = res.data.data.address_footer
        this.phone = res.data.data.phone
      })
    },
    updateSiteSetting() {
      let data = {
        'site_name': this.title,
        'site_description': this.description,
        'address_footer': this.address,
        'phone': this.phone
      }
      axios
        .patch('/api/admin/applications', data)
        .then(res => this.fetchAppData())
    }
  }
}
</script>

<style lang="scss" scoped></style>
