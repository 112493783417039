var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("p", { staticClass: "lead text-dark ml-3" }, [
          _vm._v("Color เลือกสี")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex justify-content-around" },
          _vm._l(_vm.colors, function(color) {
            return _c(
              "div",
              {
                staticClass: "preview-color",
                class: {
                  selected: _vm.application.theme_color.main == color.main
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.selectColor(color)
                  }
                }
              },
              [
                _c("div", { staticClass: "box-color" }, [
                  _c("div", { staticClass: "main-color" }, [
                    _c("svg", { attrs: { height: "50", width: "50" } }, [
                      _c("polyline", {
                        style:
                          "fill:" + color.main + ";stroke:white;stroke-width:4",
                        attrs: { points: "0,0 50,0 50,20 20,50 0,50 0,0" }
                      }),
                      _vm._v(
                        "Sorry, your browser does not support inline SVG.\n              "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "sub-color" }, [
                    _c("svg", { attrs: { height: "50", width: "50" } }, [
                      _c("polyline", {
                        style:
                          "fill:" + color.sub + ";stroke:white;stroke-width:4",
                        attrs: { points: "20,50 50,20 50,50 20,50" }
                      }),
                      _vm._v(
                        "Sorry, your browser does not support inline SVG.\n              "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("i", { staticClass: "fas fa-check-circle" })
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "row no-gutters" }, [
          _c("span", { staticClass: "col-12 text-danger" }, [
            _vm._v(
              "*เมื่อทำการเปลี่ยนแปลงรูปแบบแล้ว การตั้งค่าส่วนเนื้อหาจะถูกรีเซ็ต"
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c("p", { staticClass: "lead text-dark text-center" }, [
              _vm._v("แบบที่ 1")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "theme-image-wrapper",
                class: { selected: _vm.application.selected_theme == 1 }
              },
              [
                _c("img", {
                  staticClass: "img-fluid cursor theme-image",
                  attrs: { src: "/imgs/templates/1.svg" },
                  on: {
                    click: function($event) {
                      _vm.selectTheme(1)
                    }
                  }
                }),
                _vm._v(" "),
                _c("i", { staticClass: "fas fa-check-circle fa-2x" })
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c("p", { staticClass: "lead text-dark text-center" }, [
              _vm._v("แบบที่ 2")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "theme-image-wrapper",
                class: { selected: _vm.application.selected_theme == 2 }
              },
              [
                _c("img", {
                  staticClass: "img-fluid cursor theme-image",
                  attrs: { src: "/imgs/templates/2.svg" },
                  on: {
                    click: function($event) {
                      _vm.selectTheme(2)
                    }
                  }
                }),
                _vm._v(" "),
                _c("i", { staticClass: "fas fa-check-circle fa-2x" })
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c("p", { staticClass: "lead text-dark text-center" }, [
              _vm._v("แบบที่ 3")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "theme-image-wrapper",
                class: { selected: _vm.application.selected_theme == 3 }
              },
              [
                _c("img", {
                  staticClass: "img-fluid cursor theme-image",
                  attrs: { src: "/imgs/templates/3.svg" },
                  on: {
                    click: function($event) {
                      _vm.selectTheme(3)
                    }
                  }
                }),
                _vm._v(" "),
                _c("i", { staticClass: "fas fa-check-circle fa-2x" })
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header mb-2" }, [
      _c("h1", [_vm._v("เลือกธีมสีเว็ปไซต์ทั้งหมด")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", [_vm._v("เลือกธีมสัดส่วน การจัดวางสินค้าและเนื้อหา")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }