<template>
    <iframe v-if="base64" width='100%' height='100%' type="application/pdf" :src="base64"></iframe>
</template>

<script>
export default {
    name: 'print',
    components: {},
    props: {},
    data () {
        return {base64: null}
    },
    computed: {},
    created () {
        axios.post(`/api/admin/print/orders/${this.$route.params.id}`)
        .then(result => {
            this.base64 ='data:application/pdf;base64,' + escape(result.data.data)
        })
    },
    methods: {}
}
</script>

<style lang="scss" scoped>

</style>