<template>
  <div class="container">
    <div class="page-header">
      <h1>หมวดหมู่สินค้า</h1>
    </div>
    <div class="row">
      <transition enter-active-class="vivify fadeIn" leave-active-class="vivify fadeOut" mode="out-in" duration="400">
        <div class="col-12 mb-5" v-if="clicked" key="create">
          <category-form @onFormSubmit="addNewCategory" :category="category"></category-form>
        </div>
        <div class="col-12" v-else key="list">
          <div class="row">
            <div class="col-md-3 col-sm-12 mb-4">
              <div class="category-card border-0 shadow-sm cursor h-100">
                <div class="card-body text-center d-flex align-items-center justify-content-center">
                  <a href="#" style="text-decoration: none;" @click="createCategory">
                    <span class="avatar avatar-category"><i class="fas fa-plus"></i></span>
                    <p class="pt-3">เพิ่มใหม่</p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-12 mb-4" v-for="category in categories.data" :key="category.id">
              <div class="category-card border-0 shadow-sm cursor">
                <div class="card-delete">
                  <div class="btn-group">
                    <a href="#" class="btn btn-outline-light btn-sm" @click.prevent="fetchCategory(category.id)"><i class="fas fa-edit mr-1"></i>แก้ไข</a>
                    <a href="#" class="btn btn-outline-light btn-sm" @click.prevent="deleteCategory(category)"><i class="fas fa-trash-alt mr-1"></i>ลบ</a>
                  </div>
                </div>
                <div class="card-body text-center">
                  <span class="avatar avatar-category" :style="{backgroundImage: `url(/imgs/categories/${category.logoFilename})`}"></span>
                  <p class="pt-3">{{ category.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
  import CategoryForm from '@/views/Category/Form'

  export default {
    name: 'category',
    components: { CategoryForm },
    props: {},
    data() {
      return {
        categories: [],
        category: {
          name: '',
          enabled: true,
          childrens: [{ name: '', enabled: true }],
          logoFilename: undefined
        },
        clicked: false,
        updateFrom: false,
      }
    },
    computed: {},
    created() {
      this.fetchCategoryData()
    },
    methods: {
      fetchCategoryData() {
        axios
          .get('/api/admin/categories')
          .then(result => (this.categories = result.data))
      },
      addNewCategory(category) {
        let form = new FormData()
        let request = {}

        form.append('name', category.name)
        if (category.enabled) {
          form.append('enabled', true)
        }
        if (category.file) {
          form.append('file', category.file)
        }
        if (category.image) {
          form.append('image', category.image)
        }
        category.childrens.forEach((child, index) => {
          form.append(`childrens[${index}][name]`, child.name)
          if (child.enabled) {
            form.append(`childrens[${index}][enabled]`, true)
          }
          if (this.clicked) {
            form.append(`childrens[${index}][id]`, child.id)
          }
        })
        let message = ''
        if (this.updateFrom) {
          form.append('_method', 'PATCH')
          request = axios.post(`/api/admin/categories/${category.id}`, form)
          message = 'แก้ไขหมวดหมู่สำเร็จ'
        } else {
          request = axios.post('/api/admin/categories', form)
          message = 'เพิ่มไขหมวดหมู่สำเร็จ'
        }
        request
          .then(result => {
            this.fetchCategoryData()
            this.clicked = false
            this.$notify({
              title: 'Success',
              message: message,
              type: 'success'
            })
          })
          .catch(error => {
              let msg = error.response.data.errors.key
              console.log(msg)
                  let message= 'มีข้อผิดพลาดเกิดขึ้น'
            switch (msg) {
              case 'กรุณากรอกname':
                message = 'กรุณากรอก ชื่อหมวดหมู่'
            }
            this.$notify({
              title: 'Error',
              message: message,
              type: 'error'
            })
          })
      },
      createCategory() {
        this.clicked = !this.clicked
        this.updateFrom = false
        this.category = {
          name: '',
          enabled: true,
          childrens: [{ name: '', enabled: true }],
          logoFilename: undefined
        }
      },
      fetchCategory(categoryId) {
        this.updateFrom = true
        axios.get(`/api/admin/categories/${categoryId}`).then(result => {
          this.category = result.data.data
          this.clicked = true
        })
      },
      deleteCategory(category) {
        this.$confirm(`ต้องการลบหมวดหมู่ "${
              category.name
            }" ใช่หรือไม่`, 'ลบหมวดหมู่', {
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            dangerouslyUseHTMLString: true,
            type: 'warning'
            }).then(() => {
            axios
              .delete(`/api/admin/categories/${category.id}`)
              .then(() => {
                this.fetchCategoryData()
              })
              .catch(error => swal('', err.response.data.message, 'error'))
        })
      }
    }
  }
</script>

<style lang="scss" scoped></style>
