var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("form", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 15 } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c("el-card", { staticClass: "el-custom-card" }, [
                    _c(
                      "div",
                      {
                        staticClass: "text-primary font-weight-bold",
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [_vm._v("ข้อมูลทั่วไปสินค้า")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("span", { staticClass: "required" }),
                        _vm._v(" ชื่อสินค้า")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.productForm.titleTH,
                            expression: "productForm.titleTH"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.errors.has("title") },
                        attrs: {
                          type: "text",
                          name: "title",
                          placeholder: "ชื่อสินค้า"
                        },
                        domProps: { value: _vm.productForm.titleTH },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.productForm,
                              "titleTH",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("title"),
                              expression: "errors.has('title')"
                            }
                          ],
                          staticClass: "invalid-feedback"
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.first("title")) +
                              "\n              "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(" รายละเอียดสินค้า")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("description"),
                                expression: "errors.has('description')"
                              }
                            ],
                            staticClass: "invalid-feedback"
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.errors.first("description")) +
                                "\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("editor", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          attrs: { value: "description", name: "description" },
                          model: {
                            value: _vm.productForm.detailTH,
                            callback: function($$v) {
                              _vm.$set(_vm.productForm, "detailTH", $$v)
                            },
                            expression: "productForm.detailTH"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "div",
                        { staticClass: "custom-control custom-switch" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.productForm.enabled,
                                expression: "productForm.enabled"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: { type: "checkbox", id: "customSwitch1" },
                            domProps: {
                              checked: Array.isArray(_vm.productForm.enabled)
                                ? _vm._i(_vm.productForm.enabled, null) > -1
                                : _vm.productForm.enabled
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.productForm.enabled,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.productForm,
                                        "enabled",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.productForm,
                                        "enabled",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.productForm, "enabled", $$c)
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "customSwitch1" }
                            },
                            [_vm._v("แสดงสินค้า")]
                          )
                        ]
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "el-custom-card" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text-primary font-weight-bold",
                          attrs: { slot: "header" },
                          slot: "header"
                        },
                        [_vm._v("หมวดหมู่สินค้า")]
                      ),
                      _vm._v(" "),
                      _c("label", { staticClass: "mb-1" }, [
                        _vm._v(" เลือกหมวดหมู่สินค้าทั้งหมด")
                      ]),
                      _vm._v(" "),
                      _c("el-tree", {
                        ref: "category-list",
                        staticClass: "border p-1",
                        attrs: {
                          data: _vm.formatCategories,
                          "show-checkbox": "",
                          "node-key": "id",
                          props: _vm.defaultProps
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-card", { staticClass: "el-custom-card" }, [
                    _c(
                      "div",
                      {
                        staticClass: "text-primary font-weight-bold",
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [_vm._v("โน๊ต")]
                    ),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.productForm.note,
                          expression: "productForm.note"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { name: "note", rows: "5" },
                      domProps: { value: _vm.productForm.note },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.productForm, "note", $event.target.value)
                        }
                      }
                    })
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "el-custom-card" },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c(
                          "span",
                          { staticClass: "text-primary font-weight-bold" },
                          [
                            _vm._v(
                              "รูปภาพสินค้า (" +
                                _vm._s(_vm.productForm.images.length) +
                                ")"
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-upload",
                        {
                          ref: "image-upload",
                          attrs: {
                            action: "/api/admin/images",
                            "list-type": "picture-card",
                            multiple: true,
                            headers: _vm.headers,
                            limit: 5,
                            "file-list": _vm.productForm.images,
                            name: "file[]",
                            "before-remove": _vm.beforeRemoveImage,
                            "on-remove": _vm.handleRemoveImage,
                            "on-success": _vm.handleUploadImageResponse
                          }
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-card", { staticClass: "el-custom-card" }, [
                    _c(
                      "div",
                      {
                        staticClass: "text-primary font-weight-bold",
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [_vm._v("แท็กชื่อสินค้า")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body px-0 pt-0 tag-list" },
                      _vm._l(_vm.productForm.tags, function(tag) {
                        return _c(
                          "el-tag",
                          {
                            key: tag,
                            attrs: {
                              closable: "",
                              "disable-transitions": false
                            },
                            on: {
                              close: function($event) {
                                _vm.handleClose(tag)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(tag) +
                                "\n              "
                            )
                          ]
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.tagValue,
                            expression: "tagValue"
                          }
                        ],
                        ref: "saveTagInput",
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.tagValue },
                        on: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleInputConfirm($event)
                          },
                          blur: _vm.handleInputConfirm,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.tagValue = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-outline-primary",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.handleInputConfirm($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "el-icon-plus" }),
                            _vm._v(" เพิ่ม")
                          ]
                        )
                      ])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("div", { staticClass: "page-header" }, [
                    _c("h1", [_vm._v("ราคาและสินค้า")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-card",
                    { staticClass: "el-custom-card" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text-primary font-weight-bold",
                          attrs: { slot: "header" },
                          slot: "header"
                        },
                        [
                          _vm._v("ราคาและสินค้า"),
                          _c("span", { staticClass: "required h" })
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.enabledSku
                        ? _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-4 col-sm-12" }, [
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "col-sm-4 align-self-center text-right",
                                    attrs: { for: "" }
                                  },
                                  [_vm._v("ราคาปกติ")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-8" }, [
                                  _c("div", { staticClass: "input-group" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.productForm.price,
                                          expression: "productForm.price"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number" },
                                      domProps: {
                                        value: _vm.productForm.price
                                      },
                                      on: {
                                        keyup: function($event) {
                                          _vm.calculatePrice("price")
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.productForm,
                                            "price",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "input-group-append" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "input-group-text" },
                                          [_vm._v("฿")]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-4 col-sm-12" }, [
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "col-sm-4 align-self-center text-right",
                                    attrs: { for: "" }
                                  },
                                  [_vm._v("ส่วนลด")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-8" }, [
                                  _c("div", { staticClass: "input-group" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.productForm.discountPrice,
                                          expression:
                                            "productForm.discountPrice"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number" },
                                      domProps: {
                                        value: _vm.productForm.discountPrice
                                      },
                                      on: {
                                        keyup: function($event) {
                                          _vm.calculatePrice("discount")
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.productForm,
                                            "discountPrice",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "input-group-append" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "input-group-text" },
                                          [_vm._v("฿")]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-4 col-sm-12" }, [
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "col-sm-4 align-self-center text-right",
                                    attrs: { for: "" }
                                  },
                                  [_vm._v("ส่วนลด (%)")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-8" }, [
                                  _c("div", { staticClass: "input-group" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.productForm.discountPercent,
                                          expression:
                                            "productForm.discountPercent"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number" },
                                      domProps: {
                                        value: _vm.productForm.discountPercent
                                      },
                                      on: {
                                        keyup: function($event) {
                                          _vm.calculatePrice("percent")
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.productForm,
                                            "discountPercent",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "input-group-append" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "input-group-text" },
                                          [_vm._v("%")]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-4 col-sm-12" }, [
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "col-sm-4 align-self-center text-right",
                                    attrs: { for: "" }
                                  },
                                  [_vm._v("สต๊อก")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-8" }, [
                                  _c("div", { staticClass: "input-group" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.productForm.quantity,
                                          expression: "productForm.quantity"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number" },
                                      domProps: {
                                        value: _vm.productForm.quantity
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.productForm,
                                            "quantity",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-4 col-sm-12" }, [
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "col-sm-4 align-self-center text-right",
                                    attrs: { for: "" }
                                  },
                                  [_vm._v("ต้นทุน")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-8" }, [
                                  _c("div", { staticClass: "input-group" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.productForm.cost,
                                          expression: "productForm.cost"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number" },
                                      domProps: { value: _vm.productForm.cost },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.productForm,
                                            "cost",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "input-group-append" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "input-group-text" },
                                          [_vm._v("฿")]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "btn border-dashed btn-white btn-block btn-lg",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.toggleEnabledSku($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-plus sku-icon shadow-sm"
                                }),
                                _vm._v(
                                  "\n                เพิ่มตัวเลือกสินค้า (SKU)\n              "
                                )
                              ]
                            )
                          ])
                        : [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-3 col-sm-12" }, [
                                _vm._v(
                                  "\n                  ตัวเลือกที่ 1\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-9 col-sm-12" }, [
                                _c(
                                  "div",
                                  { staticClass: "card-body bg-light" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-group row" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "col-sm-3",
                                            attrs: { for: "" }
                                          },
                                          [_vm._v("ชื่อ")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-sm-9 d-flex align-items-center"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.productForm.sku1Label,
                                                  expression:
                                                    "productForm.sku1Label"
                                                }
                                              ],
                                              staticClass: "form-control w-75",
                                              attrs: { type: "text" },
                                              domProps: {
                                                value: _vm.productForm.sku1Label
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.productForm,
                                                    "sku1Label",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "text-muted ml-auto align-self-start",
                                                on: {
                                                  click: function($event) {
                                                    _vm.enabledSku = false
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas fa-times"
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.productForm.sku1, function(
                                      option,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "form-group row"
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "col-sm-3",
                                              attrs: { for: "" }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: index === 0,
                                                      expression: "index === 0"
                                                    }
                                                  ]
                                                },
                                                [_vm._v("ตัวเลือก")]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-sm-9 d-flex align-items-center"
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: option.name,
                                                    expression: "option.name"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control w-75",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: option.name
                                                },
                                                on: {
                                                  keyup: function($event) {
                                                    if (
                                                      !("button" in $event) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    _vm.addSkuOption(1)
                                                  },
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      option,
                                                      "name",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: "ลบตัวเลือก",
                                                      expression: "'ลบตัวเลือก'"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "text-muted ml-1",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      _vm.removeSkuOption(
                                                        1,
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-times-circle"
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group row" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-sm-9 offset-sm-3"
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "btn border-dashed btn-white btn-block btn-lg w-75",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    _vm.addSkuOption(1)
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-plus sku-icon shadow-sm"
                                                }),
                                                _vm._v(
                                                  "\n                          เพิ่มตัวเลือกสินค้า\n                        "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  2
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-3 col-sm-12" }, [
                                _vm._v(
                                  "\n                  ตัวเลือกที่ 2\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-9 col-sm-12" }, [
                                _c(
                                  "div",
                                  { staticClass: "card-body bg-light" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-group row" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "col-sm-3",
                                            attrs: { for: "" }
                                          },
                                          [_vm._v("ชื่อ")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-sm-9 d-flex align-items-center"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.productForm.sku2Label,
                                                  expression:
                                                    "productForm.sku2Label"
                                                }
                                              ],
                                              staticClass: "form-control w-75",
                                              attrs: { type: "text" },
                                              domProps: {
                                                value: _vm.productForm.sku2Label
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.productForm,
                                                    "sku2Label",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.productForm.sku2, function(
                                      option,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "form-group row"
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "col-sm-3",
                                              attrs: { for: "" }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: index === 0,
                                                      expression: "index === 0"
                                                    }
                                                  ]
                                                },
                                                [_vm._v("ตัวเลือก")]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-sm-9 d-flex align-items-center"
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: option.name,
                                                    expression: "option.name"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control w-75",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: option.name
                                                },
                                                on: {
                                                  keyup: function($event) {
                                                    if (
                                                      !("button" in $event) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    _vm.addSkuOption(2)
                                                  },
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      option,
                                                      "name",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: "ลบตัวเลือก",
                                                      expression: "'ลบตัวเลือก'"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "text-muted ml-1",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      _vm.removeSkuOption(
                                                        2,
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-times-circle"
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group row" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-sm-9 offset-sm-3"
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "btn border-dashed btn-white btn-block btn-lg w-75",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    _vm.addSkuOption(2)
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-plus sku-icon shadow-sm"
                                                }),
                                                _vm._v(
                                                  "\n                          เพิ่มตัวเลือกสินค้า\n                        "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  2
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-12 mt-5" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-primary font-weight-bold"
                                  },
                                  [_vm._v("ข้อมูลตัวเลือก")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-12" }, [
                                _c("div", {}, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "col-sm-3",
                                        attrs: { for: "" }
                                      },
                                      [_vm._v("แก้ไขทั้งชุด")]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-sm-9" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: _vm.editSet.cost,
                                                expression: "editSet.cost",
                                                modifiers: { number: true }
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "number",
                                              placeholder: "ต้นทุน (฿)"
                                            },
                                            domProps: {
                                              value: _vm.editSet.cost
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.editSet,
                                                  "cost",
                                                  _vm._n($event.target.value)
                                                )
                                              },
                                              blur: function($event) {
                                                _vm.$forceUpdate()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: _vm.editSet.price,
                                                expression: "editSet.price",
                                                modifiers: { number: true }
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "number",
                                              placeholder: "ราคาปกติ (฿)"
                                            },
                                            domProps: {
                                              value: _vm.editSet.price
                                            },
                                            on: {
                                              keyup: function($event) {
                                                _vm.calculateEditSet("price")
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.editSet,
                                                  "price",
                                                  _vm._n($event.target.value)
                                                )
                                              },
                                              blur: function($event) {
                                                _vm.$forceUpdate()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value:
                                                  _vm.editSet.discountPrice,
                                                expression:
                                                  "editSet.discountPrice",
                                                modifiers: { number: true }
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "number",
                                              placeholder: "ส่วนลด (฿)"
                                            },
                                            domProps: {
                                              value: _vm.editSet.discountPrice
                                            },
                                            on: {
                                              keyup: function($event) {
                                                _vm.calculateEditSet("discount")
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.editSet,
                                                  "discountPrice",
                                                  _vm._n($event.target.value)
                                                )
                                              },
                                              blur: function($event) {
                                                _vm.$forceUpdate()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value:
                                                  _vm.editSet.discountPercent,
                                                expression:
                                                  "editSet.discountPercent",
                                                modifiers: { number: true }
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "number",
                                              max: "100",
                                              placeholder: "ส่วนลด (%)"
                                            },
                                            domProps: {
                                              value: _vm.editSet.discountPercent
                                            },
                                            on: {
                                              keyup: function($event) {
                                                _vm.calculateEditSet("percent")
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.editSet,
                                                  "discountPercent",
                                                  _vm._n($event.target.value)
                                                )
                                              },
                                              blur: function($event) {
                                                _vm.$forceUpdate()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: _vm.editSet.quantity,
                                                expression: "editSet.quantity",
                                                modifiers: { number: true }
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "number",
                                              placeholder: "สต๊อก"
                                            },
                                            domProps: {
                                              value: _vm.editSet.quantity
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.editSet,
                                                  "quantity",
                                                  _vm._n($event.target.value)
                                                )
                                              },
                                              blur: function($event) {
                                                _vm.$forceUpdate()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "input-group-append"
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-primary",
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.updateSet(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("อัพเดททั้งหมด")]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-3 col-sm-12" }, [
                                _vm._v(
                                  "\n                  รายการตัวเลือก\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-9 col-sm-12" }, [
                                _c("div", { staticClass: "table-responsive" }, [
                                  _c(
                                    "table",
                                    { staticClass: "table table-bordered" },
                                    [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { width: "15%" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.productForm.sku1Label
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.productForm.sku2Label != "" &&
                                          _vm.productForm.sku2.length > 0
                                            ? _c(
                                                "th",
                                                { attrs: { width: "15%" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.productForm.sku2Label
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            { attrs: { width: "10%" } },
                                            [_vm._v("ต้นทุน(฿)")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            { attrs: { width: "10%" } },
                                            [_vm._v("ราคา(฿)")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            { attrs: { width: "10%" } },
                                            [_vm._v("ส่วนลด(฿)")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            { attrs: { width: "10%" } },
                                            [_vm._v("สต๊อก")]
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.productForm.skuPrices,
                                        function(option) {
                                          return _c("tbody", [
                                            _vm.productForm.sku1.length >
                                            option.sku1
                                              ? _c("tr", [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.productForm.sku1[
                                                          option.sku1
                                                        ].name
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _vm.productForm.sku2.length >
                                                  option.sku2
                                                    ? _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.productForm
                                                              .sku2[option.sku2]
                                                              .name
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName:
                                                            "v-model.number",
                                                          value: option.cost,
                                                          expression:
                                                            "option.cost",
                                                          modifiers: {
                                                            number: true
                                                          }
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: { type: "number" },
                                                      domProps: {
                                                        value: option.cost
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            option,
                                                            "cost",
                                                            _vm._n(
                                                              $event.target
                                                                .value
                                                            )
                                                          )
                                                        },
                                                        blur: function($event) {
                                                          _vm.$forceUpdate()
                                                        }
                                                      }
                                                    })
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName:
                                                            "v-model.number",
                                                          value: option.price,
                                                          expression:
                                                            "option.price",
                                                          modifiers: {
                                                            number: true
                                                          }
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: { type: "number" },
                                                      domProps: {
                                                        value: option.price
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            option,
                                                            "price",
                                                            _vm._n(
                                                              $event.target
                                                                .value
                                                            )
                                                          )
                                                        },
                                                        blur: function($event) {
                                                          _vm.$forceUpdate()
                                                        }
                                                      }
                                                    })
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName:
                                                            "v-model.number",
                                                          value:
                                                            option.discountPrice,
                                                          expression:
                                                            "option.discountPrice",
                                                          modifiers: {
                                                            number: true
                                                          }
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: { type: "number" },
                                                      domProps: {
                                                        value:
                                                          option.discountPrice
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            option,
                                                            "discountPrice",
                                                            _vm._n(
                                                              $event.target
                                                                .value
                                                            )
                                                          )
                                                        },
                                                        blur: function($event) {
                                                          _vm.$forceUpdate()
                                                        }
                                                      }
                                                    })
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName:
                                                            "v-model.number",
                                                          value:
                                                            option.quantity,
                                                          expression:
                                                            "option.quantity",
                                                          modifiers: {
                                                            number: true
                                                          }
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: { type: "number" },
                                                      domProps: {
                                                        value: option.quantity
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            option,
                                                            "quantity",
                                                            _vm._n(
                                                              $event.target
                                                                .value
                                                            )
                                                          )
                                                        },
                                                        blur: function($event) {
                                                          _vm.$forceUpdate()
                                                        }
                                                      }
                                                    })
                                                  ])
                                                ])
                                              : _vm._e()
                                          ])
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ])
                              ])
                            ])
                          ]
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("div", { staticClass: "page-header" }, [
                    _c("h2", [_vm._v("กล่องพัสดุเพื่อส่ง")])
                  ]),
                  _vm._v(" "),
                  _c("el-card", { staticClass: "el-custom-card" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-1 align-self-center",
                          attrs: { for: "" }
                        },
                        [_vm._v("น้ำหนัก")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c("div", { staticClass: "input-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.shipping.weight,
                                expression: "shipping.weight",
                                modifiers: { number: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", placeholder: "น้ำหนัก" },
                            domProps: { value: _vm.shipping.weight },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.shipping,
                                  "weight",
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function($event) {
                                _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "input-group-append" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _vm._v("g")
                            ])
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-3 text-right align-self-center",
                          attrs: { for: "" }
                        },
                        [_vm._v("ขนาดสินค้า (กxยxส)")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-5" }, [
                        _c("div", { staticClass: "input-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.shipping.width,
                                expression: "shipping.width",
                                modifiers: { number: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", placeholder: "กว้าง" },
                            domProps: { value: _vm.shipping.width },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.shipping,
                                  "width",
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function($event) {
                                _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.shipping.length,
                                expression: "shipping.length",
                                modifiers: { number: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", placeholder: "ยาว" },
                            domProps: { value: _vm.shipping.length },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.shipping,
                                  "length",
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function($event) {
                                _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.shipping.height,
                                expression: "shipping.height",
                                modifiers: { number: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", placeholder: "สูง" },
                            domProps: { value: _vm.shipping.height },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.shipping,
                                  "height",
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function($event) {
                                _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "input-group-append" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _vm._v("cm")
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 text-center mb-5" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-gradient-primary btn-lg",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.addProduct($event)
              }
            }
          },
          [_vm._v("บันทึก")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h1", [_vm._v("เพิ่มสินค้า")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }