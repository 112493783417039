import Vue from 'vue'

Vue.directive('tooltip', (el, binding) => {
  let placement =  binding.arg != undefined ? binding.arg : 'top'
  $(el).tooltip({
    title: binding.value,
    trigger: 'hover',
    placement
  })
})
