<template>
  <div>
    <label :for="elementId">
      <span
        class="avatar avatar-category cursor"
        id="previewImage"
        :style="{ backgroundImage: `url(${previewImage})` }"
      >
        <i class="fas fa-camera" :class="{ 'd-none': hideIcon }"></i>
      </span>
    </label>
    <input
      type="file"
      class="d-none"
      :id="elementId"
      name="image"
      @change="handleUploadImage"
    />
  </div>
</template>

<script>
export default {
  name: 'imageUpload',
  components: {},
  props: {
    image: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      previewImage: undefined,
      hideIcon: false
    }
  },
  watch: {
    image(value) {
      if (value != undefined) {
        this.previewImage = value
        this.hideIcon = true
      }
    }
  },
  computed: {
    elementId() {
      return Math.random()
        .toString(36)
        .substr(2, 9)
    }
  },
  created() {
    if (this.image) {
      this.previewImage = this.image
      this.hideIcon = true
    }
  },
  methods: {
    handleUploadImage(event) {
      let file = event.target.files[0]
      let reader = new FileReader()
      reader.onload = image => {
        this.hideIcon = true
        this.previewImage = image.target.result
        this.$emit('finishedUpload', file)
      }
      reader.readAsDataURL(file)
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  cursor: pointer;
}
</style>
