var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pr-3 pl-3 text-center" }, [
    _c(
      "svg",
      {
        staticClass: "donut-chart",
        attrs: { height: "160", width: "160", viewBox: "0 0 160 160" }
      },
      _vm._l(_vm.sortedValues, function(value, index) {
        return _c("g", [
          _c("circle", {
            attrs: {
              cx: _vm.cx,
              cy: _vm.cy,
              r: _vm.radius,
              stroke: _vm.colors[index],
              "stroke-width": _vm.strokeWidth,
              "stroke-dasharray": _vm.adjustedCircumference,
              "stroke-dashoffset": _vm.calculateStrokeDashOffset(
                value,
                _vm.circumference
              ),
              fill: "transparent",
              transform: _vm.returnCircleTransformValue(index)
            }
          }),
          _vm._v(" "),
          _vm.segmentBigEnough(value)
            ? _c(
                "text",
                {
                  attrs: {
                    "text-anchor": "middle",
                    dy: "3px",
                    x: _vm.chartData[index].textX,
                    y: _vm.chartData[index].textY
                  }
                },
                [_vm._v(_vm._s(_vm.percentageString(value)))]
              )
            : _vm._e()
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }