<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header mb-2">
          <h1>เลือกธีมสีเว็ปไซต์ทั้งหมด</h1>
        </div>
        <p class="lead text-dark ml-3">Color เลือกสี</p>
        <div class="d-flex justify-content-around">
          <div
            class="preview-color"
            v-for="color in colors"
            @click.prevent="selectColor(color)"
            :class="{ 'selected': application.theme_color.main == color.main }"
          >
            <div class="box-color">
              <div class="main-color">
                <svg height="50" width="50">
                  <polyline
                    points="0,0 50,0 50,20 20,50 0,50 0,0"
                    :style="'fill:' + color.main + ';stroke:white;stroke-width:4'"
                  ></polyline>Sorry, your browser does not support inline SVG.
                </svg>
              </div>
              <div class="sub-color">
                <svg height="50" width="50">
                  <polyline
                    points="20,50 50,20 50,50 20,50"
                    :style="'fill:' + color.sub+';stroke:white;stroke-width:4'"
                  ></polyline>Sorry, your browser does not support inline SVG.
                </svg>
              </div>
            </div>
            <i class="fas fa-check-circle"></i>
          </div>
        </div>
        <div class="page-header">
          <h3>เลือกธีมสัดส่วน การจัดวางสินค้าและเนื้อหา</h3>
        </div>
        <div class="row no-gutters">
          <span
            class="col-12 text-danger"
          >*เมื่อทำการเปลี่ยนแปลงรูปแบบแล้ว การตั้งค่าส่วนเนื้อหาจะถูกรีเซ็ต</span>
          <div class="col-md-4">
            <p class="lead text-dark text-center">แบบที่ 1</p>
            <div class="theme-image-wrapper" :class="{ selected: application.selected_theme == 1 }">
              <img
                src="/imgs/templates/1.svg"
                class="img-fluid cursor theme-image"
                @click="selectTheme(1)"
              >
              <i class="fas fa-check-circle fa-2x"></i>
            </div>
          </div>
          <div class="col-md-4">
            <p class="lead text-dark text-center">แบบที่ 2</p>
            <div class="theme-image-wrapper" :class="{ selected: application.selected_theme == 2 }">
              <img
                src="/imgs/templates/2.svg"
                class="img-fluid cursor theme-image"
                @click="selectTheme(2)"
              >
              <i class="fas fa-check-circle fa-2x"></i>
            </div>
          </div>
          <div class="col-md-4">
            <p class="lead text-dark text-center">แบบที่ 3</p>
            <div class="theme-image-wrapper" :class="{ selected: application.selected_theme == 3 }">
              <img
                src="/imgs/templates/3.svg"
                class="img-fluid cursor theme-image"
                @click="selectTheme(3)"
              >
              <i class="fas fa-check-circle fa-2x"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CodeLoader } from "vue-content-loader";

export default {
  name: "theme",
  components: { CodeLoader },
  props: {},
  data() {
    return {
      colors: [
        { main: "#919191", sub: "#FF7575" },
        { main: "#8ED30E", sub: "#F7710C" },
        { main: "#454545", sub: "#D6231E" },
        { main: "#007AFF", sub: "#F67005" },
        { main: "#F19B27", sub: "#0A6BA4" },
        { main: "#B259FF", sub: "#669AE8" },
        { main: "#45345C", sub: "#A62B1F" },
        { main: "#19C2A9", sub: "#FF7E61" },
        { main: "#D34052", sub: "#40D3BA" }
      ],
      application: {
        theme_color: { main: "", sub: "" }
      }
    };
  },
  computed: {},
  created() {
    this.getApplicatonData();
  },
  methods: {
    getApplicatonData() {
      axios.get("/api/admin/applications").then(res => {
        this.application = res.data.data;
        if (this.application.theme_color == null) {
          this.selectColor(this.colors[0]);
        }
      });
    },
    selectColor(color) {
      axios.post("/api/admin/themes", { color }).then(res => {
        this.application.theme_color = color;
      });
    },
    selectTheme(themeId) {
      axios
        .post("/api/admin/themes", { theme_id: themeId })
        .then(res => this.getApplicatonData());
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~$sass/variables";
.preview-color {
  width: 54px;
  height: 54px;
  position: relative;
  cursor: pointer;
  > i.fas.fa-check-circle {
    display: none;
  }
  &.selected {
    border: 2px solid $green;
    border-radius: $border-radius;
    & > i.fas.fa-check-circle {
      display: inline;
      position: absolute;
      color: $green;
      right: 2px;
      bottom: 2px;
    }
  }
  > .box-color {
    width: 50px;
    height: 50px;
    border-radius: $border-radius;
    position: relative;
    .main-color,
    .sub-color {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
}
.theme-image-wrapper {
  padding: 5px;
  position: relative;
  border: 2px solid transparent;
  .fas.fa-check-circle {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: $green;
    display: none;
  }
  &.selected {
    border-color: $green;
    border-radius: $border-radius;
    /* background: rgba(0, 0, 0, 0.5); */
    > i.fas.fa-check-circle {
      display: inline;
    }
  }
}
</style>
