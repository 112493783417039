var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "admin-section" },
    [
      _c("div", { staticClass: "inner-wrap container bg white pb-1 pb-lg-0" }, [
        _c("div", { staticClass: "admin-btns admin-btns--sectionedit" }, [
          _c(
            "a",
            {
              staticClass:
                "ui button white basic d-block mt-1 mb-1 d-xl-flex js-modal-btn",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.handleDeleteComponentModal($event)
                }
              }
            },
            [
              _c("i", {
                staticClass: "d-none d-xl-block trash alternate outline icon"
              }),
              _vm._v("ลบ\n      ")
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "admin-btns admin-btns--add-section-top circular ui icon button blue js-modal-btn",
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.$parent.showSectionModal(_vm.id, "top")
              }
            }
          },
          [_c("i", { staticClass: "plus icon" })]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "admin-btns admin-btns--add-section-bottom circular ui icon button blue js-modal-btn",
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.$parent.showSectionModal(_vm.id, "bottom")
              }
            }
          },
          [_c("i", { staticClass: "plus icon" })]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "products-list set-3 products-type-d row" }, [
          _c("div", { staticClass: "col-lg-3" }, [
            _c(
              "div",
              {
                staticClass: "item item-hover",
                class: { show: _vm.productSlot[0] }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "admin-item-btns",
                    on: {
                      click: function($event) {
                        _vm.currentSlot = 0
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.$parent.openModal(
                              "#selectProductSlot-" + _vm.errors.vmId
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "plus icon" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.$parent.openModal(
                              "#selectProductSlot-" + _vm.errors.vmId
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "edit outline icon" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.$parent.openModal(
                              "#deleteProduct-" + _vm.errors.vmId
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "trash alternate outline icon" })]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "product type-d py-2" }, [
                  _c(
                    "h5",
                    { staticClass: "ui text large bold text-truncate" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.productSlot[0]
                            ? _vm.productSlot[0].titleTH
                            : "Enchanted Rose Light"
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "thumb" }, [
                    _vm.productSlot[0]
                      ? _c("img", {
                          attrs: {
                            src:
                              "/storage/products/" +
                              _vm.productSlot[0]["images"][0]["filename"]
                          }
                        })
                      : _c("img", { attrs: { src: "#" } })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center mt-3" }, [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass: "ui text large bold [ color red-2 ] mb-0"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.productSlot[0]
                                ? _vm.productSlot[0].netPrice
                                : "0"
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "ui text large line-through" }, [
                        _vm._v(
                          _vm._s(
                            _vm.productSlot[0] ? _vm.productSlot[0].price : "0"
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(0)
                  ])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-3" }, [
            _c(
              "div",
              {
                staticClass: "item item-hover",
                class: { show: _vm.productSlot[1] }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "admin-item-btns",
                    on: {
                      click: function($event) {
                        _vm.currentSlot = 1
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.$parent.openModal(
                              "#selectProductSlot-" + _vm.errors.vmId
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "plus icon" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.$parent.openModal(
                              "#selectProductSlot-" + _vm.errors.vmId
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "edit outline icon" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.$parent.openModal(
                              "#deleteProduct-" + _vm.errors.vmId
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "trash alternate outline icon" })]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "product type-d py-2" }, [
                  _c(
                    "h5",
                    { staticClass: "ui text large bold text-truncate" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.productSlot[1]
                            ? _vm.productSlot[1].titleTH
                            : "Enchanted Rose Light"
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "thumb" }, [
                    _vm.productSlot[1]
                      ? _c("img", {
                          attrs: {
                            src:
                              "/storage/products/" +
                              _vm.productSlot[1]["images"][0]["filename"]
                          }
                        })
                      : _c("img", { attrs: { src: "#" } })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center mt-3" }, [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass: "ui text large bold [ color red-2 ] mb-0"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.productSlot[1]
                                ? _vm.productSlot[1].netPrice
                                : "0"
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "ui text large line-through" }, [
                        _vm._v(
                          _vm._s(
                            _vm.productSlot[1] ? _vm.productSlot[1].price : "0"
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(1)
                  ])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-3" }, [
            _c(
              "div",
              {
                staticClass: "item item-hover",
                class: { show: _vm.productSlot[2] }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "admin-item-btns",
                    on: {
                      click: function($event) {
                        _vm.currentSlot = 2
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.$parent.openModal(
                              "#selectProductSlot-" + _vm.errors.vmId
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "plus icon" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.$parent.openModal(
                              "#selectProductSlot-" + _vm.errors.vmId
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "edit outline icon" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.$parent.openModal(
                              "#deleteProduct-" + _vm.errors.vmId
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "trash alternate outline icon" })]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "product type-d py-2" }, [
                  _c(
                    "h5",
                    { staticClass: "ui text large bold text-truncate" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.productSlot[2]
                            ? _vm.productSlot[2].titleTH
                            : "Enchanted Rose Light"
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "thumb" }, [
                    _vm.productSlot[2]
                      ? _c("img", {
                          attrs: {
                            src:
                              "/storage/products/" +
                              _vm.productSlot[2]["images"][0]["filename"]
                          }
                        })
                      : _c("img", { attrs: { src: "#" } })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center mt-3" }, [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass: "ui text large bold [ color red-2 ] mb-0"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.productSlot[2]
                                ? _vm.productSlot[2].netPrice
                                : "0"
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "ui text large line-through" }, [
                        _vm._v(
                          _vm._s(
                            _vm.productSlot[2] ? _vm.productSlot[2].price : "0"
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(2)
                  ])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-3" }, [
            _c(
              "div",
              {
                staticClass: "item item-hover",
                class: { show: _vm.productSlot[3] }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "admin-item-btns",
                    on: {
                      click: function($event) {
                        _vm.currentSlot = 3
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.$parent.openModal(
                              "#selectProductSlot-" + _vm.errors.vmId
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "plus icon" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.$parent.openModal(
                              "#selectProductSlot-" + _vm.errors.vmId
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "edit outline icon" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.$parent.openModal(
                              "#deleteProduct-" + _vm.errors.vmId
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "trash alternate outline icon" })]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "product type-d py-2" }, [
                  _c(
                    "h5",
                    { staticClass: "ui text large bold text-truncate" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.productSlot[3]
                            ? _vm.productSlot[3].titleTH
                            : "Enchanted Rose Light"
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "thumb" }, [
                    _vm.productSlot[3]
                      ? _c("img", {
                          attrs: {
                            src:
                              "/storage/products/" +
                              _vm.productSlot[3]["images"][0]["filename"]
                          }
                        })
                      : _c("img", { attrs: { src: "#" } })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center mt-3" }, [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass: "ui text large bold [ color red-2 ] mb-0"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.productSlot[3]
                                ? _vm.productSlot[3].netPrice
                                : "0"
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "ui text large line-through" }, [
                        _vm._v(
                          _vm._s(
                            _vm.productSlot[3] ? _vm.productSlot[3].price : "0"
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(3)
                  ])
                ])
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("select-product-slot-modal", {
        attrs: {
          categories: _vm.categories,
          currentSlot: _vm.currentSlot,
          id: "selectProductSlot-" + _vm.errors.vmId
        },
        on: { setProductToSlot: _vm.setProductToSlot }
      }),
      _vm._v(" "),
      _c("delete-product-modal", {
        attrs: {
          currentSlot: _vm.currentSlot,
          id: "deleteProduct-" + _vm.errors.vmId
        },
        on: { deleteProductFromSlot: _vm.deleteProductFromSlot }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center ml-auto" }, [
      _c(
        "button",
        { staticClass: "ui circular red icon button d-inline-block p-2" },
        [_c("i", { staticClass: "icon-custom-add-cart large d-block" })]
      ),
      _vm._v(" "),
      _c("button", { staticClass: "ui circular default icon button p-1" }, [
        _c("i", { staticClass: "icon-custom-heart tiny" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center ml-auto" }, [
      _c(
        "button",
        { staticClass: "ui circular red icon button d-inline-block p-2" },
        [_c("i", { staticClass: "icon-custom-add-cart large d-block" })]
      ),
      _vm._v(" "),
      _c("button", { staticClass: "ui circular default icon button p-1" }, [
        _c("i", { staticClass: "icon-custom-heart tiny" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center ml-auto" }, [
      _c(
        "button",
        { staticClass: "ui circular red icon button d-inline-block p-2" },
        [_c("i", { staticClass: "icon-custom-add-cart large d-block" })]
      ),
      _vm._v(" "),
      _c("button", { staticClass: "ui circular default icon button p-1" }, [
        _c("i", { staticClass: "icon-custom-heart tiny" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center ml-auto" }, [
      _c(
        "button",
        { staticClass: "ui circular red icon button d-inline-block p-2" },
        [_c("i", { staticClass: "icon-custom-add-cart large d-block" })]
      ),
      _vm._v(" "),
      _c("button", { staticClass: "ui circular default icon button p-1" }, [
        _c("i", { staticClass: "icon-custom-heart tiny" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }