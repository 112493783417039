var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "nav",
      {
        staticClass:
          "navbar navbar-expand-lg navbar-light bg-white p-0 shadow-sm"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse",
            attrs: { id: "navbarNav" }
          },
          [
            _c("ul", { staticClass: "navbar-nav ml-auto align-items-center" }, [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column h-100 text-right",
                    style: "line-height:17px;"
                  },
                  [
                    _c("div", { staticClass: "text-primary" }, [
                      _vm._v("แพ็คเก็จของคุณ")
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.package.name) +
                          " / " +
                          _vm._s(
                            _vm.package.expiry_date == 30 ? "รายเดือน" : "รายปี"
                          )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.package.expired_day > 4
                            ? "text-success"
                            : "text-danger"
                        ]
                      },
                      [
                        _vm._v(
                          "เหลือ " + _vm._s(_vm.package.expired_day) + " วัน"
                        )
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item ml-2 bg-dark" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "d-flex flex-column h-100 sign-out px-2 py-1 text-white",
                    attrs: { href: "#" },
                    on: { click: _vm.logout }
                  },
                  [_vm._m(1), _vm._v(" "), _c("div", [_vm._v("ออกจากระบบ")])]
                )
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarNav",
          "aria-controls": "navbarNav",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("i", { staticClass: "fas fa-sign-out-alt" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }