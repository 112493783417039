<template>
  <div class="container mb-5">
    <div class="page-header">
      <h1> หน้าหลัก </h1>
    </div>
    <div class="row">
      <div class="col-12 mb-1">
        <i class="far fa-calendar-alt mr-1 mt-1"></i> {{today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear()}}
      </div>
      <div class="col-12">
        <div class="box-report row">
          <div class="col-md-3">
            <div class="media  border border-success rounded-1">
              <img class="align-self-center" :src="'/imgs/main/member.svg'"  alt="image">
              <div class="media-body ">
                สมาชิก<br>
                <span>{{repord.users}}</span> 
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="media border border-primary rounded-1">
              <img class="align-self-center" :src="'/imgs/main/allgoods.svg'"  alt="image">
              <div class="media-body align-self-center">
                สินค้าทั้งหมด<br>
                <span>{{repord.products}}</span> 
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="media border border-danger rounded-1">
              <img class="align-self-center" :src="'/imgs/main/allorder.svg'"  alt="image">
              <div class="media-body align-self-center">
                การสั่งซื้อ<br>
                <span>{{repord.orders}}</span> 
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="media border border-warning rounded-1">
              <img class="align-self-center" :src="'/imgs/main/sell_amount.svg'"  alt="image">
              <div class="media-body align-self-center">
                ยอดขาย<br>
                <span>{{repord.total}}&#3647;</span> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="repord.populars.length > 0" class="col-md-7 mt-3">
          <div class="box-popular">
           สินค้าขายดี
          </div>
          <div class="box-report row">
            <div class="col-md-12">
              <div class=" border rounded-1 p-3 ">
                <div class="row p-0 mb-2">
                  <div class="col-3 text-center">รูปสินค้า</div>
                  <div class="col-3 text-left">ชื่อสินค้า</div>
                  <div class="col-3 text-center">จำนวนขาย</div>
                  <div class="col-3 text-right">จำนวนเงิน</div>
                </div>
                <div v-for="popular in repord.populars" :key="popular.id" class="row pt-2 pb-2 border-top ">
                  <div class="col-3 text-center">
                    <img class="m-0" width="40px" :src="popular.image" />
                  </div>
                  <div class="col-3  d-flex align-content-center flex-wrap">
                    <div class="w-100 text-left">{{popular.name}}</div>
                  </div>
                  <div class="col-3  d-flex align-content-center flex-wrap">
                    <div class="w-100 text-center">{{popular.amount}}</div>
                  </div>
                  <div class="col-3  d-flex align-content-center flex-wrap">
                    <div class="w-100 text-right text-success">{{popular.total.toLocaleString()}}&#3647;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="col-md-5 mt-3">
          <div class="box-popular">
           เปรียบเทียบสินค้าขายดี
          </div>
          <div class="box-report row">
            <div class="col-12">
              <div v-if="values.length > 0" class="border rounded-1 pl-4 pr-4">
                <DonutChart  :initialValues="values"></DonutChart>
                <div class="row list-popular">
                  <div v-for="(item, index) in values" :key="index" class="col-md-4 mb-2">
                    <div v-if="index != 5 && repord.populars[index]"><span :style="'background-color:' + colors[index]"></span>{{ repord.populars[index] ? repord.populars[index].name : '?'}}</div>
                    <div v-if="index == 5 && repord.populars.length > 4"><span :style="'background-color:' + colors[index]"></span>อื่นๆ</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="col-md-12 mt-3">
          <div class="box-popular">
           การสั่งซื้อล่าสุด
          </div>
          <div class="box-report row">
            <div class="col-md-12">
              <div class=" border rounded-1 p-3 ">
                <div class="p-0 mb-2">
                  <div v-for="(item, index) in repord.newOrders" :key="index" class="row mb-2">
                    <div class="col-md-1">
                      <img src="/imgs/main/order.svg" class="img-fulid">
                    </div>
                    <div class="col-md-9 align-self-center">
                      มีรายการสั่งซื้อ เลขที่ออเดอร์ <span class="text-primary">{{item.order_no}}</span>
                      ชื่อ {{item.fullname}}
                      <span v-if="item.pay_type == 'ONLINE'" class="text-success">ได้ทำการชำระเงิน จำนวน {{item.total_price.toLocaleString()}} บาท</span>
                      <span v-else class="text-orange" >รอการชำระเงิน จำนวน {{item.total_price.toLocaleString()}} บาท</span>
                      <span class="date" v-tooltip="$moment(item.created_at).format('DD/MMMM/YYYY HH:mm:ss')">{{ $moment(item.created_at).fromNow() }}</span>
                    </div>
                    <div class="col-md-2 align-self-center">
                      <router-link :to="{name: 'orders.show', params: {id: item.id}}" class="btn btn-primary btn-sm">รายละเอียด</router-link>
                    </div>
                    <hr v-if="index != repord.newOrders.length - 1" class="col-12 mt-2 m-0 p-0">
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import DonutChart from '@/components/DonutChart'
export default {
  name: 'dashboard',
  components: {DonutChart},
  props: {},
  data() {
    return {
      loading: false,
      today: new Date(),
      repord: {
        users: 0,
        products: 0,
        orders: 0,
        total: 0,
        populars:[],
        newOrders: [],
      },
      values: [],
      colors: ["#708DF4", "#F77091", "#F6CB5E", "#72C1F5", "#95E067","#5A5858"],
      popularsName:[],
    }
  },
  computed: {},
  created() {
    this.application()
    this.dashboard()
  },
  methods: {
    application(){
      axios
      .get('/api/admin/applications')
      .then(res => {
        if(res.data.data.theme_color == null){
          this.$router.push('/guide')
        }
      })
      .catch(error => {
      })
    },
    dashboard(){
      this.loading = true
      axios
      .get('/api/admin/dashboard')
      .then(res => {
        this.repord = res.data.data
        this.values = this.repord.chart
      })
      .catch(error => {
      })
      .finally(() => this.loading = false)
    },
  }
}
</script>

<style lang="scss" scoped>
.rounded-1{
  border-radius: 1rem;
  position: relative;
}
.media-body{
  text-align: right;
  padding: 1rem 1.2rem;
  font-size: 1.2rem;
  line-height: 2rem;
  span{
    font-size: 2rem;
    font-weight: bold;
  }
}
.box-popular{
  font-size: 1.2rem;
}
.box-report img{
    margin-left: 1.2rem;
  }
  .list-popular{
    div{
      position: relative;
      padding-left: 25px;
      font-size: .7rem;
      span{
        left: 0px;
        top: -3px;
        position: absolute;
        width: 20px;
        height: 20px;
      }
    }
  }
  .text-orange{
    color:orange;
  }
  .date{
    color: #75757570;
  }
 
</style>
