<template>
  <section class="admin-section admin-section--banner">
    <div class="inner-wrap container item-hover">
      <div class="row d-block">
        <div class="content">
          <img class="w-100 d-block" src="/imgs/contents/admin-banner.svg" v-if="!hasBanner">
          <img class="w-100 d-block img-fluid" :src="banner.url" v-for="banner in banners" v-else height="280">
        </div>
      </div>
      <div class="admin-btns admin-btns--sectionedit">
        <a class="ui button white basic d-block mt-1 mb-1 d-xl-flex js-modal-btn" @click.prevent="$parent.openModal('#editBannerModal')">
          <i class="d-none d-xl-block edit outline icon"></i>แก้ไข
        </a>
      </div>
    </div>
    <div class="modal fade edit-banner-modal" tabindex="-1" role="dialog" id="editBannerModal">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title color black h3">อัพโหลดแบนเนอร์ไฟล์ .JPG หรือ .PNG (ขนาดแนะนำ 1923 x 954 pixel)</p>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <el-upload action="/api/admin/banners/upload" list-type="picture-card" :multiple="true" :headers="headers" :file-list="banners" :before-remove="beforeRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="modal-footer">
            <a class="ui button red basic text" data-dismiss="modal"><span class="ui text large">ยกเลิก</span></a>
            <a class="ui button blue text"><span class="ui text large" data-dismiss="modal">บันทึก</span></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'cover',
    components: {},
    props: {},
    data() {
      return {
        banners: []
      }
    },
    computed: {
      headers() {
        return axios.defaults.headers.common
      },
      hasBanner () {
        return this.banners.length > 0
      }
    },
    created() {
      this.getBanner()
    },
    methods: {
      getBanner() {
        axios
          .get('/api/admin/banners')
          .then(result => (this.banners = result.data.data))
      },
      async beforeRemove(file) {
        let confirm = await this.$confirm('ต้องการลบรูปแบนเนอร์ ?')
        if (confirm) {
          return axios.delete(`/api/admin/banners/${file.id}`)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .bannerImage {
    object-fit: cover;
  }
</style>
