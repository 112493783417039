<template>
  <div class="container-fluid h-100" id="login-wrapper">
    <div class="row h-100 align-items-center justify-content-center">
      <div class="col-md-4 col-sm-12">
        <form class="card login-card shadow" @submit.prevent="sendResetPassword">
          <div class="card-body">
            <h3 class="text-center font-weight-bold py-3">Reset Password</h3>
            <div class="form-group">
              <div class="input-icon">
                <div class="input-icon-addon">
                  <i class="fas fa-envelope" :class="{ 'text-danger': msg.email != undefined }"></i>
                </div>
                <input type="text" class="form-control form-control-lg" :class="{ 'is-invalid': msg.email != undefined }" placeholder="Email" v-model="email" />
              </div>
              <div class="invalid-feedback" v-if="msg.email != undefined">
                {{ msg.email[0] }}
              </div>
            </div>
            <div class="form-group">
              <div class="input-icon">
                <div class="input-icon-addon">
                  <i class="fas fa-lock" :class="{ 'text-danger': msg.password }"></i>
                </div>
                <input type="password" class="form-control form-control-lg" :class="{ 'is-invalid': msg.password }" placeholder="Password" v-model="password" />
              </div>
              <div class="invalid-feedback" v-if="msg.password">
                {{ msg.password[0] }}
              </div>
            </div>
            <div class="form-group">
              <div class="input-icon">
                <div class="input-icon-addon">
                  <i class="fas fa-lock" :class="{ 'text-danger': msg.confirmPassword }"></i>
                </div>
                <input type="password" class="form-control form-control-lg" :class="{ 'is-invalid': msg.confirmPassword }" placeholder="Confirm Password" v-model="confirmPassword" />
              </div>
              <div class="invalid-feedback" v-if="msg.confirmPassword">
                {{ msg.confirmPassword[0] }}
              </div>
            </div>
            <div class="form-group">
              <button class="btn btn-lg btn-block btn-orange" :disabled="loading">
                Reset Password
              </button>
            </div>
          </div>
        </form>
        <div class=" mt-3 d-flex align-items-center justify-content-center">
          <p>Powered by <img src="/imgs/logo.png" height="24" width="150" /></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'reset',
    data() {
      return {
        email: 'ekkarat@wewillapp.com',
        password: '',
        confirmPassword: '',
        token: this.$route.params.token,
        msg: {},
        loading: false
      }
    },
    computed: {},
    created() {},
    methods: {
      sendResetPassword() {
        this.loading = true
        let { email, password ,confirmPassword, token } = this
        var data = {
            email : email,
            password : password ,
            password_confirmation : confirmPassword, 
            token : token
        }
        axios.post('/api/admin/password/reset',data)
            .then(res => {
                this.$router.push({ name: 'login' })
            })
            .catch(error => {
                switch (error.response.status) {
                case 422:
                    if(error.response.data.msg){
                        this.msg = error.response.data.msg
                    }else{
                        var message = error.response.data.message
                        var msg = {}
                        if(message.search('email') > 0 || message.search('user') > 0 ){
                            msg.email  = [message]
                        }
                        if(message.search('password') > 0 && message.search('password confirmation') < 0 ){
                            msg.password  = [message]
                        }
                        if(message.search('password confirmation') > 0 ){
                            msg.confirmPassword  = [message]
                        }
                        console.log(msg)
                        this.msg = msg
                    }
                    break
                case 404:
                    this.msg = { email: ['ไม่พบชื่อผู้ใช้งานในระบบ'] }
                    break
                default:
                    break
                }
            })
            .finally(() => (this.loading = false))
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '~$sass/variables';

#login-wrapper {
  background-image: url('/imgs/login-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
.login-card {
  color: #fff;
  background-image: linear-gradient(to right, #309fdd, #1caebc);
  border: none;
  input.form-control {
    border-color: transparent !important;
    &.is-invalid {
      border-color: $red !important;
      background-color: lighten($red, 45%);
      color: $red;
      &::placeholder {
        color: $red;
      }
    }
    &::placeholder {
      font-weight: 300;
    }
  }
  .input-icon {
    position: relative;
    .input-icon-addon {
      position: absolute;
      color: $gray-600;
      height: 100%;
      padding: 0 1rem;
      display: flex;
      align-items: center;
    }
    .form-control {
      padding-left: 2.5rem;
    }
  }
}
.invalid-feedback {
  display: block;
}
</style>
