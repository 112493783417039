<template>
  <div class="container-fluid h-100" id="login-wrapper">
    <div class="row h-100 align-items-center justify-content-center">
      <div class="col-md-4 col-sm-12">
        <form class="card login-card shadow" @submit.prevent="sendLoginRequest">
          <div class="card-body">
            <h3 class="text-center font-weight-bold py-3">Admin Login</h3>
            <div class="form-group">
              <div class="input-icon">
                <div class="input-icon-addon">
                  <i class="fas fa-user" :class="{ 'text-danger': loginErrors.username != null }"></i>
                </div>
                <input type="text" class="form-control form-control-lg" :class="{ 'is-invalid': loginErrors.username != null }" placeholder="Username" v-model="username" />
              </div>
              <div class="invalid-feedback" v-if="loginErrors.username != null">
                {{ loginErrors.username }}
              </div>
            </div>
            <div class="form-group">
              <div class="input-icon">
                <div class="input-icon-addon">
                  <i class="fas fa-lock" :class="{ 'text-danger': loginErrors.password }"></i>
                </div>
                <input type="password" class="form-control form-control-lg" :class="{ 'is-invalid': loginErrors.password }" placeholder="Password" autocomplete="new-password" v-model="password" />
              </div>
              <div class="invalid-feedback" v-if="loginErrors.password">
                {{ loginErrors.password }}
              </div>
            </div>
            <div class="form-group text-right">
              <a data-toggle="modal" data-target="#showModalForgot" class="text-white">ลืมรหัสผ่าน ?</a>
            </div>
            <div class="form-group">
              <button class="btn btn-lg btn-block btn-orange" :disabled="loading">
                เข้าสู่ระบบ
              </button>
            </div>
          </div>
        </form>
        <div class=" mt-3 d-flex align-items-center justify-content-center">
          <p>Powered by <img src="/imgs/logo.png" height="24" width="150" /></p>
        </div>
      </div>
    </div>
    <div class="modal fade" id="showModalForgot" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                    <div class="ui segment pl-0 pr-0">
                        <h2 class="ui header text-center mt-2 color theme">ลืมรหัสผ่าน</h2>
                        <div class="content">
                            <div class="col-12">
                                <p class="text color gray-7 text-center"></p>
                            </div>
                            <div class="mt-3">
                                <div class="ui labeled input col-12">
                                    <div class="input-icon">
                                      <input class="form-control form-control-lg" v-model="inputForgotEmail" type="text" placeholder="E-mail">
                                      <span v-if="message != ''" class="invalid-feedback">{{message}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-3 mb-3">
                                <button  @click="forgotEmail" :disabled="loading" class="btn btn-lg btn-block btn-orange">ส่ง</button>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'login',
    data() {
      return {
        username: '',
        password: '',
        loginErrors: {
          username: null,
          password: null
        },
        loading: false,
        inputForgotEmail: '',
        message: '',
      }
    },
    computed: {},
    created() {},
    watch: {
      username(){
        this.loginErrors.username = null
        this.loginErrors.password = null
      },
      password(){
        this.loginErrors.username = null
        this.loginErrors.password = null
      }
    },
    methods: {
      sendLoginRequest() {
        this.loading = true
        let { username, password } = this
        this.$store
          .dispatch('auth/login', { username, password })
          .then(result => {
            this.$router.push({ name: 'admins.index' })
          })
          .catch(error => {
              this.loginErrors =  {
                'username' : ' ',
                'password' : 'ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง',
              };
          })
          .finally(() => (this.loading = false))
      },
      forgotEmail(){
        this.loading = true
          axios
          .post('/api/admin/password/email',{email: this.inputForgotEmail})
          .then(response => {
              $('#showModalForgot').modal('hide');
              this.message = ''
              this.$notify.success({
                title: 'Success',
                message: 'กรุณาตรวจสอบในอีเมล'
              })
          })
          .catch(error => {
              if(!error.response){
                  return
              }
              let message = error.response.data.loginErrors.message
              let snakeCase = error.response.data.loginErrors.snakeCase
              this.message = 'กรุณากรอก email'
              switch (snakeCase) {
                  case 'the_email_must_be_a_valid_email_address.':
                    this.message = 'ไม่พบ email ในระบบ'
                    break;
              }
          })
          .finally(() => (this.loading = false))
      },
    }
  }
</script>

<style lang="scss" scoped>
@import '~$sass/variables';

#login-wrapper {
  background-image: url('/imgs/login-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
.login-card {
  color: #fff;
  background-image: linear-gradient(to right, #309fdd, #1caebc);
  border: none;
  input.form-control {
    border-color: transparent !important;
    &.is-invalid {
      border-color: $red !important;
      background-color: lighten($red, 45%);
      color: $red;
      &::placeholder {
        color: $red;
      }
    }
    &::placeholder {
      font-weight: 300;
    }
  }
  .input-icon {
    position: relative;
    .input-icon-addon {
      position: absolute;
      color: $gray-600;
      height: 100%;
      padding: 0 1rem;
      display: flex;
      align-items: center;
    }
    .form-control {
      padding-left: 2.5rem;
    }
  }
}
.invalid-feedback {
  display: block;
}
</style>
