<template>
  <div class="admin-preview" v-show="!loading">
    <!-- main navbar -->
    <div class="container pt-2 pl-0">
      <a href="/admin/home" class="btn btn-gradient-primary btn-lg"><i class="fas fa-home mr-1"></i>กลับหน้าหลัก</a>
    </div>
    <!-- main navbar -->
    <div class="admin-preview-container">
      <header class="admin-preview__header admin-section">
        <div class="inner-wrap container">
          <div class="[ ui borderless main ] [ m-0 pt-lg-4 pt-2 pb-lg-4 d-block ]">
            <div class="[ align-items-center d-flex ]">
              <div class="d-block d-lg-none col-8">
                <div class="thumb--logo item item-hover" :class="{'show': logoImage}">
                  <div class="admin-item-btns">
                    <div class="admin-item-btns d-flex">
                      <div class="d-flex">
                        <label class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" for="logoImage">
                          <i class="plus icon color theme"></i>
                        </label>
                        <label class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" for="logoImage">
                          <i class="edit outline icon"></i>
                        </label>
                        <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="openModal('#deleteLogoModal')">
                          <i class="trash alternate outline icon"></i>
                        </a>
                        <input type="file" class="d-none" id="logoImage" @change="handleUploadLogoImage">
                      </div>
                    </div>
                  </div>
                  <img class="w-100 d-block" :src="logoImage">
                </div>
              </div>
              <div class="col col-lg-3">
                <div class="thumb--logo item item-hover" :class="{'show': logoImage}">
                  <div class="admin-item-btns">
                    <div class="admin-item-btns d-flex">
                      <div class="d-flex">
                        <label class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" for="logoImage-mobile">
                          <i class="plus icon color theme"></i>
                        </label>
                        <label class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" for="logoImage-mobile">
                          <i class="edit outline icon"></i>
                        </label>
                        <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="openModal('#deleteLogoModal')">
                          <i class="trash alternate outline icon"></i>
                        </a>
                        <input type="file" class="d-none" id="logoImage-mobile" @change="handleUploadLogoImage">
                      </div>
                    </div>
                  </div>
                  <img class="w-100 d-block" :src="logoImage">
                </div>
              </div>
              <div class="[ d-lg-flex d-none d-lg-block align-items-center ml-auto ]">
                <span class="ui text medium">Currency:
                  <div class="[ ui inline dropdown ] [ ml-1 ]">
                    <input type="hidden" name="Currency">
                    <div class="text">THB</div><i class="dropdown icon"></i>
                    <div class="menu">
                      <div class="item" data-value="1">THB</div>
                      <div class="item active" data-value="0">US</div>
                    </div>
                  </div></span> <span class="[ ml-4 ] ui text medium">Language:<div class="[ ui inline dropdown ] [ ml-1 ]"><input type="hidden" name="language">
                    <div class="text">TH</div><i class="dropdown icon"></i>
                    <div class="menu">
                      <div class="item" data-value="1">TH</div>
                      <div class="item active" data-value="0">EN</div>
                    </div>
                  </div>
                </span>
                <a class="[ ui text medium ] [ ml-4 align-items-center d-flex ] [ color black ]" href="#">
                  <i class="dollar sign icon large [ mr-0 ] [ color black ]"></i> Method of Payment
                </a>
                <a class="[ ui text medium ] [ ml-4 align-items-center d-flex ] [ color black ]" href="#">
                  <i class="phone volume icon large [ mr-0 ] [ color black ]"></i> 096-8917824
                </a>
              </div>
            </div>
            <div class="[mt-2 ] d-none d-lg-block" style="padding:3.5%">
              <div class="row align-items-center">
                <div class="col col-3 [ d-lg-flex d-none d-lg-block align-items-center ]">
                  <div class="[ align-items-center d-flex ] [ ui dropdown item ]" href="#">
                    <i class="icon-custom-burger-menu large color black"></i>
                    <span class="[ ml-3 ] [ ui text large color black ]">หมวดหมู่สินค้า</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="[ d-flex align-items-center ]">
                    <div class="w-100">
                      <div class="w-100 ui input">
                        <input class="ui input black br-0" type="text" placeholder>
                        <button class="ui button black br-0 d-flex align-items-center px-3 py-2">
                          <i class="ui icon search massive mb-2 d-block"></i>
                          <span class="ui text large">ค้นหา</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col-5">
                  <div class="[ d-flex align-items-center text-right ]">
                    <a class="[ text ] [ ml-4 text-center ml-auto ] [ color gray-4 ]" href="#">
                      <i class="icon-custom-lock big [ mr-auto ml-auto d-block ]"></i>
                      <span class="text tiny d-block mt-1">ออกจากระบบ</span>
                    </a>
                    <a class="[ text ] [ d-none d-lg-block ml-4 text-center ] [ color gray-4 ]" href="#">
                      <i class="icon-custom-heart big [ mr-auto ml-auto d-block ]"></i>
                      <span class="text tiny d-block mt-1">รายการที่ชอบ</span>
                    </a>
                    <a class="[ text ] [ ml-4 text-center ] [ color gray-4 ]" href="#">
                      <i class="icon-custom-cart big [ mr-auto ml-auto d-block ]"></i>
                      <span class="text tiny d-block mt-1">ตะกร้า</span>
                    </a>
                    <a class="[ text ] [ ml-4 text-center ] [ d-none d-lg-block color gray-4 ]" href="#">
                      <i class="icon-custom-profile big [ mr-auto ml-auto d-block ]"></i>
                      <span class="text tiny d-block mt-1">ส่วนตัว</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-block d-lg-none"><img class="w-100 d-block" src="/imgs/contents/admin-mobile-header-bottom.svg"></div>
          </div> <!-- Navbar -->
        </div>
      </header>
      <template v-for="theme in themes">
        <component :is="theme.components.name" :theme-data="theme.data" :categories="categories" :id="theme.id" @onDelete="setCurrentDeleteComponent" />
      </template>
      <footer class="footer">
        <div class="container bg white"><img class="d-block w-100" src="/imgs/contents/admin-footer.svg"></div>
      </footer>

      <div class="modal fade" role="dialog" tabindex="-1" id="addSectionModal">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <p class="modal-title color black h3">เลือกรูปแบบ</p>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12 steps-list">
                  <div class="row align-items-center mt-2">
                    <div class="col-12">
                      <p class="ui text big color black">รูปแบบที่ 1</p>
                    </div>
                    <div class="col ui checkbox">
                      <input type="radio" name="step-style" v-model="sectionModalData.selectComponent" value="section-title">
                      <label class="d-block">
                        <img class="w-100" src="/imgs/contents/admin-section-content-2.svg">
                      </label>
                    </div>
                  </div>
                  <div class="row align-items-center mt-2">
                    <div class="col-12">
                      <p class="ui text big color black">รูปแบบที่ 2</p>
                    </div>
                    <div class="col ui checkbox">
                      <input type="radio" name="step-style" v-model="sectionModalData.selectComponent" value="product-list1">
                      <label class="d-block">
                        <img class="w-100" src="/imgs/contents/admin-section-products-1.svg">
                      </label>
                    </div>
                  </div>
                  <div class="row align-items-center mt-2">
                    <div class="col-12">
                      <p class="ui text big color black">รูปแบบที่ 3</p>
                    </div>
                    <div class="col ui checkbox">
                      <input type="radio" name="step-style" v-model="sectionModalData.selectComponent" value="product-list2">
                      <label class="d-block">
                        <img class="w-100" src="/imgs/contents/admin-section-products-2.svg">
                      </label>
                    </div>
                  </div>
                  <div class="row align-items-center mt-2">
                    <div class="col-12">
                      <p class="ui text big color black">รูปแบบที่ 4</p>
                    </div>
                    <div class="col ui checkbox">
                      <input type="radio" name="step-style" v-model="sectionModalData.selectComponent" value="product-list3">
                      <label class="d-block">
                        <img class="w-100" src="/imgs/contents/admin-section-products-3.svg">
                      </label>
                    </div>
                  </div>
                  <div class="row align-items-center mt-2">
                    <div class="col-12">
                      <p class="ui text big color black">รูปแบบที่ 5</p>
                    </div>
                    <div class="col ui checkbox">
                      <input type="radio" name="step-style" v-model="sectionModalData.selectComponent" value="product-list4">
                      <label class="d-block">
                        <img class="w-100" src="/imgs/contents/admin-section-products-4.svg">
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a class="ui button red basic text" data-dismiss="modal"><span class="ui text large">ยกเลิก</span></a>
              <a class="ui button blue text" @click.prevent="onUserSelectedSection"><span class="ui text large">บันทึก</span></a>
            </div>
          </div>
        </div>
      </div> <!-- addSectionModal -->
      <div class="modal fade" role="dialog" tabindex="-1" id="deleteSectionModal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <p class="modal-title color black h3 mx-auto">ลบรูปแบบ</p>
            </div>
            <div class="modal-body">
              <div class="text-center">
                <i class="far fa-trash-alt color red-1 mb-3" style="font-size: 12rem;"></i>
              </div>
              <p class="ui text large color black text-center">คุณต้องการลบรูปแบบหรือไม่</p>
            </div>
            <div class="modal-footer">
              <a class="ui button red basic text" data-dismiss="modal"><span class="ui text large">ยกเลิก</span></a>
              <a class="ui button blue text" @click.prevent="handleDeleteComponent"><span class="ui text large">บันทึก</span></a>
            </div>
          </div>
        </div>
      </div> <!-- deleteSectionModal -->
      <div class="modal fade" role="dialog" tabindex="-1" id="deleteLogoModal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <p class="modal-title color black h3 mx-auto">ลบโลโก้</p>
            </div>
            <div class="modal-body">
              <div class="text-center">
                <i class="far fa-trash-alt color red-1 mb-3" style="font-size: 12rem;"></i>
              </div>
              <p class="ui text large color black text-center">คุณต้องการลบโลโก้หรือไม่</p>
            </div>
            <div class="modal-footer">
              <a class="ui button red basic text" data-dismiss="modal"><span class="ui text large">ยกเลิก</span></a>
              <a class="ui button blue text" @click.prevent="handleDeleteLogoImage"><span class="ui text large">บันทึก</span></a>
            </div>
          </div>
        </div>
      </div> <!-- deleteSectionModal -->
    </div>
  </div>

</template>

<script>
  require('semantic-ui/dist/components/checkbox.js')
  require('bootstrap-select-v4/js/bootstrap-select.js')

  import Cover from './components/Cover'
  import SectionTitle from './components/SectionTitle'
  import ProductList1 from './components/ProductList1'
  import ProductList2 from './components/ProductList2'
  import ProductList3 from './components/ProductList3'
  import ProductList4 from './components/ProductList4'
  import Step from './components/Step'

  export default {
    name: 'contents',
    components: {
      Cover,
      SectionTitle,
      ProductList1,
      ProductList2,
      ProductList3,
      ProductList4,
      Step
    },
    props: {},
    data() {
      return {
        logoImage: '',
        themes: [],
        categories: [],
        loading: true,
        selectedThemeId: 0,
        sectionModalData: {
          themeId: undefined,
          position: undefined,
          selectComponent: undefined
        }
      }
    },
    computed: {},
    mounted() {
      // inject scoped css
      let styled = document.createElement('style')
      styled.setAttribute('lang', 'css')
      styled.innerHTML = `
          @import url('/css/contents/vendors.css');
          @import url('/css/contents/admin-preview.css');
          @import url('/css/contents/reset.css')`
      document.head.appendChild(styled)
      $(function() {
        $('.ui.checkbox').checkbox()
      })
      $('#addSectionModal').on('hide.bs.modal', () => {
        this.sectionModalData = {
          themeId: undefined,
          position: undefined,
          selectComponent: undefined
        }
      })
      $('#deleteSectionModa').on('hide.bs.modal', () => {
        this.selectedThemeId = 0
      })
    },
    created() {
      this.getTheme()
      this.getLogo()
      setTimeout(() => {
        this.loading = false
      }, 3000)
      this.fetchCategoryList()

    },
    methods: {
      openModal(element) {
        console.log(element) 
        $(element).modal('toggle')
      },
      getLogo() {
        axios
          .get('/api/admin/banners')
          .then(result => (this.logoImage = result.data.logo))
      },
      handleUploadLogoImage(e) {
        let file = e.target.files[0]
        let form = new FormData()
        form.append('file', file)
        axios.post('/api/admin/banners/upload/logo', form).then(res => {
          this.logoImage = res.data
          e.target.value = ''
        })
      },
      handleDeleteLogoImage () {
        axios.delete('/api/admin/banners/upload/logo').then(res => {
          this.logoImage = ''
          this.getLogo()
          this.$notify.success('ลบโลโก้สำเร็จ')
        })
      },
      getTheme() {
        axios.get('/api/admin/themes').then(res => (this.themes = res.data.data))
      },
      setCurrentDeleteComponent(themeId) {
        console.log('themeId', themeId)
        this.selectedThemeId = themeId
      },
      handleDeleteComponent() {
        axios.delete(`/api/admin/themes/${this.selectedThemeId}`).then(res => {
          this.getTheme()
          this.$notify.success('ลบเนื้อหาสำเร็จ')
          $('#deleteSectionModal').modal('hide')
        })
      },
      logout() {
        this.$store
          .dispatch('auth/logout')
          .then(() => (location.href = '/admin/login'))
      },
      showSectionModal (themeId, position) {
        this.sectionModalData = { themeId, position }
        $('#addSectionModal').modal('show')
      },
      onUserSelectedSection () {
        let findThemeIndex = this.themes.findIndex(theme => theme.id == this.sectionModalData.themeId)
        if (findThemeIndex != -1) {
          let start = this.sectionModalData.position == 'top'
            ? findThemeIndex
            : findThemeIndex+1

          let data = this.themes.slice()
          data.splice(start, 0, {
            componentName: this.sectionModalData.selectComponent,
            isNew: true
          })
          axios.patch('/api/admin/themes', {themes: data}).then(res => {
            this.getTheme()
            this.$notify.success('เพิ่มส่วนเนื้อหาสำเร็จ')
          })
        }
        $('#addSectionModal').modal('hide')
      },
      fetchCategoryList() {
        axios
        .get('/api/admin/categories')
        .then(result => {
          this.categories = result.data.data
          })
      },
    }
  }
</script>

<style lang="scss" scoped>
  body {
    font-size: 14px;
  }
  @media (min-width: 1200px) {
    .container >>> {
      max-width: 1140px !important;
    }
  }
  i.large.icon, i.large.icons {
    font-size: 1rem;
  }
  .container >>> {
    padding: 7.5px;
  }
  a {
    text-decoration: none !important;
  }
</style>
