<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header">
          <h1>ผู้ใช้งาน</h1>
        </div>
        <div class="custom-card mt-3">
          <div class="card-header">
            <div class="row">
              <div class="col-6 mx-auto">
                <form class="input-group">
                  <input type="text" class="form-control" placeholder="ค้นหาผู้ใช้งาน" v-model="search" />
                  <div class="input-group-append">
                    <button class="btn btn-gradient-primary" @click.prevent="searchUser">
                      <i class="fas fa-search"></i>
                    </button>
                    <button class="btn btn-danger" @click.prevent="getResults">
                      <i class="fas fa-redo mr-1"></i>ล้างค่า
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-center table-bordered">
                <thead>
                  <tr class="bg-light">
                    <th style="border-right-color: transparent; vertical-align:middle;" class="text-center">
                      <input type="checkbox" v-model="selectAll" />
                    </th>
                    <th colspan="6" class="text-left">
                      <div class="d-flex">
                        <div>
                          <a href="#" class="btn btn-outline-danger btn-sm mr-3" @click.prevent="deleteManyUser">
                            <i class="far fa-trash-alt mr-1"></i>ลบ
                          </a>
                        </div>
                        <div>
                          <select name="enabledAll" class="custom-select custom-select-sm" @change="toggleEnableManyUser">
                            <option selected disabled>กรุณาเลือก</option>
                            <option :value="true">เปิดการใช้งาน</option>
                            <option :value="false">ปิดการใช้งาน</option>
                          </select>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr class="bg-light text-center">
                    <th></th>
                    <th>รูป</th>
                    <th>ชื่อ-สกุล</th>
                    <th>อีเมล์</th>
                    <th>สถานะการใช้งาน</th>
                    <th>เบอร์โทรศัพท์</th>
                    <th>จัดการ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in laravelData.data" :key="user.id" class="text-center">
                    <td class="text-center">
                      <input type="checkbox" v-model.number="selectedUser" :value="user.id" class="select" />
                    </td>
                    <td>
                      <span class="avatar avatar-md" :style="`background-image: url(/storage/users/avatar/${user.avatar})`" v-if="user.avatar"></span>
                      <span class="avatar avatar-md" v-else>A</span>
                    </td>
                    <td>{{ user.firstname }} {{ user.lastname }}</td>
                    <td>{{ user.email }}</td>
                    <td>
                      <div class="custom-control custom-switch">
                        <input type="checkbox" 
                          class="custom-control-input" 
                          :id="'customSwitch'+user.id" 
                          v-model="user.enabled" 
                          @change="toggleEnableUser(user.id)">
                        <label class="custom-control-label" :for="'customSwitch'+user.id" ></label>
                      </div>
                    </td>
                    <td>{{ user.phone }}</td>
                    <td class="text-center">
                      <div class="dropdown">
                        <a href="#" class="link-unstyled border-0" data-toggle="dropdown">
                          <!-- <i class="fas fa-ellipsis-v"></i> -->
                          <span class="avatar avatar-sm"><i class="fas fa-ellipsis-h"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <router-link :to="{name: 'users.show',params: { id: user.id }}" class="dropdown-item text-primary">
                            <i class="far fa-eye mr-1"></i>ดู
                          </router-link>
                          <a href="#" class="dropdown-item text-danger" @click.prevent="deleteUser(user.id)"><i class="far fa-trash-alt mr-1"></i>ลบ</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <pagination :data="laravelData" @pagination-change-page="getResults" class="justify-content-end"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'user',
    components: {},
    props: {},
    data() {
      return {
        search: '',
        laravelData: {},
        selectedUser: []
      }
    },
    computed: {
      selectAll: {
        get: function() {
          return this.laravelData.data
            ? this.selectedUser.length == this.laravelData.data.length
            : false
        },
        set: function(value) {
          var selected = []

          if (value) {
            this.laravelData.data.forEach(function(user) {
              selected.push(user.id)
            })
          }
          return (this.selectedUser = selected)
        }
      }
    },
    created() {
      this.getResults()
    },
    methods: {
      getResults(page = 1) {
        this.search = ''
        axios.get('/api/admin/users?page=' + page).then(response => {
          this.laravelData = response.data
        })
      },
      toggleEnableUser(userId) {
        console.log(userId)
        axios.post(`/api/admin/users/${userId}`).then(res => this.getResults())
      },
      deleteUser(userId) {
        this.$confirm(`ต้องการลบ user ใช่หรือไม่`, 'ลบ', {
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            dangerouslyUseHTMLString: true,
            type: 'warning'
            }).then(() => {
            if (confirm) {
              axios.delete(`/api/admin/users/${userId}`).then(res => this.getResults())
            }
          })
      },
      searchUser() {
        axios
          .get(`/api/admin/users?search=${this.search}`)
          .then(res => (this.laravelData = res.data))
      },
      toggleEnableManyUser(event) {
        axios
          .post('/api/admin/users/enabled', {
            users: this.selectedUser,
            enabled: event.target.value
          })
          .then(res => this.getResults())
      },
      deleteManyUser() {
        this.$confirm(`ต้องการลบ users ${
              this.selectedUser.length
            } รายการ ใช่หรือไม่`, 'ลบ', {
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            dangerouslyUseHTMLString: true,
            type: 'warning'
            }).then(() => {
            if (confirm) {
              let form = new FormData()
              form.append('_method', 'DELETE')
              this.selectedUser.forEach(user => form.append('users[]', user))
              axios.post('/api/admin/users', form).then(res => {
                this.getResults()
                this.$notify.success({
                  title: 'Success',
                  message: 'ลบสำเร็จ'
                })
              })
                
            }
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
tbody > tr > td {
  padding: 0.5rem;
}
</style>
