var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "custom-card" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "form-group" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.facebook,
                    expression: "facebook"
                  }
                ],
                staticClass: "form-control form-control-lg",
                attrs: { type: "text" },
                domProps: { value: _vm.facebook },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.facebook = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _vm._m(3),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.instagram,
                    expression: "instagram"
                  }
                ],
                staticClass: "form-control form-control-lg",
                domProps: { value: _vm.instagram },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.instagram = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _vm._m(4),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.line,
                    expression: "line"
                  }
                ],
                staticClass: "form-control form-control-lg",
                domProps: { value: _vm.line },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.line = $event.target.value
                  }
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-4 text-center" }, [
          _c(
            "a",
            {
              staticClass: "btn  btn-lg btn-gradient-primary",
              attrs: { href: "#", role: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.updateSiteSetting($event)
                }
              }
            },
            [_vm._v("บันทึก")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h1", [_vm._v("โซเชียล")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("เพิ่ม URL ร้านค้า")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "d-flex align-items-center" }, [
      _c("i", {
        staticClass: "fab fa-facebook fa-2x mr-2",
        staticStyle: { color: "#3b5999" }
      }),
      _vm._v(" "),
      _c("span", [_vm._v("URL Facebook")]),
      _vm._v(" "),
      _c("small", { staticClass: "text-muted" }, [
        _vm._v("\n                (เช่น www.facebook.com/store.me)")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "d-flex align-items-center" }, [
      _c("i", {
        staticClass: "fab fa-instagram fa-2x mr-2",
        staticStyle: { color: "#e4405f" }
      }),
      _vm._v(" "),
      _c("span", [_vm._v("URL Instagram")]),
      _vm._v(" "),
      _c("small", { staticClass: "text-muted align-self-center" }, [
        _vm._v("\n                (เช่น www.instagram.com/store.me)")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "d-flex align-items-center" }, [
      _c("i", {
        staticClass: "fab fa-line fa-2x mr-2",
        staticStyle: { color: "#00c300" }
      }),
      _vm._v(" "),
      _c("span", [_vm._v("ID Line")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }