const state = {
  admin: {},
  isLogged: !!localStorage.getItem('token')
}

const getters = {
  admin: state => state.admin,
  isLogged: state => state.isLogged
}

const actions = {
  async login({ commit }, params) {
    await axios
      .post('/api/admin/login', params)
      .then(result => {
        commit('setLoginData', result.data.data)
      })
      .catch(error => {
        throw error
      })
  },
  logout({ commit }, params) {
    localStorage.removeItem('token')
    commit('logout')
  },
  getProfileData({ commit }) {
    axios
      .get(`/api/admin/user`)
      .then(result => commit('setProfileData', result.data.data))
  }
}

const mutations = {
  setLoginData(state, data) {
    window.axios.defaults.headers.common['Authorization'] =
      'Bearer ' + data.token
    localStorage.setItem('token', data.token)
  },
  setProfileData(state, data) {
    state.admin = data
  },
  logout(state) {
    state.admin = {}
    state.isLogged = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
