<template>
  <section class="admin-section">
    <div class="inner-wrap container bg white">
      <div class="admin-btns admin-btns--sectionedit">
        <a class="ui button white basic d-block mt-1 mb-1 d-xl-flex js-modal-btn" @click.prevent="handleDeleteComponentModal">
          <i class="d-none d-xl-block trash alternate outline icon"></i>ลบ
        </a>
      </div>
      <a class="admin-btns admin-btns--add-section-top circular ui icon button blue js-modal-btn" @click.prevent="$parent.showSectionModal(id, 'top')"><i class="plus icon"></i></a>
      <a class="admin-btns admin-btns--add-section-bottom circular ui icon button blue js-modal-btn" @click.prevent="$parent.showSectionModal(id, 'bottom')"><i class="plus icon"></i></a>
      <div class="row d-block">
        <div class="pt-4 pb-4">
          <div class="products-list set-1 row">
            <div class="col-lg-4 products-list__column">
              <div class="row">
                <div class="col-12 top">
                  <div class="row mt-3">
                    <div class="col-12">
                      <div class="item item-hover" :class="{'show': productSlot[0]}" v-if="productSlot[0]">
                        <div class="admin-item-btns" @click="currentSlot = 0">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)">
                            <i class="plus icon"></i>
                          </a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)">
                            <i class="edit outline icon"></i>
                          </a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)">
                            <i class="trash alternate outline icon"></i>
                          </a>
                        </div>
                        <div class="product type-a p-3">
                          <h5 class="ui text large bold text-truncate" v-if="productSlot[0]">{{ productSlot[0].titleTH }}</h5>
                          <div class="row">
                            <div class="col-4 pr-0">
                              <p class="ui text big bold [ color red-2 ] m-0" v-if="productSlot[0]">{{ productSlot[0].netPrice }}</p>
                              <p class="ui text big line-through m-0" v-if="productSlot[0]">{{ productSlot[0].price }}</p>
                              <div class>
                                <button class="ui circular red icon button d-inline-block p-2">
                                  <i class="icon-custom-add-cart large d-block"></i>
                                </button>
                                <button class="ui circular default icon button p-1">
                                  <i class="icon-custom-heart tiny"></i>
                                </button>
                              </div>
                            </div>
                            <div class="col-8">
                              <div class="thumb">
                                <img v-if="productSlot[0]" :src="`/storage/products/${productSlot[0]['images'][0]['filename']}`">
                                <img src="/imgs/contents/Group 933.svg" v-else>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="item item-hover" v-else>
                        <div class="admin-item-btns" @click="currentSlot = 0">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)">
                            <i class="plus icon"></i>
                          </a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)">
                            <i class="edit outline icon"></i>
                          </a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)">
                            <i class="trash alternate outline icon"></i>
                          </a>
                        </div>
                        <div class="product type-a p-3">
                          <h5 class="ui text large bold text-truncate">Title</h5>
                          <div class="row">
                            <div class="col-4 pr-0">
                              <p class="ui text big bold [ color red-2 ] m-0">Discount Price</p>
                              <p class="ui text big line-through m-0">Price</p>
                              <div class>
                                <button class="ui circular red icon button d-inline-block p-2">
                                  <i class="icon-custom-add-cart large d-block"></i>
                                </button>
                                <button class="ui circular default icon button p-1">
                                  <i class="icon-custom-heart tiny"></i>
                                </button>
                              </div>
                            </div>
                            <div class="col-8">
                              <div class="thumb">
                                <img src="#">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> <!-- item -->
                    </div>
                  </div>
                </div>
                <div class="col bottom">
                  <div class="row mt-3">
                    <div class="col-6">
                      <div class="item item-hover" :class="{'show': productSlot[1]}" v-if="productSlot[1]">
                        <div class="admin-item-btns" @click="currentSlot = 1">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-b p-3">
                          <h5 class="ui text large bold text-center text-truncate">{{ productSlot[1].titleTH }}</h5>
                          <div class="thumb">
                            <img v-if="productSlot[1].images.length != 0" :src="`/storage/products/${productSlot[1]['images'][0]['filename']}`">
                            <img src="/imgs/contents/Group 933.svg" v-else>
                          </div>
                          <div class="d-flex align-items-center mt-3">
                            <div>
                              <p class="ui text large bold [ color red-2 ] mb-0"></p>
                            </div>
                            <div class="d-flex align-items-center ml-auto">
                              <button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button>
                              <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="item item-hover" v-else>
                        <div class="admin-item-btns" @click="currentSlot = 1">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-b p-3">
                          <h5 class="ui text large bold text-center text-truncate">Title</h5>
                          <div class="thumb">
                            <img src="#">
                          </div>
                          <div class="d-flex align-items-center mt-3">
                            <div>
                              <p class="ui text large bold [ color red-2 ] mb-0">Price</p>
                            </div>
                            <div class="d-flex align-items-center ml-auto">
                              <button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button>
                              <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="item item-hover" :class="{'show': productSlot[2]}" v-if="productSlot[2]">
                        <div class="admin-item-btns" @click="currentSlot = 2">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-b p-3">
                          <h5 class="ui text large bold text-center text-truncate">{{ productSlot[2].titleTH }}</h5>
                          <div class="thumb">
                            <img v-if="productSlot[2].images.length != 0" :src="`/storage/products/${productSlot[2]['images'][0]['filename']}`">
                            <img src="/imgs/contents/Group 933.svg" v-else>
                          </div>
                          <div class="d-flex align-items-center mt-3">
                            <div>
                              <p class="ui text large bold [ color red-2 ] mb-0">{{ productSlot[2].price }}</p>
                            </div>
                            <div class="d-flex align-items-center ml-auto"><button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button> <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button></div>
                          </div>
                        </div>
                      </div>

                      <div class="item item-hover" v-else>
                        <div class="admin-item-btns" @click="currentSlot = 2">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-b p-3">
                          <h5 class="ui text large bold text-center text-truncate">Title</h5>
                          <div class="thumb">
                            <img src="#">
                          </div>
                          <div class="d-flex align-items-center mt-3">
                            <div>
                              <p class="ui text large bold [ color red-2 ] mb-0">Price</p>
                            </div>
                            <div class="d-flex align-items-center ml-auto"><button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button> <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 products-list__column">
              <div class="row">
                <div class="col-12 top">
                  <div class="row mt-3">
                    <div class="col-12">
                      <div class="item item-hover" :class="{'show': productSlot[3]}" v-if="productSlot[3]">
                        <div class="admin-item-btns" @click="currentSlot = 3">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)" data-slot="1"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)" data-slot="1"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)" data-target=".delete-item-modal"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-a p-3">
                          <h5 class="ui text large bold text-truncate">{{ productSlot[3].titleTH }}</h5>
                          <div class="row">
                            <div class="col-4 pr-0">
                              <p class="ui text big bold [ color red-2 ] m-0">{{ productSlot[3].netPrice }}</p>
                              <p class="ui text big line-through m-0">{{ productSlot[3].price }}</p>
                              <div class><button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button> <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button></div>
                            </div>
                            <div class="col-8">
                              <div class="thumb">
                                <img v-if="productSlot[3].images.length != 0" :src="`/storage/products/${productSlot[3]['images'][0]['filename']}`">
                                <img src="/imgs/contents/Group 933.svg" v-else>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="item item-hover" v-else>
                        <div class="admin-item-btns" @click="currentSlot = 3">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)" data-slot="1"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)" data-slot="1"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-a p-3">
                          <h5 class="ui text large bold text-truncate">Title</h5>
                          <div class="row">
                            <div class="col-4 pr-0">
                              <p class="ui text big bold [ color red-2 ] m-0">Discount Price</p>
                              <p class="ui text big line-through m-0">Price</p>
                              <div class><button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button> <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button></div>
                            </div>
                            <div class="col-8">
                              <div class="thumb">
                                <img src="#">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col bottom">
                  <div class="row mt-3">
                    <div class="col-6">
                      <div class="item item-hover" :class="{'show': productSlot[4]}" v-if="productSlot[4]">
                        <div class="admin-item-btns" @click="currentSlot = 4">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)" data-slot="2"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-b p-3">
                          <h5 class="ui text large bold text-center text-truncate">{{ productSlot[4].titleTH }}</h5>
                          <div class="thumb">
                            <img v-if="productSlot[4]['images']" :src="`/storage/products/${productSlot[4]['images'][0]['filename']}`">
                            <img src="/imgs/contents/Group 933.svg" v-else>
                          </div>
                          <div class="d-flex align-items-center mt-3">
                            <div>
                              <p class="ui text large bold [ color red-2 ] mb-0">{{ productSlot[4].price }}</p>
                            </div>
                            <div class="d-flex align-items-center ml-auto"><button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button> <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button></div>
                          </div>
                        </div>
                      </div>

                      <div class="item item-hover" v-else>
                        <div class="admin-item-btns" @click="currentSlot = 4">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)" data-slot="2"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-b p-3">
                          <h5 class="ui text large bold text-center text-truncate">Title</h5>
                          <div class="thumb">
                            <img src="#">
                          </div>
                          <div class="d-flex align-items-center mt-3">
                            <div>
                              <p class="ui text large bold [ color red-2 ] mb-0">Price</p>
                            </div>
                            <div class="d-flex align-items-center ml-auto"><button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button> <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="item item-hover" :class="{'show': productSlot[5]}" v-if="productSlot[5]">
                        <div class="admin-item-btns" @click="currentSlot = 5">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)" data-slot="3"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-b p-3">
                          <h5 class="ui text large bold text-center text-truncate">{{ productSlot[5].titleTH }}</h5>
                          <div class="thumb">
                            <img v-if="productSlot[5].images.length != 0" :src="`/storage/products/${productSlot[5]['images'][0]['filename']}`">
                            <img src="/imgs/contents/Group 933.svg" v-else>
                          </div>
                          <div class="d-flex align-items-center mt-3">
                            <div>
                              <p class="ui text large bold [ color red-2 ] mb-0">{{ productSlot[5].price }}</p>
                            </div>
                            <div class="d-flex align-items-center ml-auto"><button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button> <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button></div>
                          </div>
                        </div>
                      </div>

                      <div class="item item-hover" v-else>
                        <div class="admin-item-btns" @click="currentSlot = 5">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)" data-slot="3"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-b p-3">
                          <h5 class="ui text large bold text-center text-truncate">Title</h5>
                          <div class="thumb">
                            <img src="#">
                          </div>
                          <div class="d-flex align-items-center mt-3">
                            <div>
                              <p class="ui text large bold [ color red-2 ] mb-0">Price</p>
                            </div>
                            <div class="d-flex align-items-center ml-auto"><button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button> <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 products-list__column">
              <div class="row">
                <div class="col-12 top">
                  <div class="row mt-3">
                    <div class="col-12">
                      <div class="item item-hover" :class="{'show': productSlot[6]}" v-if="productSlot[6]">
                        <div class="admin-item-btns" @click="currentSlot = 6">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-a p-3">
                          <h5 class="ui text large bold text-truncate">{{ productSlot[6].titleTH }}</h5>
                          <div class="row">
                            <div class="col-4 pr-0">
                              <p class="ui text big bold [ color red-2 ] m-0">{{ productSlot[6].netPrice }}</p>
                              <p class="ui text big line-through m-0">{{ productSlot[6].price }}</p>
                              <div class><button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button> <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button></div>
                            </div>
                            <div class="col-8">
                              <div class="thumb">
                                <img v-if="productSlot[6].images.length != 0" :src="`/storage/products/${productSlot[6]['images'][0]['filename']}`">
                                <img src="/imgs/contents/Group 933.svg" v-else>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="item item-hover" v-else>
                        <div class="admin-item-btns" @click="currentSlot = 6">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-a p-3">
                          <h5 class="ui text large bold text-truncate">Title</h5>
                          <div class="row">
                            <div class="col-4 pr-0">
                              <p class="ui text big bold [ color red-2 ] m-0">Discount Price</p>
                              <p class="ui text big line-through m-0">Price</p>
                              <div class><button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button> <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button></div>
                            </div>
                            <div class="col-8">
                              <div class="thumb">
                                <img src="#">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col bottom">
                  <div class="row mt-3">
                    <div class="col-6">
                      <div class="item item-hover" :class="{'show': productSlot[7]}" v-if="productSlot[7]">
                        <div class="admin-item-btns" @click="currentSlot = 7">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-b p-3">
                          <h5 class="ui text large bold text-center text-truncate">{{ productSlot[7].titleTH }}</h5>
                          <div class="thumb">
                            <img v-if="productSlot[7].images.length != 0" :src="`/storage/products/${productSlot[7]['images'][0]['filename']}`">
                            <img src="/imgs/contents/Group 933.svg" v-else>
                          </div>
                          <div class="d-flex align-items-center mt-3">
                            <div>
                              <p class="ui text large bold [ color red-2 ] mb-0">{{ productSlot[7].price }}</p>
                            </div>
                            <div class="d-flex align-items-center ml-auto"><button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button> <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button></div>
                          </div>
                        </div>
                      </div>

                      <div class="item item-hover" v-else>
                        <div class="admin-item-btns" @click="currentSlot = 7">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-b p-3">
                          <h5 class="ui text large bold text-center text-truncate">Title</h5>
                          <div class="thumb">
                            <img src="#">
                          </div>
                          <div class="d-flex align-items-center mt-3">
                            <div>
                              <p class="ui text large bold [ color red-2 ] mb-0">Price</p>
                            </div>
                            <div class="d-flex align-items-center ml-auto"><button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button> <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="item item-hover" :class="{'show': productSlot[8]}" v-if="productSlot[8]">
                        <div class="admin-item-btns" @click="currentSlot = 8">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-b p-3">
                          <h5 class="ui text large bold text-center text-truncate">{{ productSlot[8].titleTH }}</h5>
                          <div class="thumb">
                            <img v-if="productSlot[8].images.length != 0" :src="`/storage/products/${productSlot[8]['images'][0]['filename']}`">
                          </div>
                          <div class="d-flex align-items-center mt-3">
                            <div>
                              <p class="ui text large bold [ color red-2 ] mb-0">{{ productSlot[8].price }}</p>
                            </div>
                            <div class="d-flex align-items-center ml-auto"><button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button> <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button></div>
                          </div>
                        </div>
                      </div>

                      <div class="item item-hover" v-else>
                        <div class="admin-item-btns" @click="currentSlot = 8">
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="plus icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn" @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"><i class="edit outline icon"></i></a>
                          <a class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn" @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"><i class="trash alternate outline icon"></i></a>
                        </div>
                        <div class="product type-b p-3">
                          <h5 class="ui text large bold text-center text-truncate">Title</h5>
                          <div class="thumb">
                            <img src="#">
                          </div>
                          <div class="d-flex align-items-center mt-3">
                            <div>
                              <p class="ui text large bold [ color red-2 ] mb-0">Price</p>
                            </div>
                            <div class="d-flex align-items-center ml-auto"><button class="ui circular red icon button d-inline-block p-2"><i class="icon-custom-add-cart large d-block"></i></button> <button class="ui circular default icon button p-1"><i class="icon-custom-heart tiny"></i></button></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> <!-- row -->
            </div> <!-- product-list-column -->
          </div>
        </div>
      </div>
    </div>
    <select-product-slot-modal  :categories="categories" :currentSlot="currentSlot" :id="`selectProductSlot-${errors.vmId}`" @setProductToSlot="setProductToSlot"></select-product-slot-modal>
    <delete-product-modal :currentSlot="currentSlot" :id="`deleteProduct-${errors.vmId}`" @deleteProductFromSlot="deleteProductFromSlot"></delete-product-modal>
  </section>
</template>

<script>
  import SelectProductSlotModal from './SelectProductSlotModal'
  import DeleteProductModal from './DeleteProductModal'
  import { isNullOrUndefined } from 'util'
  export default {
    name: 'product-list1',
    components: {
      SelectProductSlotModal,
      DeleteProductModal
    },
    props: {
      id: {
        type: Number,
        default: 0
      },
      theme: {
        type: Object,
        default: () => {}
      },
      categories: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        productSlot: new Array(9),
        productSlotIds: new Array(9),
        // categories:[],
        currentSlot: 0
      }
    },
    computed: {},
    created() {
      this.getTheme()
      // this.fetchCategoryList()
    },
    methods: {
      fetchCategoryList() {
        axios
        .get('/api/admin/categories')
        .then(result => {
          this.categories = result.data.data
          this.showAddProduct = true
          })
      },
      getTheme () {
        axios.get(`/api/admin/themes/${this.id}`).then(res => {
          if (res.data.products != undefined) {
            this.productSlot = res.data.products
            this.productSlotIds = this.productSlot.map(p => isNullOrUndefined(p) ? null : p.id)
          }
        })
      },
      setProductToSlot (product, slot) {
        this.productSlot[slot] = product
        this.productSlotIds[slot] = product
        axios.post(`/api/admin/themes/${this.id}`, {data: this.productSlotIds, _method: 'PATCH'}).then(res => {
          this.$notify.success('เพิ่มสินค้าในเนื้อหาสำเร็จ')
          this.getTheme()
          $(`#selectProductSlot-${this.errors.vmId}`).modal('hide')
        })
        this.showSelectProductDialog = false
      },
      deleteProductFromSlot () {
        this.productSlot[this.currentSlot] = null
        this.productSlotIds[this.currentSlot] = null
        axios.post(`/api/admin/themes/${this.id}`, {data: this.productSlotIds, _method: 'PATCH'}).then(res => {
          this.$notify.success('ลบสินค้าในเนื้อหาสำเร็จ')
          this.getTheme()
          $(`#deleteProduct-${this.errors.vmId}`).modal('hide')
        })
      },
      handleDeleteComponentModal () {
        this.$emit('onDelete', this.id)
        this.$parent.openModal('#deleteSectionModal')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .text-truncate {
    padding-top: 0.25rem;
  }
</style>
