<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="page-header">
                <h1>โปรโมชั่น</h1>
                </div>
                <div class="custom-card mt-3">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6">
                                <form class="input-group" method="get">
                                <input type="text" class="form-control" placeholder="ค้นหาโปรโมชั่น" v-model="search" />
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="submit" @click.prevent="searchPromo">
                                    <i class="fas fa-search"></i>
                                    </button>
                                    <a class="btn btn-danger" href="#" @click="fetchPromoList"><i class="fas fa-redo mr-1"></i>ล้างค่า</a>
                                </div>
                                </form>
                            </div>
                            <div class="col ml-auto">
                                <router-link :to="{ name: 'promotion.create' }" class="btn btn-gradient-success float-right" data-toggle="modal" data-target="#">
                                <i class="fas fa-plus mr-1"></i>เพิ่มโปรโมชั่น
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                        <table class="table table-center table-bordered">
                            <thead>
                            <tr class="bg-light text-center">
                                <th>ชื่อโปรโมชั่น</th>
                                <th>รายการสินค้า</th>
                                <th>สถานะการใช้งาน</th>
                                <th>เวลาที่เริ่ม-สิ้นสุดโปรโมชั่น</th>
                                <th>จัดการ</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="user in laravelData.data" :key="user.id" class="text-center">
                                <td class="text-center">
                                <input type="checkbox" v-model.number="selectedUser" :value="user.id" class="select" />
                                </td>
                                <td>
                                <span class="avatar avatar-md" :style="`background-image: url(/storage/users/avatar/${user.avatar})`" v-if="user.avatar"></span>
                                <span class="avatar avatar-md" v-else>A</span>
                                </td>
                                <td>{{ user.firstname }} {{ user.lastname }}</td>
                                <td>{{ user.email }}</td>
                                <td>
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" 
                                    class="custom-control-input" 
                                    :id="'customSwitch'+user.id" 
                                    v-model="user.enabled" 
                                    @change="toggleEnableUser(user.id)">
                                    <label class="custom-control-label" :for="'customSwitch'+user.id" ></label>
                                </div>
                                </td>
                                <td>{{ user.phone }}</td>
                                <td class="text-center">
                                <div class="dropdown">
                                    <a href="#" class="link-unstyled border-0" data-toggle="dropdown">
                                    <!-- <i class="fas fa-ellipsis-v"></i> -->
                                    <span class="avatar avatar-sm"><i class="fas fa-ellipsis-h"></i></span>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                    <router-link :to="{name: 'users.show',params: { id: user.id }}" class="dropdown-item text-primary">
                                        <i class="far fa-eye mr-1"></i>ดู
                                    </router-link>
                                    <a href="#" class="dropdown-item text-danger" @click.prevent="deleteUser(user.id)"><i class="far fa-trash-alt mr-1"></i>ลบ</a>
                                    </div>
                                </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <pagination :data="laravelData" @pagination-change-page="getResults" class="justify-content-end"></pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'promotion',
    components: {},
    props: {},
    data () {
        return {
            search: '',
            laravelData: {},
            selectePromo: [],
            role: 0
        }
    },
    computed: {},
    created () {},
    methods: {
        fetchPromoList() {
            this.search = ''
            axios
            .get('/api/admin/promotion')
            .then(result => (this.laravelData = result.data))
        },
    }
}
</script>

<style lang="scss" scoped>

</style>