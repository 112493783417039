var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row mb-5" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("el-card", { staticClass: "custom-card mt-3" }, [
            _c(
              "div",
              {
                staticClass: "text-primary font-weight-bold",
                attrs: { slot: "header" },
                slot: "header"
              },
              [_vm._v("จัดการโปรโมชั่น")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "name" } }, [
                    _c("b", [_vm._v("ชื่อโปรโมชั่น")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "required" })
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.promo.name,
                        expression: "promo.name"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "name" },
                    domProps: { value: _vm.promo.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.promo, "name", $event.target.value)
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "form-group row " }, [
                  _c("div", { staticClass: "col-12 pl-0" }, [
                    _c("label", [
                      _c("b", [_vm._v("กำหนดเวลาที่เริ่ม-สิ้นสุดโปรโมชั่น")])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "custom-control custom-switch float-right"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.promo.selectDate,
                              expression: "promo.selectDate"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: { type: "checkbox", id: "selectDate" },
                          domProps: {
                            checked: Array.isArray(_vm.promo.selectDate)
                              ? _vm._i(_vm.promo.selectDate, null) > -1
                              : _vm.promo.selectDate
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.promo.selectDate,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.promo,
                                      "selectDate",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.promo,
                                      "selectDate",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.promo, "selectDate", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("label", {
                          staticClass: "custom-control-label",
                          attrs: { for: "selectDate" }
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-12 form-inline p-0 " }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.promo.startDate,
                          expression: "promo.startDate"
                        }
                      ],
                      staticClass: "form-control ",
                      attrs: {
                        disabled: !_vm.promo.selectDate,
                        type: "datetime-local"
                      },
                      domProps: { value: _vm.promo.startDate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.promo, "startDate", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "pr-1 pl-1 text-center" }, [
                      _vm._v("ถึง")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.promo.expiredDate,
                          expression: "promo.expiredDate"
                        }
                      ],
                      staticClass: "form-control ",
                      attrs: {
                        disabled: !_vm.promo.selectDate,
                        type: "datetime-local"
                      },
                      domProps: { value: _vm.promo.expiredDate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.promo,
                            "expiredDate",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("label", { staticClass: "form-check-label pr-5" }, [
                      _c("b", [_vm._v("เงื่อนไขโปรโมชั่น")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.promo.type,
                          expression: "promo.type"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "inlineRadioOptions",
                        id: "inlineRadio1",
                        value: "buy1free1"
                      },
                      domProps: {
                        checked: _vm._q(_vm.promo.type, "buy1free1")
                      },
                      on: {
                        change: function($event) {
                          _vm.$set(_vm.promo, "type", "buy1free1")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "inlineRadio1" }
                      },
                      [_vm._v("ซื้อ 1 แถม 1")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.promo.type,
                          expression: "promo.type"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "inlineRadioOptions",
                        id: "inlineRadio2",
                        value: "discount"
                      },
                      domProps: { checked: _vm._q(_vm.promo.type, "discount") },
                      on: {
                        change: function($event) {
                          _vm.$set(_vm.promo, "type", "discount")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "inlineRadio2" }
                      },
                      [_vm._v("ลดราคา")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.promo.type,
                          expression: "promo.type"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "inlineRadioOptions",
                        id: "inlineRadio3",
                        value: "deliveryfree"
                      },
                      domProps: {
                        checked: _vm._q(_vm.promo.type, "deliveryfree")
                      },
                      on: {
                        change: function($event) {
                          _vm.$set(_vm.promo, "type", "deliveryfree")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "inlineRadio3" }
                      },
                      [_vm._v("จัดส่งฟรี")]
                    )
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "custom-card mt-3 p-4" }, [
            _c(
              "div",
              {
                staticClass: "text-primary font-weight-bold",
                attrs: { slot: "header" },
                slot: "header"
              },
              [_vm._v("รายการสินค้าในโปรโมชั่น")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c(
                  "form",
                  { staticClass: "input-group", attrs: { method: "get" } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search,
                          expression: "search"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "ค้นหาสินค้า" },
                      domProps: { value: _vm.search },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.search = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.searchPromo($event)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-search" })]
                      )
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 mt-4" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body w-100" }, [
                    _c(
                      "div",
                      {
                        staticClass: "text-primary font-weight-bold",
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [_vm._v("ตั่งค่าทั้งชุด")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-12 form-inline" }, [
                        _c(
                          "label",
                          { staticClass: "pl-3 pr-3", attrs: { for: "" } },
                          [_vm._v("ตั้งส่วนลดอยู่ที่")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control ",
                          staticStyle: { width: "100px" },
                          attrs: {
                            type: "number",
                            id: "inputDiscount",
                            name: "inputDiscount",
                            placeholder: "บาท"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "p-3" }, [_vm._v("หรือ")]),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          staticStyle: { width: "100px" },
                          attrs: {
                            type: "number",
                            max: "100",
                            min: "0",
                            id: "inputDiscountPercent",
                            name: "inputDiscountPercent",
                            placeholder: "%"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { staticClass: "pl-3 pr-3", attrs: { for: "" } },
                          [_vm._v("จำกัดยอดซื้อ (ผู้ใช้งาน)")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control mr-4",
                          staticStyle: { width: "100px" },
                          attrs: {
                            type: "number",
                            id: "limitbuy",
                            name: "limitbuy"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mr-4",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.updateSet($event)
                              }
                            }
                          },
                          [_vm._v("อัพเดทที่เลือก")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.updateSet($event)
                              }
                            }
                          },
                          [_vm._v("อัพเดททั้งหมด")]
                        )
                      ])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 mt-4" }, [
                _c("div", { staticClass: "table-responsive" }, [
                  _c(
                    "table",
                    { staticClass: "table table-center table-bordered" },
                    [
                      _c("thead", [
                        _c("tr", { staticClass: "bg-light text-center" }, [
                          _c("th", { attrs: { rowspan: "2" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectAll,
                                  expression: "selectAll"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.selectAll)
                                  ? _vm._i(_vm.selectAll, null) > -1
                                  : _vm.selectAll
                              },
                              on: {
                                click: _vm.selectAllProducts,
                                change: function($event) {
                                  var $$a = _vm.selectAll,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selectAll = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selectAll = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selectAll = $$c
                                  }
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { rowspan: "2" } }, [
                            _vm._v("รูปสินค้า")
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { rowspan: "2" } }, [
                            _vm._v("ชื่อสินค้า")
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { rowspan: "2" } }, [
                            _vm._v("ราคาเดิม (บาท)")
                          ]),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass: "border-bottom-0",
                              attrs: { colspan: "2" }
                            },
                            [_vm._v("ส่วนลด")]
                          ),
                          _vm._v(" "),
                          _c("th", { attrs: { rowspan: "2" } }, [
                            _vm._v("ราคาขาย (บาท)")
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { rowspan: "2" } }, [
                            _vm._v("จำกัดยอดซื้อ")
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { rowspan: "2" } }, [
                            _vm._v("จัดการ")
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._m(3)
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.promo.showProducts, function(
                          product,
                          index
                        ) {
                          return _c(
                            "tr",
                            { key: index, staticClass: "text-center" },
                            [
                              _c("td", { staticClass: "text-center" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedProduct,
                                      expression: "selectedProduct"
                                    }
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    value: product.id,
                                    checked: Array.isArray(_vm.selectedProduct)
                                      ? _vm._i(
                                          _vm.selectedProduct,
                                          product.id
                                        ) > -1
                                      : _vm.selectedProduct
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.selectedProduct,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = product.id,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.selectedProduct = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.selectedProduct = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.selectedProduct = $$c
                                      }
                                    }
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                product.images.length > 0
                                  ? _c("span", {
                                      staticClass: "avatar avatar-md",
                                      style:
                                        "background-image: url(" +
                                        product.images[0].url +
                                        ")"
                                    })
                                  : _c(
                                      "span",
                                      { staticClass: "avatar avatar-md" },
                                      [_vm._v("P")]
                                    )
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(product.titleTH))]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-success" }, [
                                _vm._v(
                                  _vm._s(product.netPrice.toLocaleString())
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("s", { staticClass: "text-dark" }, [
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(
                                        product.promotion.discountPrice.toLocaleString()
                                      )
                                    )
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(product.promotion.discountPercent) +
                                    "%"
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    product.promotion.netPrice.toLocaleString()
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(product.promotion.limit))
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-center" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "delete-product pointer",
                                    on: {
                                      click: function($event) {
                                        _vm.deleteProduct([product.id])
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-trash-alt fa-lg mr-1"
                                    })
                                  ]
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _vm.promo.products.length > 1
                    ? _c("Pagination", {
                        attrs: {
                          currentPage: _vm.currentPage,
                          lastPage: _vm.lastPage
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade bd-example-modal-lg",
        attrs: {
          id: "exampleModalCenter",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "exampleModalCenterTitle",
          "aria-hidden": "true"
        }
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(4),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body pb-0" }, [
              _c("div", { staticClass: "row justify-content-center" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm._m(5),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab-content",
                      attrs: { id: "myTabContent" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "tab-pane fade pt-4",
                          attrs: {
                            id: "home",
                            role: "tabpanel",
                            "aria-labelledby": "home-tab"
                          }
                        },
                        [
                          _c("el-tree", {
                            ref: "category-list",
                            staticClass: "border p-1",
                            attrs: {
                              data: _vm.formatCategories,
                              "show-checkbox": "",
                              "node-key": "id",
                              props: _vm.defaultProps
                            },
                            on: { "check-change": _vm.handleCheckChange }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "tab-pane fade show active pt-4",
                          attrs: {
                            id: "profile",
                            role: "tabpanel",
                            "aria-labelledby": "profile-tab"
                          }
                        },
                        [
                          _c("div", { staticClass: "form-group input-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.inputModal,
                                  expression: "inputModal"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "ค้นหาสินค้า"
                              },
                              domProps: { value: _vm.inputModal },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.inputModal = $event.target.value
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "input-group-append" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: { click: _vm.searchModal }
                                },
                                [_c("i", { staticClass: "fas fa-search" })]
                              )
                            ])
                          ])
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 form-inline mt-2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checkAll,
                        expression: "checkAll"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { value: "true", id: "selectall", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.checkAll)
                        ? _vm._i(_vm.checkAll, "true") > -1
                        : _vm.checkAll
                    },
                    on: {
                      click: _vm.selectProducts,
                      change: function($event) {
                        var $$a = _vm.checkAll,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "true",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.checkAll = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.checkAll = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.checkAll = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "ml-2", attrs: { for: "selectall" } },
                    [_vm._v("เลือกทั้งหมด")]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body border-top" }, [
              _c(
                "div",
                {
                  staticClass: "row modal-item",
                  class: { loading: _vm.loading }
                },
                _vm._l(_vm.products, function(product) {
                  return _c(
                    "div",
                    {
                      key: product.id,
                      staticClass: "col-md-2 col-sm-4 text-center"
                    },
                    [
                      _c("label", { attrs: { for: "check" + product.id } }, [
                        _c(
                          "div",
                          {
                            staticClass: "box-img",
                            class: { active: _vm.isActive(product.id) }
                          },
                          [
                            product.imageUrl != null
                              ? _c("img", {
                                  staticClass: "img-fluid",
                                  attrs: { src: product.imageUrl }
                                })
                              : _c("img", {
                                  staticClass: "img-fluid",
                                  attrs: { src: "/imgs/product.png" }
                                }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectModal,
                                  expression: "selectModal"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "check" + product.id
                              },
                              domProps: {
                                value: product,
                                checked: Array.isArray(_vm.selectModal)
                                  ? _vm._i(_vm.selectModal, product) > -1
                                  : _vm.selectModal
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.selectModal,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = product,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selectModal = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selectModal = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selectModal = $$c
                                  }
                                }
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-center mb-2 box-title" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(product.titleTH) +
                            "\n                        "
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", "data-dismiss": "modal" }
                },
                [_vm._v("ยกเลิก")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button", "data-dismiss": "modal" },
                  on: { click: _vm.addProductPromo }
                },
                [_vm._v("ยืนยัน")]
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h1", [_vm._v("โปรโมชั่น")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "alert alert-warning alert-dismissible fade show",
        attrs: { role: "alert" }
      },
      [
        _vm._v("\n                หากกำหนดโปรโมชั่นเสร็จแล้ว ถ้าอยู่ในสถานะ "),
        _c("b", [_vm._v("กำลังใช้งาน")]),
        _vm._v(
          " จะไม่สามารถแก้ไขโปรโมชั่นได้อีก หรือถ้ายกเลิกโปรโมชั่นสามารถทำได้ กรุณาตรวจสอบให้ถี่ถ้วนก่อนทำการบันทึกข้อมูล\n                "
        ),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "alert",
              "aria-label": "Close"
            }
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col ml-auto" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-gradient-success float-right",
          attrs: {
            href: "#",
            "data-toggle": "modal",
            "data-target": "#exampleModalCenter"
          }
        },
        [
          _c("i", { staticClass: "fas fa-plus mr-1" }),
          _vm._v("เพิ่มสินค้า\n                        ")
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "bg-light text-center" }, [
      _c("th", [_vm._v("(บาท)")]),
      _vm._v(" "),
      _c("th", [_vm._v("(%)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "exampleModalLongTitle" } },
        [_vm._v("เลือกสินค้าเพื่อจัดโปรโมชั่น")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      { staticClass: "nav nav-tabs", attrs: { id: "myTab", role: "tablist" } },
      [
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link  active",
              attrs: {
                id: "profile-tab",
                "data-toggle": "tab",
                href: "#profile",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false"
              }
            },
            [_vm._v("ค้นหาสินค้า")]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link ",
              attrs: {
                id: "home-tab",
                "data-toggle": "tab",
                href: "#home",
                role: "tab",
                "aria-controls": "home",
                "aria-selected": "true"
              }
            },
            [_vm._v("เลือกหมวดหมู่สินค้า")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }