var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.base64
    ? _c("iframe", {
        attrs: {
          width: "100%",
          height: "100%",
          type: "application/pdf",
          src: _vm.base64
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }