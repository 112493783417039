<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header">
          <h1>ผู้ดูแล</h1>
        </div>
        <div class="custom-card mt-3">
          <div class="card-header">
            <div class="row">
              <div class="col-6">
                <form class="input-group" method="get">
                  <input type="text" class="form-control" placeholder="ค้นหาผู้ดูแล" v-model="search" />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit" @click.prevent="searchAdmin">
                      <i class="fas fa-search"></i>
                    </button>
                    <a class="btn btn-danger" href="#" @click="fetchAdminList"><i class="fas fa-redo mr-1"></i>ล้างค่า</a>
                  </div>
                </form>
              </div>
              <div class="col ml-auto">
                <router-link :to="{ name: 'admins.create' }" class="btn btn-gradient-success float-right" data-toggle="modal" data-target="#">
                  <i class="fas fa-plus mr-1"></i>เพิ่มผู้ดูแล
                </router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-center table-bordered">
                <thead>
                  <tr class="bg-light">
                    <th style="border-right-color: transparent; vertical-align:middle;" class="text-center">
                      <input type="checkbox" v-model="selectAll" />
                    </th>
                    <th colspan="6" class="text-left">
                      <div class="d-flex">
                        <div>
                          <a href="#" class="btn btn-outline-danger btn-sm mr-3" @click.prevent="deleteManyAdmin">
                            <i class="far fa-trash-alt mr-1"></i>ลบ
                          </a>
                        </div>
                        <div>
                          <select v-model="role" class="custom-select custom-select-sm" @change="changeManyAdminRole">
                            <option selected disabled value="0">กรุณาเลือก</option>
                            <option value="ROOT">ผู้ดูแลระบบ</option>
                            <option value="ADMIN">ผู้ดูแล</option>
                          </select>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr class="bg-light text-center">
                    <th></th>
                    <th>รูป</th>
                    <th>ชื่อผู้ใช้งาน</th>
                    <th>อีเมล์</th>
                    <th>ตำแหน่ง</th>
                    <th>จัดการ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="admin in laravelData.data" :key="admin.id" class="text-center">
                    <td class="text-center">
                      <input type="checkbox" v-model.number="selectedAdmin" :value="admin.id" />
                    </td>
                    <td>
                      <span class="avatar avatar-md" :style="`background-image: url(/storage/avatars/admin/${admin.avatar})`" v-if="admin.avatar"></span>
                      <span class="avatar avatar-md" v-else>A</span>
                    </td>
                    <td>{{ admin.username }}</td>
                    <td>{{ admin.email }}</td>
                    <td>
                      <div class="dropdown">
                        <a href="#" class="btn btn-sm dropdown-toggle px-3" data-toggle="dropdown" :class="{'btn-orange': admin.role == 'ADMIN', 'btn-primary': admin.role == 'ROOT'}">
                          {{ admin.role == 'ADMIN' ? 'ผู้ดูแล' : 'ผู้ดูแลระบบ' }}
                        </a>
                        <div class="dropdown-menu" style="min-width: 8rem;">
                          <a href="#" class="dropdown-item" @click="changeAdminRole(admin.id, 'ADMIN')">ผู้ดูแล</a>
                          <a href="#" class="dropdown-item" @click="changeAdminRole(admin.id, 'ROOT')">ผู้ดูแลระบบ</a>
                        </div>
                      </div>
                    </td>
                    <td class="text-center">
                      <div class="dropdown">
                        <a href="#" class="link-unstyled border-0" data-toggle="dropdown">
                          <!-- <i class="fas fa-ellipsis-v"></i> -->
                          <span class="avatar avatar-sm"><i class="fas fa-ellipsis-h"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <router-link :to="{
                              name: 'admins.edit',
                              params: { id: admin.id }
                            }" class="dropdown-item text-primary">
                            <i class="far fa-edit mr-1"></i>แก้ไข
                          </router-link>
                          <a href="#" class="dropdown-item text-danger" @click.prevent="deleteAdmin(admin.id)"><i class="far fa-trash-alt mr-1"></i>ลบ</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right">
              <pagination :data="laravelData" @pagination-change-page="fetchAdminList" class="justify-content-end"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'admin',
    components: {},
    props: {},
    data() {
      return {
        search: '',
        laravelData: {},
        selectedAdmin: [],
        role: 0
      }
    },
    computed: {
      selectAll: {
        get: function() {
          return this.laravelData.data
            ? this.selectedAdmin.length == this.laravelData.data.length
            : false
        },
        set: function(value) {
          var selected = []

          if (value) {
            this.laravelData.data.forEach(function(user) {
              selected.push(user.id)
            })
          }
          return (this.selectedAdmin = selected)
        }
      }
    },
    created() {
      this.fetchAdminList()
    },
    methods: {
      fetchAdminList() {
        this.search = ''
        axios
          .get('/api/admin/admins')
          .then(result => (this.laravelData = result.data))
      },
      searchAdmin() {
        axios
          .get(`/api/admin/admins?search=${this.search}`)
          .then(res => (this.laravelData = res.data))
      },
      changeAdminRole(adminId, role) {
        axios
          .post('/api/admin/admins/role', { admins: [adminId], role: role })
          .then(res => this.fetchAdminList())
      },
      changeManyAdminRole() {
        axios
          .post('/api/admin/admins/role', {
            admins: this.selectedAdmin,
            role: this.role
          })
          .then(res => this.fetchAdminList())
      },
      deleteAdmin(adminId) {
        axios
          .delete(`/api/admin/admins/${adminId}`)
          .then(res => this.fetchAdminList())
      },
      deleteManyAdmin() {
        let form = new FormData()
        form.append('_method', 'DELETE')
        this.selectedAdmin.forEach(admin => form.append('admins[]', admin))
        axios.post('/api/admin/admins', form).then(res => this.fetchAdminList())
      }
    }
  }
</script>

<style lang="scss" scoped></style>
