<template>
  <div class="card shadow-sm border-0">
    <a href="#" class="text-muted ml-auto p-3" @click="$parent.clicked = !$parent.clicked">
      <i class="fa fa-times"></i>
    </a>
    <div class="card-body">
      <form class="container">
        <div class="row">
          <div class="col-12">
            <div class="page-title my-3"></div>
          </div>
          <div class="col-md-8 col-sm-12">
            <div class="card h-100">
              <div class="card-header">
                <span class="card-title">ชื่อหมวดหมู่</span>
              </div>
              <div class="card-body rounded-0">
                <div class="form-group">
                  <label for="">ชื่อหมวดหมู่<span class="required"></span></label>
                  <input type="text" class="form-control" name="name" @input="handleInput" :value="category.name" />
                </div>
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="category.enabled" @change="handleInput">
                  <label class="custom-control-label" for="customSwitch1">เปิดใช้งาน</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-header">
                <span class="card-title">โลโก้</span>
              </div>
              <div class="card-body text-center">
                <image-upload @finishedUpload="handleUploadImageResult" :image="image"></image-upload>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <div class="card">
              <div class="card-header">
                <span class="card-title"> หมวดหมู่ย่อย </span>
                <button type="button" class="float-right btn btn-primary btn-sm" @click="addSubCategoryIndex">
                  <i class="fa fa-plus mr-1"></i>เพิ่มหมวดหมู่ย่อย
                </button>
              </div>
              <!-- <transition-group class="card-body" tag="div" name="subCategoryList"> -->
              <div class="card-body">
                <div class="form-group row" v-for="(subCategory, index) in category.childrens" :key="subCategory.id">
                  <div class="col-sm-2 align-self-center">ชื่อหมวดหมู่</div>
                  <div class="col-sm-6">
                    <input type="text" class="form-control" name="name" @input="handleChildInput($event, index)" :value="subCategory.name" />
                  </div>
                  <div class="col-sm-2 align-self-center">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" name="enabled" @change="handleChildInput($event, index)" :checked="subCategory.enabled" />
                      <label class="form-check-label">เปิดใช้งาน</label>
                    </div>
                  </div>
                  <div class="col-sm-2 align-self-end">
                    <a href="#" class="link-unstyled text-danger" @click="removeSubCategoryIndex(index)">
                      <i class="fas fa-times-circle fa-2x"></i>
                    </a>
                  </div>
                </div>
              </div>
              <!-- </transition-group> -->
              <div class="card-footer text-center bg-transparent border-0">
                <button class="btn btn-gradient-primary btn-lg" type="submit" @click.prevent="submitCategoryForm">
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import ImageUpload from '@/components/ImageUpload'

  export default {
    name: 'categoryForm',
    components: { ImageUpload },
    props: {
      category: {
        type: Object,
        default: () => ({
          name: '',
          enabled: true,
          childrens: [{ name: '', enabled: true }],
          logoFilename: undefined
        })
      },
      errors: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        categoryImage: {}
      }
    },
    computed: {
      image() {
        if( this.category.logoFilename != undefined){
         return `/storage/categories/${this.category.logoFilename}`
        }
        return null
      }
    },
    created() {},
    methods: {
      handleUploadImageResult(image) {
        this.categoryImage = image
      },
      handleInput(event) {
        let target = event.target
        let value = target.type === 'checkbox' ? target.checked : target.value
        let name = target.name
        this.category[name] = value
      },
      handleChildInput(event, index) {
        let target = event.target
        let value = target.type === 'checkbox' ? target.checked : target.value
        let name = target.name
        this.category.childrens[index][name] = value
      },
      addSubCategoryIndex() {
        this.category.childrens.push({ name: '', enabled: true })
      },
      removeSubCategoryIndex(index) {
        this.category.childrens.splice(index, 1)
      },
      submitCategoryForm() {
        this.category.image = this.categoryImage
        this.$emit('onFormSubmit', this.category)
      }
    }
  }
</script>

<style lang="scss" scoped></style>
