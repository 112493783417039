var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: { role: "dialog", tabindex: "-1", id: _vm.id }
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("a", { staticClass: "ui button blue text" }, [
              _c(
                "span",
                {
                  staticClass: "ui text large",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.handleDeleteProduct($event)
                    }
                  }
                },
                [_vm._v("บันทึก")]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "p",
        { staticClass: "modal-title ui text big color black text-center" },
        [_vm._v("สินค้า")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("div", { staticClass: "text-center" }, [
        _c("i", {
          staticClass: "far fa-trash-alt color red-1 mb-3",
          staticStyle: { "font-size": "12rem" }
        }),
        _vm._v(" "),
        _c("p", { staticClass: "ui text large color black text-center" }, [
          _vm._v("คุณต้องการลบสินค้าหรือไม่")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "ui button red basic text",
        attrs: { "data-dismiss": "modal" }
      },
      [_c("span", { staticClass: "ui text large" }, [_vm._v("ยกเลิก")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }