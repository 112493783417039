<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header">
          <h1>ตั้งค่าที่อยู่ในการจัดส่ง</h1>
        </div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="card-title">ที่อยู่ในการจัดส่ง</h4>
          </div>
          <div class="card-body">
            <div class="form-group row">
              <div class="col-sm-4">
                <label for="">ชื่อ</label>
                <input type="text" class="form-control form-control" v-model="name" />
              </div>
              <div class="col-sm-4">
                <label for="">อีเมล์</label>
                <input type="text" class="form-control form-control" v-model="email" />
              </div>

              <div class="col-sm-4">
                <label for="">เบอร์โทรศัพท์</label>
                <input type="text" class="form-control form-control" v-model="phone" />
              </div>
            </div>
            <div class="form-group">
              <label for="">ที่อยู่</label>
              <input type="text" class="form-control" v-model="address">
            </div>
            <div class="form-group row">
              <div class="col-sm-3">
                <label for="">จังหวัด</label>
                <select name="provinceId" class="custom-select" v-model="provinceId" @change="getAmphur">
                  <option value="0" disabled selected>กรุณาเลือก</option>
                  <option :value="province.id" v-for="province in provinces" :key="province.id">{{ province.name_in_thai }}</option>
                </select>
              </div>

              <div class="col-sm-3">
                <label for="">เขต / อำเภอ</label>
                <select name="provinceId" class="custom-select" v-model="amphurId" @change="getDistrict">
                  <option value="0" disabled selected>กรุณาเลือก</option>
                  <option :value="amphur.id" v-for="amphur in amphurs" :key="amphur.id">{{ amphur.name_in_thai }}</option>
                </select>
              </div>
              <div class="col-sm-3">
                <label for="">แขวง / ตำบล</label>
                <select name="provinceId" class="custom-select" v-model="districtId">
                  <option value="0" disabled selected>กรุณาเลือก</option>
                  <option
                    :value="_district.id"
                    v-for="_district in districts"
                    :key="_district.id">
                      {{ _district.name_in_thai }}
                  </option>
                </select>
              </div>

              <div class="col-sm-3">
                <label for="">รหัสไปรษณีย์</label>
                <input type="text" class="form-control" :value="zipcode" @input="zipcode = $event.target.value">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 text-center">
          <a href="#" class="btn btn btn-gradient-primary btn-lg" role="button" @click.prevent="updateContact">บันทึก</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'shopAddress',
    components: {},
    props: {},
    data() {
      return {
        name: '',
        email: '',
        phone: '',
        address: '',
        provinceId: 0,
        amphurId: 0,
        districtId: 0,
        district: {},
        zipcode: '',
        provinces: [],
        amphurs: [],
        districts: [],
      }
    },
    computed: {},
    created() {
      this.fetchAppData()
      this.getProvince()
    },
    methods: {
      getProvince() {
        axios.get('/api/addresses').then(res => this.provinces = res.data)
      },
      getAmphur() {
        axios.get(`/api/addresses/amphur/${this.provinceId}`).then(res => this.amphurs = res.data)
      },
      getDistrict() {
        axios.get(`/api/addresses/district/${this.amphurId}`).then(res => this.districts = res.data)
      },
      fetchAppData() {
        axios.get('/api/admin/applications').then(res => {
          let address = res.data.data.contact
          this.name       = (this.name == null) ? '' : address.name
          this.email      = (this.email == null) ? '' : address.email
          this.phone      = (this.phone == null) ? '' : address.phone
          this.address    = (this.address == null) ? '' : address.address
          this.provinceId = (this.provinceId == null) ? '' : address.provinceId
          this.amphurId   = (this.amphurId == null) ? '' : address.amphurId
          this.districtId = (this.districtId == null) ? '' : address.districtId
          this.zipcode    = (this.zipcode == null) ? '' : address.zipcode

          this.getAmphur(address.provinceId)
          this.getDistrict(address.amphurId)
        })
      },
      updateContact() {
        let form = new FormData()
        if(this.email == null){
            this.email = ''
          }
        form.append('name', this.name)
        form.append('email', this.email)
        form.append('phone', this.phone)
        form.append('address', this.address)
        form.append('provinceId', this.provinceId)
        form.append('amphurId', this.amphurId)
        form.append('districtId', this.districtId)
        form.append('zipcode', this.zipcode)
        form.append('_method', 'PATCH')

        axios.post('/api/admin/applications', form).then(() => {
          this.fetchAppData()
          this.$notify.success({
            title: 'Success',
            message: 'แก้ไขสำเร็จ'
          })
        })
      }
    }
  }
</script>

<style lang="scss" scoped></style>
