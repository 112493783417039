var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mb-5" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 mb-1" }, [
        _c("i", { staticClass: "far fa-calendar-alt mr-1 mt-1" }),
        _vm._v(
          " " +
            _vm._s(
              _vm.today.getDate() +
                "-" +
                (_vm.today.getMonth() + 1) +
                "-" +
                _vm.today.getFullYear()
            ) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "box-report row" }, [
          _c("div", { staticClass: "col-md-3" }, [
            _c(
              "div",
              { staticClass: "media  border border-success rounded-1" },
              [
                _c("img", {
                  staticClass: "align-self-center",
                  attrs: { src: "/imgs/main/member.svg", alt: "image" }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "media-body " }, [
                  _vm._v("\n              สมาชิก"),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.repord.users))])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3" }, [
            _c(
              "div",
              { staticClass: "media border border-primary rounded-1" },
              [
                _c("img", {
                  staticClass: "align-self-center",
                  attrs: { src: "/imgs/main/allgoods.svg", alt: "image" }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "media-body align-self-center" }, [
                  _vm._v("\n              สินค้าทั้งหมด"),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.repord.products))])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3" }, [
            _c("div", { staticClass: "media border border-danger rounded-1" }, [
              _c("img", {
                staticClass: "align-self-center",
                attrs: { src: "/imgs/main/allorder.svg", alt: "image" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "media-body align-self-center" }, [
                _vm._v("\n              การสั่งซื้อ"),
                _c("br"),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.repord.orders))])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3" }, [
            _c(
              "div",
              { staticClass: "media border border-warning rounded-1" },
              [
                _c("img", {
                  staticClass: "align-self-center",
                  attrs: { src: "/imgs/main/sell_amount.svg", alt: "image" }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "media-body align-self-center" }, [
                  _vm._v("\n              ยอดขาย"),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.repord.total) + "฿")])
                ])
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.repord.populars.length > 0
        ? _c("div", { staticClass: "col-md-7 mt-3" }, [
            _c("div", { staticClass: "box-popular" }, [
              _vm._v("\n         สินค้าขายดี\n        ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "box-report row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "div",
                  { staticClass: " border rounded-1 p-3 " },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _vm._l(_vm.repord.populars, function(popular) {
                      return _c(
                        "div",
                        {
                          key: popular.id,
                          staticClass: "row pt-2 pb-2 border-top "
                        },
                        [
                          _c("div", { staticClass: "col-3 text-center" }, [
                            _c("img", {
                              staticClass: "m-0",
                              attrs: { width: "40px", src: popular.image }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-3  d-flex align-content-center flex-wrap"
                            },
                            [
                              _c("div", { staticClass: "w-100 text-left" }, [
                                _vm._v(_vm._s(popular.name))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-3  d-flex align-content-center flex-wrap"
                            },
                            [
                              _c("div", { staticClass: "w-100 text-center" }, [
                                _vm._v(_vm._s(popular.amount))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-3  d-flex align-content-center flex-wrap"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "w-100 text-right text-success"
                                },
                                [
                                  _vm._v(
                                    _vm._s(popular.total.toLocaleString()) + "฿"
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-5 mt-3" }, [
        _c("div", { staticClass: "box-popular" }, [
          _vm._v("\n         เปรียบเทียบสินค้าขายดี\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "box-report row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm.values.length > 0
              ? _c(
                  "div",
                  { staticClass: "border rounded-1 pl-4 pr-4" },
                  [
                    _c("DonutChart", { attrs: { initialValues: _vm.values } }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row list-popular" },
                      _vm._l(_vm.values, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "col-md-4 mb-2" },
                          [
                            index != 5 && _vm.repord.populars[index]
                              ? _c("div", [
                                  _c("span", {
                                    style:
                                      "background-color:" + _vm.colors[index]
                                  }),
                                  _vm._v(
                                    _vm._s(
                                      _vm.repord.populars[index]
                                        ? _vm.repord.populars[index].name
                                        : "?"
                                    )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            index == 5 && _vm.repord.populars.length > 4
                              ? _c("div", [
                                  _c("span", {
                                    style:
                                      "background-color:" + _vm.colors[index]
                                  }),
                                  _vm._v("อื่นๆ")
                                ])
                              : _vm._e()
                          ]
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12 mt-3" }, [
        _c("div", { staticClass: "box-popular" }, [
          _vm._v("\n         การสั่งซื้อล่าสุด\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "box-report row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: " border rounded-1 p-3 " }, [
              _c(
                "div",
                { staticClass: "p-0 mb-2" },
                _vm._l(_vm.repord.newOrders, function(item, index) {
                  return _c("div", { key: index, staticClass: "row mb-2" }, [
                    _vm._m(2, true),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-9 align-self-center" }, [
                      _vm._v(
                        "\n                    มีรายการสั่งซื้อ เลขที่ออเดอร์ "
                      ),
                      _c("span", { staticClass: "text-primary" }, [
                        _vm._v(_vm._s(item.order_no))
                      ]),
                      _vm._v(
                        "\n                    ชื่อ " +
                          _vm._s(item.fullname) +
                          "\n                    "
                      ),
                      item.pay_type == "ONLINE"
                        ? _c("span", { staticClass: "text-success" }, [
                            _vm._v(
                              "ได้ทำการชำระเงิน จำนวน " +
                                _vm._s(item.total_price.toLocaleString()) +
                                " บาท"
                            )
                          ])
                        : _c("span", { staticClass: "text-orange" }, [
                            _vm._v(
                              "รอการชำระเงิน จำนวน " +
                                _vm._s(item.total_price.toLocaleString()) +
                                " บาท"
                            )
                          ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm
                                .$moment(item.created_at)
                                .format("DD/MMMM/YYYY HH:mm:ss"),
                              expression:
                                "$moment(item.created_at).format('DD/MMMM/YYYY HH:mm:ss')"
                            }
                          ],
                          staticClass: "date"
                        },
                        [_vm._v(_vm._s(_vm.$moment(item.created_at).fromNow()))]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-2 align-self-center" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-primary btn-sm",
                            attrs: {
                              to: {
                                name: "orders.show",
                                params: { id: item.id }
                              }
                            }
                          },
                          [_vm._v("รายละเอียด")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    index != _vm.repord.newOrders.length - 1
                      ? _c("hr", { staticClass: "col-12 mt-2 m-0 p-0" })
                      : _vm._e()
                  ])
                }),
                0
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h1", [_vm._v(" หน้าหลัก ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row p-0 mb-2" }, [
      _c("div", { staticClass: "col-3 text-center" }, [_vm._v("รูปสินค้า")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3 text-left" }, [_vm._v("ชื่อสินค้า")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3 text-center" }, [_vm._v("จำนวนขาย")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3 text-right" }, [_vm._v("จำนวนเงิน")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-1" }, [
      _c("img", {
        staticClass: "img-fulid",
        attrs: { src: "/imgs/main/order.svg" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }