<template>
  <div class="modal fade item-modal" role="dialog" tabindex="-1" :id="id">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title color black h3">อัพโหลดสินค้า</p>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 mt-2">
              <div class="ui segments h-100">
                <div class="ui segment">
                  <p class="ui text big color black">ค้นหาจากหมวดหมู่สินค้า</p>
                </div>
                <div class="ui segment">
                  <div class="ui form">
                    <div class="field">
                      <label class="ui text large">หมวดหมู่หลัก</label>
                      <div>
                        <select
                          class="selectpicker"
                          data-live-search="true"
                          v-model="mainCategory"
                          @change="selectCategoryId"
                          placeholder="กรุณาเลือก"
                        >
                          <option selected disabled :value="0" class="d-none">กรุณาเลือก</option>
                          <option
                            :value="category"
                            v-for="category in categories"
                            :data-tokens="category.name"
                          >{{ category.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="field mt-4">
                      <label class="ui text large">หมวดหมู่ย่อย</label>
                      <div class>
                        <select
                          class="selectpicker"
                          data-live-search="true"
                          v-model="subCategory"
                          @change="selectSubCategoryId"
                          :disabled.sync="!mainCategory.childrens"
                        >
                          <option selected disabled :value="0" class="d-none">กรุณาเลือก</option>
                          <option
                            :value="children"
                            v-for="children in mainCategory.childrens"
                            :data-tokens="children.name"
                          >{{ children.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mt-2">
              <div class="ui segments">
                <div class="ui segment">
                  <div class="ui form">
                    <div class="ui action input fluid">
                      <input
                        type="text"
                        placeholder="ค้นหาสินค้า"
                        v-model="productSearchText"
                        @input="searchProduct"
                      />
                      <button class="ui button blue">
                        <i class="search icon large m-0"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="ui segment select-product-list">
                  <div class="ui w-100 text-center" v-if="onSearchProduct">
                    <div
                      class="spinner-border text-muted text-center spinner-border-lg"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div class="ui w-100 text-center" v-if="!onSearchProduct && products.length == 0">
                    <p class="lead text-muted">ไม่พบสินค้า</p>
                  </div>
                  <div
                    class="ui checkbox w-100 product-list"
                    v-for="product in products"
                    :key="product.id"
                  >
                    <input
                      type="radio"
                      name="product-item"
                      tabindex="0"
                      class="hidden"
                      :value="product.id"
                      :id="`product-${product.id}-${id}`"
                      v-model="selectProduct"
                    />
                    <label class="d-block w-100" :for="`product-${product.id}-${id}`">
                      <div class="row align-items-center">
                        <div class="col-2">
                          <div class="thumb" v-if="product.images.length > 0">
                            <img :src="product.images[0].url" />
                          </div>
                          <span class="avatar avatar-xl" v-else>P</span>
                        </div>
                        <div class="col-7">
                          <p class="ui text medium color black">{{ product.titleTH }}</p>
                        </div>
                        <div class="col-3 text-right">
                          <p class="ui text medium color black">{{ product.price }}฿</p>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <a class="ui button red basic text" data-dismiss="modal">
            <span class="ui text large">ยกเลิก</span>
          </a>
          <a class="ui button blue text" :class="{'disabled': disabled}" :disabled="disabled">
            <span class="ui text large" @click="addProductToSlot">บันทึก</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- itemModal -->
</template>

<script>
import { setTimeout } from "timers";
export default {
  name: "selectProductSlotModal",
  components: {},
  props: {
    currentSlot: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      default: "productSlotModal-1"
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      products: [],
      mainCategory: 0,
      subCategory: 0,
      mainCategoryId: 0,
      subCategoryId: 0,
      productSearchText: "",
      onSearchProduct: false,
      selectProduct: undefined
    };
  },
  computed: {
    filterProducts() {
      if (this.productSearchText !== "") {
        return this.products.filter(product => {
          if (product.titleTH.indexOf(this.productSearchText) != -1) {
            return product;
          }
        });
      }
      return this.products;
    },
    disabled() {
      return this.selectProduct == undefined;
    }
  },
  mounted() {
    $(`#${this.id}`).on("show.bs.modal", () => {
      this.selectProduct = undefined;
    });
  },
  created() {
    console.log(this.categories);
  },
  methods: {
    selectCategoryId() {
      this.mainCategoryId = this.mainCategory.id;
      this.subCategoryId = 0;
      this.searchProduct();
    },
    selectSubCategoryId() {
      this.mainCategoryId = 0;
      this.subCategoryId = this.subCategory.id;
      this.searchProduct();
    },
    searchProduct() {
      this.onSearchProduct = true;
      this.products = [];
      let formData = new FormData();
      if (this.productSearchText != "") {
        formData.append("search", this.productSearchText);
      }
      if (this.mainCategoryId != 0) {
        formData.append("categoryId", this.mainCategoryId);
      }
      if (this.subCategoryId != 0) {
        formData.append("subCategoryId", this.subCategoryId);
      }

      axios
        .post(`/api/admin/products/search`, formData)
        .then(res => {
          this.products = res.data.data;
          this.onSearchProduct = false;
          $(".selectpicker").selectpicker("refresh");
        })
        .catch(error => {
          this.onSearchProduct = false;
        });
    },
    addProductToSlot() {
      this.$emit("setProductToSlot", this.selectProduct, this.currentSlot);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
