<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- <alert id="myAlert" :type="alert.type" :text="alert.text" /> -->
        <div class="page-header"><h1>วิธีการชำระเงิน</h1></div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="card-title">เพิ่มวิธีชำระเงิน</h4>
          </div>
          <div class="card-body">
            <editor v-model="payment" value="contact"></editor>
          </div>
        </div>
        <div class="mt-5 text-center  ">
          <a
            href="#"
            class="btn btn-lg btn-gradient-primary"
            @click.prevent="updatePayment"
            >บันทึก</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '@/components/Editor'

export default {
  name: 'paymentSetting',
  components: { Editor },
  props: {},
  data() {
    return {
      payment: ''
    }
  },
  computed: {},
  created() {
    this.fetchAppData()
  },
  methods: {
    fetchAppData() {
      axios.get('/api/admin/applications').then(res => {
        this.payment = res.data.data.payment
      })
    },
    updatePayment() {
      let form = new FormData()
      form.append('payment', this.payment)
      form.append('_method', 'patch')
      axios.post('/api/admin/applications', form).then(res => {
        swal({
          title: '',
          text: 'บันทึกสำเร็จ',
          icon: 'success',
          buttons: false,
          timer: 3000
        })
        this.fetchAppData()
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
