var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row no-gutters" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "custom-card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-2 col-sm-12 text-center" }, [
                _vm.user.avatar
                  ? _c("span", {
                      staticClass: "avatar avatar-xxl",
                      style:
                        "background-image: url(/storage/users/avatar/" +
                        _vm.user.avatar +
                        ")"
                    })
                  : _c("span", { staticClass: "avatar avatar-xxl" }, [
                      _vm._v("U")
                    ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-10 col-sm-12" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [_vm._v("ชื่อ")]),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control form-control-lg",
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.user.firstname }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("นามสกุล")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control form-control-lg",
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.user.lastname }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("วันเกิด")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control form-control-lg",
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.user.birthdate }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [_vm._v("เพศ")]),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control form-control-lg",
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.user.gender }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.user.addresses, function(address, i) {
                      return _c("div", { key: i, staticClass: "col-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("ที่อยู่ในการจัดส่ง " + _vm._s(i + 1))
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "form-control form-control-lg",
                            attrs: { type: "text", readonly: "" },
                            domProps: { value: address.text }
                          })
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("เบอร์โทรศัพท์")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control form-control-lg",
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.user.phone }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6 col-sm-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [_vm._v("อีเมล์")]),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control form-control-lg",
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: _vm.user.email }
                        })
                      ])
                    ])
                  ],
                  2
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h1", [_vm._v("ผู้ใช้งาน")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }