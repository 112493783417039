require("./bootstrap");
import router from "./router";
import store from "./store";
import Vue from "vue";
import VeeValidate from "vee-validate";
import Element from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import CKEditor from "@ckeditor/ckeditor5-vue";
import "@ckeditor/ckeditor5-build-classic";
import "./directive";

const moment = require("moment");
require("moment/locale/th.js");
moment.locale("th");
Vue.prototype.$moment = moment;

Vue.use(Element, { locale });
Vue.use(VeeValidate, { events: "blur|change" });
Vue.use(CKEditor);
Vue.component("pagination", require("laravel-vue-pagination"));
Vue.component("skeleton-box", require("./components/SkeletonBox").default);

new Vue({
  el: "#app",
  router,
  store
});
