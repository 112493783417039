var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lastPage > 1
    ? _c("div", { staticClass: "text-center mt-4 mb-5" }, [
        _c("div", { staticClass: "page-center" }, [
          _c(
            "ul",
            { staticClass: "pagination" },
            [
              _c(
                "li",
                {
                  staticClass: "page-item",
                  class: { disabled: _vm.currentPage == 1 }
                },
                [
                  _vm.currentPage != 1
                    ? _c(
                        "a",
                        {
                          staticClass: "page-link",
                          attrs: { tabindex: "-1" },
                          on: {
                            click: function($event) {
                              _vm.pageNext(_vm.currentPage - 1)
                            }
                          }
                        },
                        [_vm._v("Previous")]
                      )
                    : _c(
                        "a",
                        { staticClass: "page-link", attrs: { tabindex: "-1" } },
                        [_vm._v("Previous")]
                      )
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.pages, function(page, i) {
                return _c(
                  "li",
                  {
                    key: i,
                    staticClass: "page-item",
                    class: { active: page == _vm.currentPage }
                  },
                  [
                    page != _vm.currentPage
                      ? _c(
                          "a",
                          {
                            staticClass: "page-link",
                            on: {
                              click: function($event) {
                                _vm.pageNext(page)
                              }
                            }
                          },
                          [_vm._v(_vm._s(page))]
                        )
                      : _c("a", { staticClass: "page-link" }, [
                          _vm._v(_vm._s(page))
                        ])
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "page-item",
                  class: { disabled: _vm.currentPage == _vm.pages.length }
                },
                [
                  _vm.currentPage != _vm.pages.length
                    ? _c(
                        "a",
                        {
                          staticClass: "page-link",
                          on: {
                            click: function($event) {
                              _vm.pageNext(_vm.currentPage + 1)
                            }
                          }
                        },
                        [_vm._v("Next")]
                      )
                    : _c("a", { staticClass: "page-link" }, [_vm._v("Next")])
                ]
              )
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }