import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

// modules

import auth from './modules/auth'
import sku from './modules/sku'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    sku
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
