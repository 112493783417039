var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-2 p-0" }, [
        _c("div", { staticClass: "step-menu pt-5 h-100" }, [
          _c("div", { staticClass: "row align-self-center" }, [
            _c("div", { staticClass: "col-12 mt-4 mb-4 p-3 text-center" }, [
              _c("img", {
                staticClass: "img-fluid",
                attrs: {
                  src:
                    _vm.step == 1
                      ? "/imgs/step/step1_b.svg"
                      : "/imgs/step/step1_w.svg",
                  alt: "step1"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-step", class: { active: _vm.step == 1 } },
                [_vm._v("ขั้นตอนที่ 1")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 mt-4 mb-4 p-3 text-center" }, [
              _c("img", {
                staticClass: "img-fluid",
                attrs: {
                  src:
                    _vm.step == 2
                      ? "/imgs/step/step3_b.svg"
                      : "/imgs/step/step3_w.svg",
                  alt: "step2"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-step", class: { active: _vm.step == 2 } },
                [_vm._v("ขั้นตอนที่ 2")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 mt-4 mb-4 p-3 text-center" }, [
              _c("img", {
                staticClass: "img-fluid",
                attrs: {
                  src:
                    _vm.step == 3
                      ? "/imgs/step/step3_b.svg"
                      : "/imgs/step/step3_w.svg",
                  alt: "step3"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-step", class: { active: _vm.step == 3 } },
                [_vm._v("ขั้นตอนที่ 3")]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-10 p-0" },
        [
          _c("navbar"),
          _vm._v(" "),
          _vm.step == 1 ? _c("ComStep1") : _vm._e(),
          _vm._v(" "),
          _vm.step == 2 ? _c("ComStep2") : _vm._e(),
          _vm._v(" "),
          _vm.step == 3
            ? _c("div", { staticClass: "p-5" }, [_vm._m(0)])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "w-100 mt-5 mb-5 text-center" }, [
            _vm.step < 3
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-gradient-primary btn-lg",
                    on: { click: _vm.nextStep }
                  },
                  [_vm._v("ต่อไป")]
                )
              : _vm._e()
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/admin/home" } }, [
      _c("img", {
        staticClass: "img-fluid",
        attrs: { src: "/imgs/step/welcome.png", alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }