<template>
  <nav class="col-md-2 d-none d-md-block sidebar">
    <div class="profile text-white text-center p-3">
      <span
        class="avatar avatar-xxl"
        :style="`background-image: url(/storage/avatars/admin/${admin.avatar}`"
        v-if="admin.avatar"
      ></span>
      <span class="avatar avatar-xxl" v-else>A</span>
      <div class="pt-1 d-flex flex-column">
        <span class="text-primary">ยินดีต้อนรับ</span>
        <span>
          {{ admin.username }}
          <a
            href="#editProfile"
            data-toggle="modal"
            data-target="#editProfile"
            data-backdrop="static"
          >
            <i class="fas fa-pen fa-xs"></i>
          </a>
        </span>
      </div>
    </div>
    <div class="sidebar-sticky">
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'admins.index' }" exact>
            <i class="fas fa-home mr-1"></i> หน้าหลัก
            <span class="sr-only">(current)</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'settings.banner' }">
            <i class="fas fa-file-upload mr-1"></i> อัพโหลดโลโก้ แบนเนอร์
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'themes.index' }">
            <i class="fas fa-fill-drip mr-1"></i> เลือกธีมสี ธีมเว็ปไซต์
          </router-link>
        </li>
        <li class="nav-item">
          <a
            class="nav-link d-flex w-100 align-items-center"
            href="#products"
            data-toggle="collapse"
          >
            <i class="fas fa-store mr-1"></i>
            <span>สินค้า</span>
            <i class="ml-auto ion ion-ios-arrow-down"></i>
          </a>
          <div class="collapse ml-4" id="products">
            <router-link :to="{ name: 'categories.index' }" class="nav-link">หมวดหมู่สินค้า</router-link>
            <router-link :to="{ name: 'products.create' }" class="nav-link">เพิ่มสินค้า</router-link>
            <router-link :to="{ name: 'products.index' }" class="nav-link" exact>รายการสินค้า</router-link>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/admin/contents">
            <span class="fas fa-th-large mr-1"></span> ส่วนเนื้อหา
          </a>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'orders.index'}" class="nav-link">
            <span class="fas fa-box mr-1"></span> การสั่งซื้อ
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'delivery.index'}" class="nav-link">
            <span class="fas fa-truck mr-1"></span> ตั้งค่าการจัดส่ง
          </router-link>
        </li>
        <!-- <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'promotion.index' }">
            <span class="fas fa-bullhorn mr-1"></span> จัดการโปรโมชั่น
          </router-link>
        </li>-->
        <li class="nav-item">
          <a class="nav-link">
            <span class="fas fa-bullhorn mr-1"></span> จัดการโปรโมชั่น
          </a>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'contacts.index' }">
            <span class="far fa-address-card mr-1"></span>
            รายการที่ติดต่่อเข้ามา
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link">
            <span class="fab fa-product-hunt mr-1"></span> แพ็คเก็จ
          </a>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'users.index' }">
            <span class="fas fa-users mr-1"></span> ผู้ใช้งาน
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/admin/admins">
            <span class="fas fa-user-shield mr-1"></span> ผู้ดูแล
          </router-link>
        </li>
        <li class="nav-item">
          <a
            class="nav-link d-flex w-100 align-items-center"
            href="#settings"
            data-toggle="collapse"
          >
            <i class="fas fa-cog mr-1"></i>
            <span>ตั้งค่า</span>
            <i class="ion ion-ios-arrow-down ml-auto"></i>
          </a>
          <div class="collapse ml-4" id="settings">
            <router-link :to="{ name: 'settings.website' }" class="nav-link">ข้อมูลเว็ปไซต์</router-link>
            <router-link :to="{ name: 'settings.payment' }" class="nav-link">วิธีการชำระเงิน</router-link>
            <router-link :to="{ name: 'settings.privacy' }" class="nav-link">นโยบายการใช้งาน</router-link>
            <router-link :to="{ name: 'settings.social' }" class="nav-link">โซเชียล</router-link>
            <router-link :to="{ name: 'settings.contact' }" class="nav-link">ติดต่อเรา</router-link>
            <router-link :to="{ name: 'settings.address' }" class="nav-link">ที่อยู่ในการจัดส่ง</router-link>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "sidebar",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    admin() {
      return this.$store.getters["auth/admin"];
    }
  },
  created() {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
