<template>
    <div v-if="lastPage > 1" class="text-center mt-4 mb-5">
        <div class="page-center">
            <ul class="pagination">
                <li class="page-item" :class="{'disabled' : currentPage == 1}">
                    <a v-if="currentPage != 1" @click="pageNext(currentPage - 1)" class="page-link" tabindex="-1">Previous</a>
                    <a v-else class="page-link" tabindex="-1">Previous</a>
                </li>
                <li v-for="(page, i) in pages" :key="i"
                    :class="{'active' : page == currentPage}"
                    class="page-item">
                    <a v-if="page != currentPage" @click="pageNext(page)" class="page-link">{{page}}</a>
                    <a v-else class="page-link">{{page}}</a>
                </li>
                <li class="page-item" :class="{'disabled' : currentPage == pages.length}">
                    <a v-if="currentPage != pages.length" @click="pageNext(currentPage + 1)" class="page-link">Next</a>
                    <a v-else class="page-link">Next</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'pagination',
    components: {},
    props: {
        currentPage: {
            type: Number,
            default: 1
        } ,
        lastPage:{
            type: Number,
            default: 1
        } ,
    },
    data () {
        return {
            pages: []
        }
    },
    created(){
        this.showPagination()
    },
    watch:{
        lastPage(){
            this.showPagination()
        }
    },
    methods: {
        showPagination(){
            let pages = []
            let num = this.lastPage
            for (let i = 1; i <= num; i++) {
                if(num < 16){
                    pages.push(i)
                }else if(num > 15){
                    if(i < 6 || (i > num - 6 ) ){
                        pages.push(i)
                    }
                }
            }
            this.pages = pages
        },
        pageNext(page){
            this.$parent.currentPage = page
        },
    }
}
</script>

<style lang="scss" scoped>
.page-center{
    display: inline-block;
}
</style>