<template>
  <div class="container">
    <form class="row">
      <div class="col-12">
        <div class="page-header">
          <h1>เพิ่มสินค้า</h1>
        </div>
        <el-row :gutter="15">
          <el-col :span="16">
            <el-card class="el-custom-card">
              <div slot="header" class="text-primary font-weight-bold">ข้อมูลทั่วไปสินค้า</div>
              <div class="form-group">
                <label for=""><span class="required"></span> ชื่อสินค้า</label>
                <input
                  type="text"
                  v-model="productForm.titleTH"
                  class="form-control"
                  :class="{'is-invalid': errors.has('title')}"
                  name="title"
                  v-validate="'required'"
                  placeholder="ชื่อสินค้า">
                <div class="invalid-feedback" v-show="errors.has('title')">
                  {{ errors.first('title') }}
                </div>
              </div>
              <div class="form-group">
                <label for="">รายละเอียดสินค้า</label>
                <div class="invalid-feedback" v-show="errors.has('description')">
                  {{ errors.first('description') }}
                </div>
                <editor v-model="productForm.detailTH" ref="description" :content="productForm.newDetailTH" value="description" v-validate="'required'" name="description">
                </editor>
              </div>
              <div class="form-group">
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="productForm.enabled">
                  <label class="custom-control-label" for="customSwitch1">แสดงสินค้า</label>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card class="el-custom-card box-categories">
              <div class="text-primary font-weight-bold" slot="header">หมวดหมู่สินค้า</div>
              <label class="mb-1">เลือกหมวดหมู่สินค้าทั้งหมด</label>
              <el-tree  :data="formatCategories" 
                        :default-checked-keys="defaultCheckedKeys" 
                        :default-expanded-keys="defaultExpandedKeys" 
                        class="border p-1" show-checkbox node-key="id" 
                        :props="defaultProps" ref="category-list">
              </el-tree>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card class="el-custom-card">
              <div class="text-primary font-weight-bold" slot="header">โน๊ต</div>
              <textarea class="form-control" name="note" v-model="productForm.note" rows="5"></textarea>
            </el-card>
          </el-col>
          <el-col :span="16">
            <el-card class="el-custom-card">
              <div slot="header">
                <span class="text-primary font-weight-bold">รูปภาพสินค้า ({{ productForm.images.length }})</span>
              </div>
              <el-upload
                ref="image-upload"
                action="/api/admin/images"
                list-type="picture-card"
                :multiple="true"
                :headers="headers"
                :limit="5"
                :file-list="productForm.images"
                name="file[]"
                :before-remove="beforeRemoveImage"
                :on-remove="handleRemoveImage"
                :on-success="handleUploadImageResponse">
                  <i class="el-icon-plus"></i>
              </el-upload>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card class="el-custom-card">
              <div class="text-primary font-weight-bold" slot="header">แท็กชื่อสินค้า</div>
              <div class="card-body px-0 pt-0 tag-list">
                <el-tag :key="tag" v-for="tag in productForm.tags" closable :disable-transitions="false" @close="handleClose(tag)">
                  {{tag}}
                </el-tag>
              </div>
              <div class="input-group">
                <input type="text" class="form-control" v-model="tagValue" ref="saveTagInput" @keyup.enter="handleInputConfirm" @blur="handleInputConfirm">
                <div class="input-group-append">
                  <a href="#" class="btn btn-outline-primary" @click.prevent="handleInputConfirm"><i class="el-icon-plus"></i> เพิ่ม</a>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="24">
            <div class="page-header">
              <h1>ราคาและสินค้า</h1>
            </div>
            <el-card class="el-custom-card">
              <div class="text-primary font-weight-bold" slot="header">ราคาและสินค้า<span class="required h"></span></div>
              <div class="row" v-if="!enabledSku">
                <div class="col-md-4 col-sm-12">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 align-self-center text-right">ราคาปกติ</label>
                    <div class="col-sm-8">
                      <div class="input-group">
                        <input @keyup="calculatePrice('price')" type="number" class="form-control" v-model="productForm.price">
                        <div class="input-group-append">
                          <span class="input-group-text">฿</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 align-self-center text-right">ส่วนลด</label>
                    <div class="col-sm-8">
                      <div class="input-group">
                        <input @keyup="calculatePrice('discount')" type="number" class="form-control" v-model="productForm.discountPrice">
                        <div class="input-group-append">
                          <span class="input-group-text">฿</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 align-self-center text-right">ส่วนลด (%)</label>
                    <div class="col-sm-8">
                      <div class="input-group">
                        <input @keyup="calculatePrice('percent')" type="number" class="form-control" v-model="productForm.discountPercent">
                        <div class="input-group-append">
                          <span class="input-group-text">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 align-self-center text-right">สต๊อก</label>
                    <div class="col-sm-8">
                      <div class="input-group">
                        <input type="number" class="form-control" v-model="productForm.quantity">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 align-self-center text-right">ต้นทุน</label>
                    <div class="col-sm-8">
                      <div class="input-group">
                        <input type="number" class="form-control" v-model="productForm.cost">
                        <div class="input-group-append">
                          <span class="input-group-text">฿</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="#" class="btn border-dashed btn-white btn-block btn-lg" @click.prevent="toggleEnabledSku">
                  <i class="el-icon-plus sku-icon shadow-sm"></i>
                  เพิ่มตัวเลือกสินค้า (SKU)
                </a>
              </div>
              <template v-else>
                <div class="row">
                  <!-- SKU 1 -->
                  <div class="col-md-3 col-sm-12">
                    ตัวเลือกที่ 1
                  </div>
                  <div class="col-md-9 col-sm-12">
                    <div class="card-body bg-light">
                      <div class="form-group row">
                        <label for="" class="col-sm-3">ชื่อ</label>
                        <div class="col-sm-9 d-flex align-items-center">
                          <input type="text" class="form-control w-75" v-model="productForm.sku1Label">
                          <a @click="enabledSku = false" class="text-muted ml-auto align-self-start" ><i class="fas fa-times"></i></a>
                        </div>
                      </div>
                      <div class="form-group row" v-for="(option, index) in productForm.sku1" :key="index">
                        <label for="" class="col-sm-3">
                          <span v-show="index === 0">ตัวเลือก</span>
                        </label>
                        <div class="col-sm-9 d-flex align-items-center">
                          <input type="text" class="form-control w-75" v-model="option.name" @keyup.enter="addSkuOption(1)">
                          <a href="#" class="text-muted ml-1" v-tooltip="'ลบตัวเลือก'" @click.prevent="removeSkuOption(1, index)">
                            <i class="fas fa-times-circle"></i>
                          </a>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-9 offset-sm-3">
                          <a href="#" class="btn border-dashed btn-white btn-block btn-lg w-75" @click.prevent="addSkuOption(1)">
                            <i class="el-icon-plus sku-icon shadow-sm"></i>
                            เพิ่มตัวเลือกสินค้า
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- SKU 2 -->
                  <div class="col-md-3 col-sm-12">
                    ตัวเลือกที่ 2
                  </div>
                  <div class="col-md-9 col-sm-12">
                    <div class="card-body bg-light">
                      <div class="form-group row">
                        <label for="" class="col-sm-3">ชื่อ</label>
                        <div class="col-sm-9 d-flex align-items-center">
                          <input type="text" class="form-control w-75" v-model="productForm.sku2Label">
                        </div>
                      </div>
                      <div class="form-group row" v-for="(option, index) in productForm.sku2" :key="index">
                        <label for="" class="col-sm-3">
                          <span v-show="index === 0">ตัวเลือก</span>
                        </label>
                        <div class="col-sm-9 d-flex align-items-center">
                          <input type="text" class="form-control w-75" v-model="option.name" @keyup.enter="addSkuOption(2)">
                          <a href="#" class="text-muted ml-1" v-tooltip="'ลบตัวเลือก'" @click.prevent="removeSkuOption(2, index)">
                            <i class="fas fa-times-circle"></i>
                          </a>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-9 offset-sm-3">
                          <a href="#" class="btn border-dashed btn-white btn-block btn-lg w-75" @click.prevent="addSkuOption(2)">
                            <i class="el-icon-plus sku-icon shadow-sm"></i>
                            เพิ่มตัวเลือกสินค้า
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mt-5">
                    <p class="text-primary font-weight-bold">ข้อมูลตัวเลือก</p>
                  </div>
                  <div class="col-12">
                    <div class="">
                      <div class="form-group row">
                        <label for="" class="col-sm-3">แก้ไขทั้งชุด</label>
                        <div class="col-sm-9">
                          <div class="input-group">
                            <input type="number" class="form-control" placeholder="ต้นทุน (฿)" v-model.number="editSet.cost">
                            <input @keyup="calculateEditSet('price')" type="number" class="form-control" placeholder="ราคาปกติ (฿)" v-model.number="editSet.price">
                            <input @keyup="calculateEditSet('discount')" type="number" class="form-control" placeholder="ส่วนลด (฿)" v-model.number="editSet.discountPrice">
                            <input @keyup="calculateEditSet('percent')" type="number" class="form-control" placeholder="ส่วนลด (%)" v-model.number="editSet.discountPercent">
                            <input type="number" class="form-control" placeholder="สต๊อก" v-model.number="editSet.quantity">
                            <div class="input-group-append">
                              <button class="btn btn-primary" @click.prevent="updateSet">อัพเดททั้งหมด</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    รายการตัวเลือก
                  </div>
                  <div class="col-md-9 col-sm-12">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th width="15%">{{productForm.sku1Label}}</th>
                            <th v-if="productForm.sku2Label != '' && productForm.sku2.length > 0 " width="15%">{{productForm.sku2Label}}</th>
                            <th width="10%">ต้นทุน</th>
                            <th width="10%">ราคา</th>
                            <th width="10%">ส่วนลด</th>
                            <th width="10%">สต๊อก</th>
                          </tr>
                        </thead>
                        <tbody v-for="option in productForm.skuPrices">
                          <tr v-if="productForm.sku1.length > option.sku1">
                            <td>{{ productForm.sku1[option.sku1].name }}</td>
                            <td v-if="productForm.sku2.length > option.sku2 ">{{ productForm.sku2[option.sku2].name }}</td>
                            <td>
                              <input type="number" class="form-control" v-model.number="option.cost">
                            </td>
                            <td>
                              <input type="number" class="form-control" v-model.number="option.price">
                            </td>
                            <td>
                              <input type="number" class="form-control" v-model.number="option.discountPrice">
                            </td>
                            <td>
                              <input type="number" class="form-control" v-model.number="option.quantity">
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </template> <!-- sku -->
            </el-card>
          </el-col> <!-- price & sku -->
          <el-col :span="24">
            <div class="page-header">
              <h2>กล่องพัสดุเพื่อส่ง</h2>
            </div>
            <el-card class="el-custom-card">
              <div class="form-group row">
                <label for="" class="col-sm-1 align-self-center">น้ำหนัก</label>
                <div class="col-sm-3">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="น้ำหนัก" v-model.number="shipping.weight">
                    <div class="input-group-append">
                      <span class="input-group-text">g</span>
                    </div>
                  </div>
                </div>
                <label for="" class="col-sm-3 text-right align-self-center">ขนาดสินค้า (กxยxส)</label>
                <div class="col-sm-5">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="กว้าง" v-model.number="shipping.width">
                    <input type="text" class="form-control" placeholder="ยาว" v-model.number="shipping.length">
                    <input type="text" class="form-control" placeholder="สูง" v-model.number="shipping.height">
                    <div class="input-group-append">
                      <span class="input-group-text">cm</span>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
            
          </el-col>
        </el-row>
      </div>
      <div class="col-12 text-center mb-5">
        <button class="btn btn-gradient-primary btn-lg" @click.prevent="addProduct">บันทึก</button>
      </div>
    </form>
  </div>
</template>

<script>
  import Editor from '@/components/Editor'
import { isNull, isNumber } from 'util';
  export default {
    name: 'editProduct',
    components: { Editor },
    props: {},
    data() {
      return {
        tagInputVisible: false,
        tagValue: '',
        enabledSku: false,
        productForm: {
          titleTH: undefined,
          detailTH: '',
          enabled: true,
          images: [],
          tags: [],
          price: 0,
          discountPercent: 0,
          discountPrice: 0,
          quantity: 0,
          sku1Label: '',
          sku1: [],
          sku2Label: '',
          sku2: [],
          skuPrices: [],
          note: "",
          cost: 0,
          totalShipping: false,
        },
        categories: {},
        defaultProps: {
          children: 'children',
          label: 'label',
          category_id: 'category_id'
        },
        editSet: {
          price: undefined,
          discountPrice: undefined,
          discountPercent: undefined,
          quantity: undefined,
          update: false,
          cost: undefined,
        },
        shippingVenders: [],
        shippingSettingPrice: undefined,
        selectShippingVenders: [],
        shipping: {
          weight: 0,
          width: 0,
          length: 0,
          height: 0
        },
        shippingType: 1,
        selectedAutoShipping: [],
        shippingTest: {
          quantity: 1,
          fromZipcode: '10400',
          toZipcode: '10400',
          addPrice: 0,
          weight: 200,
          width: 31,
          length: 36,
          height: 26
        },
        calculatedShippingPrice: [],
        input: {
          type1: {price: 0},
          type2: {price: 0},
          type3: {
            first: 0,
            next: 0
            },
          type4:[{weight:0,price:0}]
        },
        shippingPrice: {},
        shippingVenders: [],
        selectedManualShipping: [],
        onCalculated: false,
        listSkus: [],
        defaultCheckedKeys: [],
        defaultExpandedKeys: Array(),
        setSku1: false,
        setSku2: false,
        totalShipping: false,
        checkboxUpdateShipping: false,
        categoriesSelect: []
      }
    },
    created(){
      this.getProduct()
    },
    computed: {
      sku1(){
        return this.productForm.sku1
      },
      sku2(){
        return this.productForm.sku2
      },
      formatCategories() {
        if (this.categories.data) {
          let categories = []
          this.categories.data.map(category => {
            categories.push({
              id: category.id,
              label: category.name,
              isMain: true,
              children: category.childrens.map(children => ({
                id: 'd' +children.id,
                category_id: category.id,
                isMain: false,
                label: children.name
              }))
            })
          })
          return categories
        }
      },
      headers() {
        return axios.defaults.headers.common
      },
      
    },
    mounted() {
      this.fetchCategoryList()
      this.shippingSetting()
    },
    methods: {
      getProduct(){
        axios.get('/api/admin/products/' + this.$route.params.id).then(response => {
          let product = response.data.data
          product.categories.map(categorie => {
              let subs = categorie.subIds.map(id => 'd' + id)
              this.defaultCheckedKeys = this.defaultCheckedKeys.concat(subs)
              this.defaultExpandedKeys.push(categorie.id)
              if(subs.length == 0){
              this.defaultCheckedKeys.push(categorie.id)
              }
          })
          let productForm = {
            titleTH: product.titleTH,
            detailTH: product.detailTH,
            newDetailTH: "",
            enabled: product.enabled,
            images: product.images,
            tags: product.tags,
            price: product.price,
            discountPrice: product.discountPrice,
            quantity: product.quantity,
            cost: product.cost,
            note: product.note,
            sku1Label: '',
            sku1: [],
            sku2Label: '',
            sku2: [],
            totalShipping: product.totalShipping,
          }
          this.productForm = productForm
          if(product.skus.length){
            this.enabledSku = true
            this.productForm.sku1Label = product.skuOptions[0].name
            this.productForm.sku1 = product.skuOptions[0].skus
            if(product.skuOptions.length  == 2){
              this.productForm.sku2 = product.skuOptions[1].skus
              this.productForm.sku2Label = product.skuOptions[1].name
            }
            this.listSkus = product.skus
          }else{  
            console.log('no sku')
            this.calculatePrice('discount')
          }
          this.renderDetail(this.productForm.detailTH)
          
          this.shipping = {
            weight: product.weight || undefined,
            width: product.width || undefined,
            length: product.length || undefined,
            height: product.height || undefined,
          }
        })
      },
      renderDetail(text) {
        axios.get(text)
          .then(result => {
            this.productForm.newDetailTH = result.data
          })
      },
      createSkuPrices(){
        console.log('createSkuPrices')
        let skus = []
        let sku1 = this.productForm.sku1
        let sku2 = this.productForm.sku2
        let num = 0
        for (let i = 0; i < sku1.length; i++) {
          if(sku2.length > 0){
            for (let s = 0; s < sku2.length; s++) {
              if(this.listSkus.length >= i+1){
                skus.push({
                  sku1: i,
                  sku2: s,
                  price: this.listSkus[num].price,
                  discountPrice: this.listSkus[num].discountPrice,
                  quantity: this.listSkus[num].quantity,
                  cost: this.listSkus[num].cost,
                })
                num++
              }else{
                skus.push({
                  sku1: i,
                  sku2: s,
                  price: 0,
                  discountPrice: 0,
                  quantity: 0,
                  cost: 0,
                })
              }
            }
          }else{
            if(this.listSkus.length >= i+1){
              skus.push({
                sku1: i,
                sku2: null,
                price: this.listSkus[i].price,
                discountPrice: this.listSkus[i].discountPrice,
                quantity: this.listSkus[i].quantity,
                cost: this.listSkus[i].cost,
              })
            }else{
              skus.push({
                sku1: i,
                sku2: null,
                price: 0,
                discountPrice: 0,
                quantity: 0,
                cost: 0,
              })
            }
          }
        }
        if(skus.length > 0){
          this.productForm.skuPrices = skus
        }
        if(this.setSku1 == true && this.setSku2){
          this.listSkus = []
        }
      },
      fetchCategoryList() {
        axios
          .get('/api/admin/categories')
          .then(result => (this.categories = result.data))
      },
      shippingSetting() {
        //shippingVenders
        axios.get('/api/admin/shippings')
        .then(result => {
          let data = result.data.data
          this.shippingVenders = data
        })
        axios
          .get('/api/admin/shipping/store')
          .then(result => {
            let data = result.data.data
            this.shippingType = data.serviceId
            this.shippingSettingPrice = data.id
            this.selectShippingVenders = data.shippings

            if(data.id == 1){
              this.input.type1 = {price:0}
            }else if(data.id == 2){
              this.input.type2 = data.type2
            }else if(data.id == 3){
              this.input.type3 = data.type3
            }else if( data.id == 4 ){
              if(data.type4.length < 1){
                this.input.type4 = [
                  {weight: 0,price:0},
                ]
              }else{
                this.input.type4 = data.type4
              }
            }
          })
      },
      beforeRemoveImage (file, fileList) {
        return this.$confirm(`ลบรูปภาพที่เลือก ?`)
      },
      handleRemoveImage(file, fileList) {
        axios.delete(`/api/admin/images/${file.filename}`)
          .then(() => {
            let index = _.findItem(fileList, ['uid', file.uid])
            this.$delete(fileList, index)
          })
      },
      handleUploadImageResponse(response, file, fileList) {
          file['filename'] = response.data.filename
          file['uid'] = response.data.id
          this.fileList = fileList
      },
      handleClose(tag) {
        this.productForm.tags.splice(this.productForm.tags.indexOf(tag), 1)
      },
      handleInputConfirm() {
        let inputValue = this.tagValue
        if (inputValue) {
          this.productForm.tags.push(inputValue)
        }
        this.tagInputVisible = false
        this.tagValue = ''
      },
      toggleEnabledSku () {
        this.enabledSku = !this.enabledSku
        if (this.enabledSku == true) {
          this.productForm.sku1.push({
            name: '',
          })
          this.productForm.price = 0
          this.productForm.discountPrice = 0
          this.productForm.quantity = 0
        }
      },
      addSku(sku) {
        if (sku == 1) {
            this.productForm.sku1.push({
            name: '',
          })
        }else{
          this.productForm.sku2.push({
            name: '',
          })
        }
      },
      addSkuOption (sku) {
        if (sku == 1) {
          this.productForm.sku1.push({
            name: '',
          })
        }else{
          this.productForm.sku2.push({
            name: '',
          })
        }
      },
      removeSkuOption (sku, index) {
        if (sku == 1) {
          this.productForm.sku1.splice(index, 1)
        }else{
          this.productForm.sku2.splice(index, 1)
        }
        
      },
      updateSet () {
        let price = this.editSet.price
        this.productForm.skuPrices.forEach(option => {
          option.price = price || 0
          option.discountPrice = this.editSet.discountPrice || 0
          option.quantity = this.editSet.quantity || 0
          option.cost = this.editSet.cost || 0
          return option
        })
        this.editSet.price = 0
        this.editSet.price = price
      },
      getShippingPrice () {
        console.log('getShippingPrice')
        this.onCalculated = true
        let parcel = {
          weight: this.shippingTest.weight,
          width: this.shippingTest.width,
          length: this.shippingTest.length,
          height: this.shippingTest.height,
        }
        let shippings = this.shippingVenders.map(ven => ven.courierCode)
        let postcodeFrom = this.shippingTest.fromZipcode
        let postcodeTo = this.shippingTest.toZipcode
        let formData = {
          shippings,
          parcel,
          postcodeFrom,
          postcodeTo
        }
        axios
          .post('/api/admin/shippings/price', formData, {
            headers: {
              'content-type': 'application/json'
            }
          })
          .then(result => {
              this.calculatedShippingPrice = result.data.data
          })
          .catch(error =>
            this.$notify.error(error.response.errors.message)
          ).finally(() => this.onCalculated = false)
      },
      calculateEditSet(val){
        if(this.editSet.discountPercent > 100){
          this.editSet.discountPercent = 100
        }
        if(Number(this.editSet.price) < 1 ){
           return
          this.editSet.price = 0
        }
        if(Number(this.editSet.discountPrice) < 1){
           return
          this.editSet.discountPrice = 0
        }
        if(Number(this.editSet.discountPercent) < 1){
           return
          this.editSet.discountPercent = 0
        }
        if(this.editSet.discountPrice > this.editSet.price){
          this.editSet.discountPrice = this.editSet.price
        }

        let price = this.editSet.price || 0
        let discountPrice = this.editSet.discountPrice || 0
        let discountPercent = this.editSet.discountPercent || 0
        
        switch (val) {
          case 'price':
            this.editSet.discountPrice = 0
            this.editSet.discountPercent = 0
            break;
          case 'percent':
            let dis = ((price *  discountPercent) / 100 ) 
            this.editSet.discountPrice = dis
            break;
          case 'discount':
            let sum = (discountPrice * 100 ) / price 
            this.editSet.discountPercent = sum
            break;
        }
      },
      calculatePrice(val){
        if(this.productForm.discountPercent > 100){
           return
          this.productForm.discountPercent = 100
        }
        if(Number(this.productForm.price) < 1 ){
           return
          this.productForm.price = 0
        }
        if(Number(this.productForm.discountPrice) < 1){
           return
          this.productForm.discountPrice = 0
        }
        
        if(Number(this.productForm.discountPercent) < 1){
           return
          this.productForm.discountPercent = 0
        }
        if(this.productForm.discountPrice > this.productForm.price){
           return
          this.productForm.discountPrice = this.productForm.price
        }

        let price = this.productForm.price
        let discountPrice = this.productForm.discountPrice
        let discountPercent = this.productForm.discountPercent

        switch (val) {
          case 'price':
            this.productForm.discountPrice = 0
            this.productForm.discountPercent = 0
            break;
          case 'percent':
            let dis = ((price *  discountPercent) / 100 ) 
            this.productForm.discountPrice = dis
            break;
          case 'discount':
            let sum = (discountPrice * 100 ) / price 
            this.productForm.discountPercent = sum
            break;
        }
      },
      addSelectedManualShipping (shipping) {
        shipping.showPrice = shipping.price
        this.selectedManualShipping.push(shipping)
      },
      updateShippingPrice (shipping) {
        shipping.showPrice = shipping.price
        $(`#manualShip-${shipping.id}`).collapse('hide')
      },
      removeSelectedManualShipping (index) {
        this.selectedManualShipping.splice(index, 1)
      },
      toTop(){
        $("html,body").animate({scrollTop:$("body").offset().top},"300")
      },
      handleChangeShipping(e,id){
        if(e.target.checked === false){
          let index = this.selectedManualShipping.findIndex(s => s.id == id )
          if(index >= 0){
            let mIndex = this.manualShippings.findIndex(s => s.id == id )
            this.manualShippings[mIndex].showPrice = 0
            this.manualShippings[mIndex].price = 0
            this.$delete(this.selectedManualShipping, index)
          }
        }
      },
      addShippingType4(){
        this.input.type4.push({weight: 0,price:0})
      },
      removeShippingType4(index){
        this.input.type4.splice(index, 1)
      },
      addProduct () {
        
        this.productForm.price = Number(this.productForm.price)
        this.productForm.discountPercent = Number(this.productForm.discountPrice)
        this.productForm.cost = Number(this.productForm.cost)
        this.productForm.quantity = Number(this.productForm.quantity)
        let form = this.productForm
        if(this.enabledSku == false){
          delete form.sku1Label
          delete form.sku2Label
          delete form.sku1
          delete form.sku2
          delete form.skuPrices
        }else{
          form.skuPrices.map(skuPrices => {
            price => skuPrices.price
            price => skuPrices.discountPercent
            price => skuPrices.cost
            price => skuPrices.quantity
          })
        }
        let selectedCategories = this.$refs['category-list'].getCheckedNodes()
        form.categories = []
        form.childrens = []
        selectedCategories.forEach(category => {
          if (category.isMain) {
            form.categories.push(category.id)
          } else {
            form.categories.push(category.category_id)
            form.childrens.push(category.id)
          }
        })
        if(this.checkboxUpdateShipping == true){
            form['shippings'] = this.selectShippingVenders
            let shippingPrice = {}
            if(this.shippingSettingPrice == 1){
              shippingPrice.type1 = {}
            }else if(this.shippingSettingPrice == 2){
              shippingPrice.type2 = this.input.type2
            }else if(this.shippingSettingPrice == 3){
              shippingPrice.type3 = this.input.type3
            }else if(this.shippingSettingPrice == 4){
              shippingPrice.type4 = this.input.type4
            }else{
              this.$notify({
                title: 'แจ้งเตือน!',
                message: 'กรุณาเลือก ตั้งค่ารูปแบบการส่งสินค้า',
                type: 'warning'
              })
              return
            }
            shippingPrice.serviceId = this.shippingType
            shippingPrice.id = Number(this.shippingSettingPrice)
            form['shippingPrice'] = shippingPrice
        }
        
        //images
        let images = this.$refs['image-upload']
        if(images.uploadFiles.length > 0){
          form.images = images.uploadFiles.map(image => (image.response)? image.response.data : image )
        }
        form.weight = this.shipping.weight
        form.width = this.shipping.width
        form.length = this.shipping.length
        form.height = this.shipping.height
        axios.put('/api/admin/products/'+ this.$route.params.id, form)
        .then(res => {
          this.defaultCheckedKeys = []
          this.defaultExpandedKeys = []
          this.$refs['image-upload'].clearFiles()
          this.$notify.success('แก้ไขสินค้าสำเร็จ')
          this.getProduct()
          setTimeout(() => {
          location.reload()
          }, 1000);
        })
        .catch(error => {
          let message = error.response.data.errors.snakeCase
          if(message == 'the_title_t_h_field_is_required.' ){
            message = 'กรุณากรอกชื่อสินค้า'
            this.toTop()
          }else if(message == 'the_detail_t_h_field_is_required.'){
            message = 'กรุณากรอกรายละเอียดสินค้า'
            this.toTop()
          }else if(message == 'is_number'){
            message = 'กรุณากรอกราคา ค่าส่วนลด จำนวน เป็นตัวเลข'
            this.toTop()
          }
          this.$notify({
            title: 'Error',
            message: message,
            type: 'error'
          })
        })
      },
     },
    watch: {
        sku1(val){
          this.setSku1 = true
          this.createSkuPrices()
        },
        sku2(val){
          this.setSku2 = true
          this.createSkuPrices()
        },
        'productForm.discountPrice'(val){
          this.calculatePrice('discount')
        },
        'productForm.quantity'(val){
            this.productForm.quantity =  (Number(val) < 0)? 0 : Number(val)
        },
        'productForm.cost'(val){
            this.productForm.cost = (Number(val) < 0)? 0 : Number(val)
        },
        'editSet.quantity'(val){
            this.editSet.quantity =  (Number(val) < 0)? 0 : Number(val)
        },
        'editSet.cost'(val){
            this.editSet.cost = (Number(val) < 0)? 0 : Number(val)
        },
      },
  }
</script>

<style lang="scss" scoped>
  .el-col {
    margin-bottom: 15px;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .sku-icon {
    padding: 5px;
    /* border: 1px solid var(--border); */
    border-radius: 50%;
    color: var(--blue);
  }
  .border-dashed:hover {
    border-color: darken(#e0e0e0, 10%);
  }
  .shipping-checkbox {
    .custom-control-label::before {
      border-color: var(--green) !important;
      border-radius: 50% !important;
      width: 1.2rem;
      height: 1.2rem;
    }
    .custom-control-label::after {
      width: 1.2rem;
      height: 1.2rem;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: var(--green);
    }
  }
  .tag-list>span.el-tag {
    margin: .125rem;
  }
  div[data-toggle='collapse']{
    cursor: pointer;
  }
  .arrow-drop{
    font-size: 1.4rem;
  }
  .arrow-drop::before{
    content: "\21B3";
  }
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background: none!important;
    background-image: none!important;
  }
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #27A9E0;
    background-color:#FFF!important;
}
</style>
