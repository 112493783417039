var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "custom-card mt-3" }, [
          _c("h3", { staticClass: "card-header" }, [
            _vm._v("ช่องทางการจัดส่ง")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", {}, [
              _c(
                "div",
                {
                  staticClass:
                    "custom-control custom-checkbox shipping-checkbox pb-1"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.deliveryTypes.atStore,
                        expression: "deliveryTypes.atStore"
                      }
                    ],
                    staticClass: "custom-control-input",
                    attrs: { type: "checkbox", id: "check_1" },
                    domProps: {
                      value: _vm.deliveryTypes.atStore,
                      checked: Array.isArray(_vm.deliveryTypes.atStore)
                        ? _vm._i(
                            _vm.deliveryTypes.atStore,
                            _vm.deliveryTypes.atStore
                          ) > -1
                        : _vm.deliveryTypes.atStore
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.deliveryTypes.atStore,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = _vm.deliveryTypes.atStore,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.deliveryTypes,
                                "atStore",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.deliveryTypes,
                                "atStore",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.deliveryTypes, "atStore", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "custom-control-label",
                      attrs: { for: "check_1" }
                    },
                    [_vm._v("รับที่ร้าน")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "custom-control custom-checkbox shipping-checkbox pb-1"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.deliveryTypes.carrier,
                        expression: "deliveryTypes.carrier"
                      }
                    ],
                    staticClass: "custom-control-input",
                    attrs: { type: "checkbox", id: "check_2" },
                    domProps: {
                      value: _vm.deliveryTypes.carrier,
                      checked: Array.isArray(_vm.deliveryTypes.carrier)
                        ? _vm._i(
                            _vm.deliveryTypes.carrier,
                            _vm.deliveryTypes.carrier
                          ) > -1
                        : _vm.deliveryTypes.carrier
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.deliveryTypes.carrier,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = _vm.deliveryTypes.carrier,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.deliveryTypes,
                                "carrier",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.deliveryTypes,
                                "carrier",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.deliveryTypes, "carrier", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "custom-control-label",
                      attrs: { for: "check_2" }
                    },
                    [_vm._v("บริการจัดส่ง")]
                  )
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.deliveryTypes.carrier
          ? _c("div", { staticClass: "custom-card mt-3" }, [
              _c("h5", { staticClass: "card-header" }, [
                _vm._v("\n              บริการจัดส่ง\n          ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "form-group mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg-6" },
                    _vm._l(_vm.shippingVenders, function(vender, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass:
                            "custom-control custom-checkbox shipping-checkbox pb-1"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectShippingVenders,
                                expression: "selectShippingVenders"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: { type: "checkbox", id: "vender" + i },
                            domProps: {
                              value: vender.courierCode,
                              checked: Array.isArray(_vm.selectShippingVenders)
                                ? _vm._i(
                                    _vm.selectShippingVenders,
                                    vender.courierCode
                                  ) > -1
                                : _vm.selectShippingVenders
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.selectShippingVenders,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = vender.courierCode,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selectShippingVenders = $$a.concat([
                                        $$v
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selectShippingVenders = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selectShippingVenders = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "vender" + i }
                            },
                            [_vm._v(_vm._s(vender.name))]
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-12 mt-4" }, [
                    _c("div", { staticClass: "card-body bg-light" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row collapse",
                          attrs: { id: "testShipping" }
                        },
                        [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "col-sm-4", attrs: { for: "" } },
                                [_vm._v("น้ำหนัก")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-8" }, [
                                _c("div", { staticClass: "input-group" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: _vm.shippingTest.weight,
                                        expression: "shippingTest.weight",
                                        modifiers: { number: true }
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "น้ำหนัก"
                                    },
                                    domProps: {
                                      value: _vm.shippingTest.weight
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.shippingTest,
                                          "weight",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function($event) {
                                        _vm.$forceUpdate()
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm._m(3)
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "col-sm-4", attrs: { for: "" } },
                                [_vm._v("ขนาดสินค้า (กxยxส)")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-8" }, [
                                _c("div", { staticClass: "input-group" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: _vm.shippingTest.width,
                                        expression: "shippingTest.width",
                                        modifiers: { number: true }
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "กว้าง"
                                    },
                                    domProps: { value: _vm.shippingTest.width },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.shippingTest,
                                          "width",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function($event) {
                                        _vm.$forceUpdate()
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: _vm.shippingTest.length,
                                        expression: "shippingTest.length",
                                        modifiers: { number: true }
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text", placeholder: "ยาว" },
                                    domProps: {
                                      value: _vm.shippingTest.length
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.shippingTest,
                                          "length",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function($event) {
                                        _vm.$forceUpdate()
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: _vm.shippingTest.height,
                                        expression: "shippingTest.height",
                                        modifiers: { number: true }
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text", placeholder: "สูง" },
                                    domProps: {
                                      value: _vm.shippingTest.height
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.shippingTest,
                                          "height",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function($event) {
                                        _vm.$forceUpdate()
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm._m(4)
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "col-sm-4", attrs: { for: "" } },
                                [_vm._v("รหัสไปรษณีย์ต้นทาง")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-8" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.shippingTest.fromZipcode,
                                      expression: "shippingTest.fromZipcode",
                                      modifiers: { number: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    placeholder: "รหัสไปรษณีย์ต้นทาง"
                                  },
                                  domProps: {
                                    value: _vm.shippingTest.fromZipcode
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.shippingTest,
                                        "fromZipcode",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function($event) {
                                      _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "col-sm-4", attrs: { for: "" } },
                                [_vm._v("รหัสไปรษณีย์ปลายทาง")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-8" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.shippingTest.toZipcode,
                                      expression: "shippingTest.toZipcode",
                                      modifiers: { number: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    placeholder: "รหัสไปรษณีย์ปลายทาง"
                                  },
                                  domProps: {
                                    value: _vm.shippingTest.toZipcode
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.shippingTest,
                                        "toZipcode",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function($event) {
                                      _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "col-sm-4", attrs: { for: "" } },
                                [_vm._v("ค่าบริการบวกเพิ่ม")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-8" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.shippingTest.addPrice,
                                      expression: "shippingTest.addPrice",
                                      modifiers: { number: true }
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    placeholder: "ค่าบริการบวกเพิ่ม"
                                  },
                                  domProps: {
                                    value: _vm.shippingTest.addPrice
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.shippingTest,
                                        "addPrice",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function($event) {
                                      _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-sm-8 offset-sm-4 text-center"
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: { disabled: _vm.onCalculated },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.getShippingPrice($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("span", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.onCalculated,
                                            expression: "onCalculated"
                                          }
                                        ],
                                        staticClass:
                                          "spinner-border spinner-border-sm",
                                        attrs: {
                                          role: "status",
                                          "aria-hidden": "true"
                                        }
                                      }),
                                      _vm._v(
                                        "\n                            คำนวณค่าจัดส่ง\n                          "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("div", { staticClass: "card-body bg-white" }, [
                              _c(
                                "div",
                                { staticClass: "row mb-0" },
                                _vm._l(_vm.calculatedShippingPrice, function(
                                  shipping,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "col-12 font-weight-normal"
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(shipping.name) +
                                          "\n                            "
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-md-2 text-orange float-right text-right"
                                        },
                                        [
                                          _vm._v(
                                            "\n                              " +
                                              _vm._s(shipping.price) +
                                              "\n                              "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-muted" },
                                            [_vm._v("฿")]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ])
                          ])
                        ]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group mb-2 mt-4" }, [
                  _c("label", { staticClass: "col-12", attrs: { for: "" } }, [
                    _vm._v("ตั้งค่ารูปแบบการส่งสินค้าภายในประเทศ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-12 mt-2" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "custom-control custom-checkbox shipping-checkbox pb-1"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.shippingSettingPrice,
                              expression: "shippingSettingPrice"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "radio",
                            id: "shippingSettingPrice1",
                            value: "1"
                          },
                          domProps: {
                            checked: _vm._q(_vm.shippingSettingPrice, "1")
                          },
                          on: {
                            change: function($event) {
                              _vm.shippingSettingPrice = "1"
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label pt-1",
                            attrs: { for: "shippingSettingPrice1" }
                          },
                          [_vm._v("ฟรี ไม่เก็บค่าจัดส่ง")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "custom-control custom-checkbox shipping-checkbox pb-1"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.shippingSettingPrice,
                              expression: "shippingSettingPrice"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "radio",
                            id: "shippingSettingPrice2",
                            value: "2"
                          },
                          domProps: {
                            checked: _vm._q(_vm.shippingSettingPrice, "2")
                          },
                          on: {
                            change: function($event) {
                              _vm.shippingSettingPrice = "2"
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label pt-1",
                            attrs: { for: "shippingSettingPrice2" }
                          },
                          [_vm._v("กำหนดค่าส่งพัสดุแบบตายตัว")]
                        ),
                        _vm._v(" "),
                        _vm.shippingSettingPrice == 2
                          ? _c("div", { staticClass: "d-flex" }, [
                              _c("span", { staticClass: "arrow-drop" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "d-flex form-group" }, [
                                _c(
                                  "div",
                                  { staticClass: "input-group col-md-10" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.input.type2.price,
                                          expression: "input.type2.price"
                                        }
                                      ],
                                      staticClass: "form-control text-right",
                                      attrs: {
                                        type: "number",
                                        placeholder: "ราคา"
                                      },
                                      domProps: {
                                        value: _vm.input.type2.price
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.input.type2,
                                            "price",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm._m(5)
                                  ]
                                )
                              ])
                            ])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "custom-control custom-checkbox shipping-checkbox pb-1"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.shippingSettingPrice,
                              expression: "shippingSettingPrice"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "radio",
                            id: "shippingSettingPrice3",
                            value: "3"
                          },
                          domProps: {
                            checked: _vm._q(_vm.shippingSettingPrice, "3")
                          },
                          on: {
                            change: function($event) {
                              _vm.shippingSettingPrice = "3"
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label pt-1",
                            attrs: { for: "shippingSettingPrice3" }
                          },
                          [
                            _vm._v(
                              "กำหนดค่าส่งพัสดุแปรผันตามจำนวนสินค้า (คิดเป็นชิ้น)"
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.shippingSettingPrice == 3
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c("span", { staticClass: "arrow-drop" }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "align-self-center col-md-2 pr-0"
                                    },
                                    [_vm._v("ค่าส่งสินค้าชิ้นแรก")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "input-group col-md-3" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.input.type3.first,
                                            expression: "input.type3.first"
                                          }
                                        ],
                                        staticClass: "form-control text-right",
                                        attrs: {
                                          type: "number",
                                          placeholder: "ราคา"
                                        },
                                        domProps: {
                                          value: _vm.input.type3.first
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.input.type3,
                                              "first",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm._m(6)
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-12 mt-2" }, [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c("span", { staticClass: "arrow-drop" }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "align-self-center col-md-2 pr-0"
                                    },
                                    [_vm._v("ค่าส่งสินค้าชิ้นถัดไป")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "input-group col-md-3" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.input.type3.next,
                                            expression: "input.type3.next"
                                          }
                                        ],
                                        staticClass: "form-control text-right",
                                        attrs: {
                                          type: "number",
                                          placeholder: "ราคา"
                                        },
                                        domProps: {
                                          value: _vm.input.type3.next
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.input.type3,
                                              "next",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm._m(7)
                                    ]
                                  )
                                ])
                              ])
                            ])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "custom-control custom-checkbox shipping-checkbox pb-1"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.shippingSettingPrice,
                              expression: "shippingSettingPrice"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "radio",
                            id: "shippingSettingPrice4",
                            value: "4"
                          },
                          domProps: {
                            checked: _vm._q(_vm.shippingSettingPrice, "4")
                          },
                          on: {
                            change: function($event) {
                              _vm.shippingSettingPrice = "4"
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label pt-1",
                            attrs: { for: "shippingSettingPrice4" }
                          },
                          [_vm._v("กำหนดค่าส่งพัสดุแปรผันตามน้ำหนักสินค้า")]
                        ),
                        _vm._v(" "),
                        _vm.shippingSettingPrice == 4
                          ? _c(
                              "div",
                              { staticClass: "row" },
                              _vm._l(_vm.input.type4, function(input4, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "col-md-12 mb-3" },
                                  [
                                    _c("div", { staticClass: "d-flex" }, [
                                      _c("span", { staticClass: "arrow-drop" }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "align-self-center col-md-1 pr-0"
                                        },
                                        [_vm._v("น้อยกว่า")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "input-group col-md-2" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: input4.weight,
                                                expression: "input4.weight"
                                              }
                                            ],
                                            staticClass:
                                              "form-control text-right",
                                            attrs: { type: "number" },
                                            domProps: { value: input4.weight },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  input4,
                                                  "weight",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _vm._m(8, true)
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "align-self-center col-md-1 pr-0 text-right"
                                        },
                                        [_vm._v("ราคา")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "input-group col-md-2" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: input4.price,
                                                expression: "input4.price"
                                              }
                                            ],
                                            staticClass:
                                              "form-control text-right",
                                            attrs: {
                                              type: "number",
                                              placeholder: "ราคา"
                                            },
                                            domProps: { value: input4.price },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  input4,
                                                  "price",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _vm._m(9, true)
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.input.type4.length > 1
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "align-self-center ml-3 pointer",
                                              on: {
                                                click: function($event) {
                                                  _vm.removeShippingType4(index)
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-times-circle fa-lg"
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      index + 1 == _vm.input.type4.length
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "align-self-center ml-3 pointer",
                                              on: {
                                                click: _vm.addShippingType4
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-plus-circle fa-lg"
                                              })
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "custom-card mt-3" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "col-12 text-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-gradient-primary btn-lg",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.updateShippingStore($event)
                    }
                  }
                },
                [_vm._v("\n                บันทึก\n              ")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h1", [_vm._v("ตั้งค่าการจัดส่ง")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group row mb-2" }, [
      _c("label", { staticClass: "col-sm-3", attrs: { for: "" } }, [
        _vm._v("เลือกผู้ให้บริการขนส่ง")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "mb-3",
        attrs: { "data-toggle": "collapse", "data-target": "#testShipping" }
      },
      [
        _vm._v(
          "\n                    ทดลองคำนวณค่าจัดส่งสินค้าแบบอัตโนมัติ\n                    "
        ),
        _c("span", { staticClass: "text-muted" }, [
          _vm._v(
            "(การคำนวณค่าจัดส่งนี้มีผลในการตรวจสอบเป็นไปตามเงื่อนไขของระบบขึ้นอยู่กับ\n                      น้ำหนัก ขนาดสินค้า ที่อยู่ต้นทางและปลายทาง)"
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("g")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("cm")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("฿")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("฿")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("฿/ชิ้น")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("g")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("฿")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }