var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "custom-card" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "form-group" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.title,
                    expression: "title"
                  }
                ],
                staticClass: "form-control form-control-lg",
                attrs: { type: "text" },
                domProps: { value: _vm.title },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.title = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("เบอร์โทรติดต่อ\n              ")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.phone,
                    expression: "phone"
                  }
                ],
                staticClass: "form-control form-control-lg",
                attrs: { type: "text" },
                domProps: { value: _vm.phone },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.phone = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _vm._m(3),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.description,
                    expression: "description"
                  }
                ],
                staticClass: "form-control form-control-lg",
                attrs: { rows: "4" },
                domProps: { value: _vm.description },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.description = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _vm._m(4),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.address,
                    expression: "address"
                  }
                ],
                staticClass: "form-control form-control-lg",
                attrs: { rows: "4" },
                domProps: { value: _vm.address },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.address = $event.target.value
                  }
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _vm._m(5),
        _vm._v(" "),
        _c("div", { staticClass: "mt-4 text-center" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-lg btn-gradient-primary",
              attrs: { href: "#", role: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.updateSiteSetting($event)
                }
              }
            },
            [_vm._v("บันทึก")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h1", [_vm._v("ข้อมูลเว็ปไซต์")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("ข้อมูลทั่วไป")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "" } }, [
      _vm._v("ชื่อเว็ปไซต์\n              "),
      _c("small", { staticClass: "text-muted" }, [
        _vm._v(
          "\n                (หรืออาจเป็นข้อความต้อนรับ เช่น\n                ยินดีต้อนรับสู่ร้านของเรา)"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "" } }, [
      _vm._v("\n              คำอธิบายเว็ปไซต์\n              "),
      _c("small", { staticClass: "text-muted" }, [
        _vm._v("\n                (หรือคำโฆษณาย่อๆ ไม่ควรเกิน 4 บรรทัด)")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "" } }, [
      _vm._v("\n              ที่อยู่ร้านค้า\n              "),
      _c("small", { staticClass: "text-muted" }, [
        _vm._v("\n                (ที่อยู่ร้านค้า ไม่ควรเกิน 2 บรรทัด)")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "custom-card mt-5" }, [
      _c("div", { staticClass: "card-header" }, [
        _c("h4", { staticClass: "card-title" }, [_vm._v("SEO")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [_c("h1", [_vm._v("SOON")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }