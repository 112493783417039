<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar />
      <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-0">
        <navbar />
        <transition mode="out-in" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" duration="500">
          <router-view />
        </transition>
      </main>
    </div>
    <div class="modal fade modal-mdw" id="editProfile" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              แก้ไขข้อมูลส่วนตัว
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-3 col-sm-12">
                  <ImageUpload :image="image" @finishedUpload="handleUploadImageResult" class="avatar-xl"></ImageUpload>
                </div>
                <div class="col-md-9 col-sm-12">
                  <!-- <div class="form-group">
                    <label for="">ชื่อผู้ใช้งาน</label>
                    <input type="text" class="form-control" :value="username" @input="handleInputChange" name="username" />
                  </div> -->
                  <div class="form-group">
                    <label for="">อีเมล์</label>
                    <input type="email" class="form-control" :value="email" @input="handleInputChange" name="email" />
                  </div>
                  <div class="form-group">
                    <label for="">เบอร์โทร</label>
                    <input type="tel" class="form-control" :value="phone" @input="handleInputChange" name="phone" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-dismiss="modal">
              ยกเลิก
            </button>
            <button type="button" class="btn btn-primary" @click="updateProfile">
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ImageUpload from '@/components/ImageUpload'

  export default {
    name: 'admin',
    components: {
      sidebar: require('../../components/Sidebar').default,
      navbar: require('../../components/Navbar').default,
      ImageUpload
    },
    data() {
      return {
        image: '',
        newImage: '',
        username: '',
        email: '',
        phone: '',
        userId: ''
      }
    },
    computed: {},
    created() {
      this.$store.dispatch('auth/getProfileData')
      this.fetchProfileData()
    },
    methods: {
      handleUploadImageResult(image) {
        this.newImage = image
      },
      fetchProfileData() {
        axios
          .get('/api/admin/user')
          .then(res => {
            let user = res.data.data
            this.username = user.username
            this.email = user.email
            this.phone = user.phone
            this.image = '/storage/avatars/admin/' + user.avatar
            this.userId = user.id
          })
          .catch(error => {
            if (error.response.status == 401) {
              return this.$router.push('/admin/login')
            }
          })
      },
      handleInputChange(event) {
        let value = event.target.value
        let name = event.target.name
        return (this[name] = value)
      },
      updateProfile() {
        let form = new FormData()
        form.append('username', this.username)
        form.append('email', this.email)
        form.append('phone', this.phone)
        form.append('_method', 'PATCH')
        if (this.newImage) {
          form.append('image', this.newImage)
        }
        axios.post(`/api/admin/admins/${this.userId}`, form)
        .then(res => {
             this.$notify({
              type: 'success',
              title: 'สำเร็จ',
              message: 'แก้ไขข้อมูลสำเร็จ'
            })
            setTimeout(() =>location.reload(),1000)
          })
          .catch(err => {
            return this.$notify({
              type: 'error',
              title: 'มีข้อผิดพลาดเกิดขึ้น',
              message: err.response.data.errors.message
            })
          })
      }
    }
  }
</script>

<style lang="scss" scoped></style>
