<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header">
          <h1>รายละเอียดการสั่งซื้อ</h1>
        </div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="card-title text-primary">รายการสั่งซื้อ {{ order.orderNO }}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-5 form-group">
                <label for="">ชื่อ - สกุล</label>
                <el-input type="text" v-model="order.fullname" disabled />
              </div>
              <div class="col-md-5 form-group">
                <label for="">เบอร์โทรศัพท์</label>
                <el-input type="text" v-model="order.phoneNumber" disabled />
              </div>
              <div class="col-md-2 form-group">
                <label for="">สถานะ</label>
                <el-select :value="order.status" placeholder="กรุณาเลือก" class="order-select" @change="updateOrderStatus">
                  <el-option :disabled="order.status != 'CREATE'" value="CREATE" label="รอดำเนินการ"></el-option>
                  <el-option v-if="order.deliveryType == 'carrier'" :disabled="order.payStatus == 'CREATE' || order.status != 'CREATE'" value="ACCEPT" label="พร้อมจัดส่ง"></el-option>
                  <el-option v-if="order.deliveryType == 'carrier'" :disabled="order.payStatus == 'CREATE' || order.status != 'ACCEPT'" value="SHIPPING" label="กำลังจัดส่ง"></el-option>
                  <el-option v-if="order.deliveryType == 'carrier'" :disabled="order.payStatus == 'CREATE' || order.status != 'SHIPPING'" value="FINISH" label="เสร็จสิ้น"></el-option>
                  <el-option v-if="order.deliveryType == 'atStore'" :disabled="order.status != 'CREATE'" value="FINISH" label="เสร็จสิ้น"></el-option>
                  <el-option :disabled="order.status != 'CREATE'" value="CANCEL" label="ยกเลิก"></el-option>
                </el-select>
              </div>
              <div v-if="order.deliveryType == 'carrier'" class="col-md-5 form-group">
                <label for="">ที่อยู่ในการจัดส่ง</label>
                <el-input v-model="addressTo" type="textarea" :rows="3" resize="none" disabled />
              </div>
              <div class="col-md-5 form-group">
                <label for="">ข้อความ(ยกเลิก)</label>
                <el-input v-model="order.detail" type="textarea" :rows="3" resize="none" disabled />
              </div>
              <div v-if="order.deliveryType == 'carrier' && order.status != 'CREATE' && order.status != 'CANCEL' " class="col-md-2 form-group text-center">
                <label for="">พิมพ์ใบปะหน้า</label>
                <a class="pointer" target="_bank" :href="order.print">
                  <img class="img-fluid" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAHNQAABzUB3AtqBwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAOVSURBVHic7d3LaxNRFAbw70ybVBERC65EBSX4QrFQwewE0YWIgoILl7roQ3z+Fa50V7Molm4FkdKlG3cibakPIqhFaUG704gWm6SZ4yKiyUyCMnXuPTbfb3kn3Dnk4+bO7e3MAERERMlIWh33F95qWn1bMD2YS+W7C9LolJJjIMYwEGO6XZ0ord9cV1zNiRwhxjAQYxiIMQzEGAZiDAMxhoEYw0CMYSDGMBBjGIgxDMQYBmIMAzGGgRjDQIxhIMYwEGMYiDEMxBgGYkzb/wTZf7+YXfcpc1EglwTYpcBml4WtNQJ8VtU5CYLREKWxmYH+apvPxeXvvt5alWASQF+qVXYoEcysVGqnZ6/s+Rg7Fm04OvZ+3bdK9SlUDropr2PNlirIz13NlRsbY3PIt+WVIYbhRN+mHh2INsYndcEFJ+UQRCX2Xbe6ytrroBaq2xdtaBXIBgeFUN3GaAPXIcYwEGMYiDEMZHUWBHpOJdyiEm4JVM5CMLeaDp3dsLMGLZTD7KGXwzs+N7Q9PDAy/7gnqDwHsC1JpxwhCQn0RiQMAMDPtptJ+2UgCWUz4aN2x1SCtsf+hIEkVFqutb3nsLscJr4fkYEktL4re6zdsVoWJ5L2y0CSu3VgZD62R5QfLfYCuJ20U15lJbenJ6g8O1x4cyObCR+tLHVJLaPHqytyBwmvsAAGslrbFfKgXO0CssC/eHQMf7KMYSDGMBBjGIgxDMQYBmIMAzGGgRjDQIxhIMYwEGP4zMW/xGcudigGYgwDMYaBGMNAjPG+Y9hfeHsG9T3onZ5LeSfA9anB3KTPIvyOEFUB5C78hwEAO1VRqNfkj99ARBSiFa81NApQhYjX9574n0MU1wBZ9F0GIIuiuOK7Cu9zyPRgbgLAhO86rPA/QqhJq0CWnFfRub5GG+KBqL5yUgpBgGK0LT6HBDIOxWEnFcHpOsTEOqNRCB2PtsVGSKmMUYi+cFKR23WIiXVGg9nl3uq9aGMskLmruXIm1JMAZlMvSUQVGqZ+nl/nQ+h7nQHUHz5Tq9ZOFc/vj63BWl5lPRna/eF7b+WIQocATAMot/rcPykOuOxmHSKLAgynf562ylCdEpGBEF/yrZ4EBDh80+da2zHkmz47BAMxhoEY4/1vWdwPacb9kN9MrFO4H9KI+yHgfkiE9zmE+yHN/I8QasJAjGEgxjAQYxiIMc6uslzdX/G/4wgxhoEYw0CIiIjWiB8rUvfKP33DGwAAAABJRU5ErkJggg==">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-card mt-5">
          <div class="card-header">
            <h4 class="card-title text-primary">รายละเอียดสินค้า</h4>
          </div>
          <div class="card-body">
              <div class="table-responsive">
                <table class="table table-bordered mb-0">
                  <thead class="bg-light text-center">
                    <tr>
                      <th width="5%" class="align-middle text-center">รูปสินค้า</th>
                      <th width="20%">ชื่อสินค้า</th>
                      <th width="5%">จำนวน</th>
                      <th class="text-right" width="5%">ราคา</th>
                      <th class="text-right" width="10%">ราคารวม</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="product in order.products" :key="product.id">
                      <td class="align-middle text-center">
                        <a :href="product.image" :data-lightbox="`product-${product.id}`"><img :src="product.image" width="48" height="48"></a>
                      </td>
                      <td>
                        <p class="mb-0">{{ product.title }}</p>
                        <span v-if="product.sku != null" class="">
                          {{(product.sku.sku1Name != null)? product.sku.sku1Name + ' : ' : '' }} {{(product.sku.skuSelect1 != null)? product.sku.skuSelect1 : '' }} 
                          {{(product.sku.sku2Name != null)? ', '+product.sku.sku2Name + ' : ' : '' }} {{(product.sku.skuSelect2 != null)? product.sku.skuSelect2 : '' }} 
                        </span>
                      </td>
                      <td class="text-right">{{ product.amount }}</td>
                      <td class="text-right">{{ product.netPrice ? product.netPrice.toLocaleString() : '-' }}</td>
                      <td class="text-right">{{ product.total ? product.total.toLocaleString() : '' }}</td>
                    </tr>
                    
                    <tr>
                      <td colspan="4" class="text-right bg-light border-bottom-0">{{order.shippingName}} (ราคาขนส่ง {{order.shippingFeeCheck}} )</td>
                      <td class="text-right bg-light border-bottom-0"> <span class="">{{ order.shippingFee}}</span></td>
                    </tr>
                    <tr>
                      <td colspan="4" class="border-0"></td>
                      <td class="text-right bg-light border-bottom-0"> ยอดรวม <span class="text-danger">{{ order.totalPrice ? order.totalPrice.toLocaleString() : '' }}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </div>
        <div class="custom-card mt-5 mb-5">
          <div class="card-header">
            <h4 class="card-title text-primary">รายละเอียดการชำระเงิน</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead class="bg-light">
                  <tr class="text-center">
                    <th>ประเภทการชำระ</th>
                    <th>วันที่ชำระ</th>
                    <th>ผู้อนุมัติ</th>
                    <th>สถานะ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-center">
                    <td v-if="order.payType == 'TRANFER'" class="text-center">
                      <a :href="order.transferSlip" target="_bank"  data-lightbox="payments">
                        <img :src="order.transferSlip" class="img-fluid" width="100">
                      </a>
                    </td>
                    <td v-else class="text-center">
                      Online
                    </td>
                    <td>
                      <span v-tooltip="$moment(order.createdAt).format('DD/MMMM/YYYY HH:mm:ss')">{{ $moment(order.createdAt).fromNow() }}</span>
                    </td>
                    <td v-if="order.admin != null" class="text-center">
                      {{order.admin}}
                    </td>
                    <td v-else class="text-center">
                      ไม่มี
                    </td>
                    <td style="width:205px;">
                      <!-- <span class="text-warning">ยังไม่ตรวจสอบ</span> -->
                      <el-select @change="updatePayStatus()" v-if="order.payType == 'TRANFER'" v-model="payStatus" placeholder="สถานะการชำระเงิน">
                        <el-option value="CREATE" label="รอการอนุมัติ"></el-option>
                        <el-option value="ACCEPT" label="อนุมัติ"></el-option>
                      </el-select>
                      <el-select v-else class="text-success" placeholder="สถานะการชำระเงิน">
                        <el-option value="ACCEPT" label="อนุมัติ"></el-option>
                      </el-select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="cancelModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Cancel order</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-12 form-group">
                <label for="">ข้อความ(ยกเลิก)</label>
                <el-input v-model="order.detail" type="textarea" :rows="3" resize="none" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">ยกเลิก</button>
            <button @click="cancelOrder()" type="button" class="btn btn-primary">บันทึก</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import lightbox from 'lightbox2'

export default {
  name: 'orderDetail',
  components: {},
  props: {},
  data () {
    return {
      order: {
        to: {}
      },
      payStatus: null,
    }
  },
  computed: {
    totalOrderPrice () {
      return this.order.totalPrice
    },
    addressTo(){
      if(this.order.to.address != undefined){
        return this.order.to.address + " " + this.order.to.state + " " + this.order.to.district + " " + this.order.to.province + " " + this.order.to.postcode
      }
      return ""
    }
  },
  created () {
    this.fetchOrder()
  },
  methods: {
    fetchOrder () {
      axios.get(`/api/admin/orders/${this.$route.params.id}`)
      .then(result => {
        this.order = result.data.data
        this.payStatus = this.order.payStatus
      })
    },
    updateOrderStatus (value) {
      let nextStatus = ''
      let formParam = {
        _method: 'PATCH',
        status: value
      }
      switch (value) {
        case 'CREATE':
          nextStatus = 'รอดำเนินการ'
          break
        case 'ACCEPT':
          nextStatus = 'พร้อมจัดส่ง'
          break
        case 'SHIPPING':
          nextStatus = 'กำลังจัดส่ง'
          break
        case 'FINISH':
          nextStatus = 'เสร็จสิ้น'
          break;
        case 'CANCEL':
          nextStatus = 'ยกเลิก'
          break;
        default:
          nextStatus = 'NONE'
          break;
      }
      this.$confirm(`เปลี่ยนสถานะออเดอร์เป็น <b class="text-primary">${nextStatus}</b> ?`, 'แก้ไขสถานะออเดอร์', {
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        if(value == 'ACCEPT'){
          axios.post('/api/admin/shipping/booking/'+this.$route.params.id)
          .then(res => {
            this.fetchOrder()
            return this.$notify({
              type: 'success',
              title: 'สำเร็จ',
              message: 'แก้ไขสถานะออเดอร์สำเร็จ'
            })
          })
          .catch(err => {
            return this.$notify({
              type: 'error',
              title: 'มีข้อผิดพลาดเกิดขึ้น',
              message: err.response.data.errors.message
            })
          })
        }
        else if (value == 'SHIPPING') {
          // this.$prompt('กรุณาระบุ Tracking Number', 'Tracking Number', {
          //   confirmButtonText: 'ตกลง',
          //   cancelButtonText: 'ยกเลิก'
          // }).then(value => {
          //   if (value.value === undefined || value.value.length <= 0) {
          //     return this.$notify.error('มีข้อผิดพลาดเกิดขึ้น', 'กรุณาระบุ Tracking Number')
          //   }
          //   formParam.tracking_no = value.value
          //   return this.sendUpdateRequest(formParam)
          // }).catch(() => {
          //   this.$notify({
          //     type: 'error',
          //     title: 'มีข้อผิดพลาดเกิดขึ้น',
          //     message: 'ไม่สามารถแก้ไขสถานะออเดอร์ได้เนื่องจาก ไม่ได้ระบุ Tracking Number'
          //   })
          // })
          return this.sendUpdateRequest(formParam)
        }
        else if (value == 'CANCEL') {
          $('#cancelModal').modal('show')
        }
        else {
          return this.sendUpdateRequest(formParam)
        }
      }).catch(() => {})
    },
    cancelOrder(){
      $('#cancelModal').modal('hide')
      let formParam = {
        _method: 'PATCH',
        status: 'CANCEL',
        message: this.order.detail
      }
      return axios.post(`/api/admin/orders/${this.$route.params.id}`, formParam)
        .then(res => {
          this.order.status = res.data.data.status
          return this.$notify({
            type: 'success',
            title: 'สำเร็จ',
            message: 'แก้ไขสถานะออเดอร์สำเร็จ'
          })
        })
        .catch(err => {
          if (err.status >= 400) {
            return this.$notify({
              type: 'error',
              title: 'มีข้อผิดพลาดเกิดขึ้น',
              message: err.response.data.message
            })
          }
        })
    },
    sendUpdateRequest (formParam) {
      return axios.post(`/api/admin/orders/${this.$route.params.id}`, formParam)
      .then(res => {
        this.order.status = res.data.data.status
        return this.$notify({
          type: 'success',
          title: 'สำเร็จ',
          message: 'แก้ไขสถานะออเดอร์สำเร็จ'
        })
      })
      .catch(err => {
        if (err.status >= 400) {
          return this.$notify({
            type: 'error',
            title: 'มีข้อผิดพลาดเกิดขึ้น',
            message: err.response.data.message
          })
        }
      })
    },
    updatePayStatus(){
      let status = this.payStatus
      axios.put(`/api/admin/pay/orders/${this.$route.params.id}`,{status: status})
      .then(result => {
        this.order = result.data.data
        this.payStatus = this.order.payStatus
      })
    }
  },
  watch:{
  }
}
</script>

<style lang="scss" scoped>
  textarea {
    resize: none;
  }
  thead.bg-light>tr>th {
    border-width: 1px;
    border-top: none;
  }
</style>
