<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header">
          <h1>
            อัพโหลดโลโก้ รูปภาพ, ไฟล์ .JPG หรือ .PNG (ขนาดแนะนำ 200x200 pixel)
          </h1>
        </div>
        <div class="d-flex">
          <div class="form-group">
            <label for="logoInput" class="btn btn-primary" role="button">
              <i class="far fa-images mr-1"></i>อัพโหลดไฟล์
            </label>
            <input type="file" class="d-none" id="logoInput" @change="handleUploadLogoImage" />
          </div>
          <div class="ml-3">
            <figure class="overlay" v-if="logoImage">
              <img :src="logoImage" height="160px" />
              <figcaption class="delete logo-delete cursor">
                <i class="fas fa-times-circle text-danger fa-2x" @click="deleteLogoImage"></i>
              </figcaption>
            </figure>
            <div class="card card-body no-logo-image" v-else>
              <i class="far fa-image fa-10x"></i>
            </div>
          </div>
        </div>
        <div class="page-header">
          <h1>
            อัพโหลดแบนเนอร์ ไฟล์ .JPG หรือ .PNG (ขนาดแนะนำ 1923 x 954 pixel)
          </h1>
        </div>
        <div>
          <label for="bannerInput" ref="file" class="btn btn-primary" role="button">
            <i class="far fa-images mr-1"></i>อัพโหลดไฟล์
          </label>
          <input type="file" class="d-none" ref="files" id="bannerInput" @change="handleUploadBannerImage" />
          <div class="row" v-if="hasBannerImage">
            <div class="col-md-4 col-sm-12 mt-3" v-for="banner in banners">
              <figure class="overlay">
                <img :src="banner.url" class="img-fluid banner-image" />
                <figcaption class="delete cursor banner-delete">
                  <i class="fas fa-times-circle text-danger fa-2x" @click="deleteBannerImage(banner.id)"></i>
                </figcaption>
              </figure>
            </div>
          </div>
          <div v-else>
            <div class="card card-body border-0">
              <i class="far fa-image fa-10x no-logo-image align-self-center"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'bannerLogoUpload',
    components: {},
    props: {},
    data() {
      return {
        logoImage: '',
        banners: [],
        files: '',
      }
    },
    computed: {
      hasBannerImage() {
        return this.banners.length > 0
      }
    },
    created() {
      this.fetchBanner()
    },
    methods: {
      handleUploadLogoImage(e) {
        let file = e.target.files[0]
        let form = new FormData()
        form.append('file', file)
        axios
          .post('/api/admin/banners/upload/logo', form)
          .then(res => (this.logoImage = res.data))
      },
      handleUploadBannerImage() {
        let files = this.$refs.files.files[0];
        let form = new FormData()
        form.append('files', files)
        // for( var i = 0; i < this.files.length; i++ ){
        //   let file = this.files[i];
        //   form.append('files[' + i + ']', file);
        // }
        axios
          .post('/api/admin/banners/upload', form)
          .then(res => (this.banners = res.data.data))
      },
      fetchBanner() {
        axios.get('/api/admin/banners').then(result => {
          this.banners = result.data.data
          this.logoImage = result.data.logo
        })
      },
      deleteLogoImage() {
        this.$confirm('ต้องการลบโลโก้หรือไม่?', {
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
        dangerouslyUseHTMLString: true,
        type: 'warning'
        })
        .then(() => {
          axios
            .delete(`/api/admin/banners/upload/logo`)
            .then(res => this.fetchBanner())
            .catch(err => console.log('deleted error: ', err))
        }).catch(() => {})
      },
      deleteBannerImage(bannerId) {
        this.$confirm('ต้องการลบแบนเนอร์หรือไม่?', {
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
        dangerouslyUseHTMLString: true,
        type: 'warning'
        })
        .then(() => {
          axios
          .delete(`/api/admin/banners/${bannerId}`)
          .then(res => this.fetchBanner())
          .catch(err => console.log('deleted error: ', err))
        }).catch(() => {})
        
      }
    }
  }
</script>

<style lang="scss" scoped>
img.preview-logo {
  /* width: 200px; */
  width: 100%;
  // height: 200px;
  object-fit: cover;
}
.logo-delete {
  left: 185px;
  top: -10px;
}
.no-logo-image {
  width: 200px;
  height: 200px;
  text-align: center;
  border: none;
  color: var(--border);
  align-items: center;
  display: flex;
  justify-content: center;
}
.banner-image {
  max-height: 160px;
  width: 100%;
  object-fit: cover;
}
.banner-delete {
  right: -10px;
  top: -10px;
}
</style>
