<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-2 p-0">
        <div class="step-menu pt-5 h-100">
          <div class="row align-self-center">
            <div class="col-12 mt-4 mb-4 p-3 text-center">
              <img :src="step == 1 ? '/imgs/step/step1_b.svg' : '/imgs/step/step1_w.svg'" class="img-fluid" alt="step1">
                <div class="text-step" :class="{'active': step == 1}">ขั้นตอนที่ 1</div> 
            </div>
            <div class="col-12 mt-4 mb-4 p-3 text-center">
              <img :src="step == 2 ? '/imgs/step/step3_b.svg' : '/imgs/step/step3_w.svg'" class="img-fluid" alt="step2">
                <div class="text-step" :class="{'active': step == 2}">ขั้นตอนที่ 2</div> 
            </div>
            <div class="col-12 mt-4 mb-4 p-3 text-center">
              <img :src="step == 3  ? '/imgs/step/step3_b.svg' : '/imgs/step/step3_w.svg'" class="img-fluid" alt="step3">
                <div class="text-step" :class="{'active': step ==3 }">ขั้นตอนที่ 3</div> 
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-10 p-0">
        <navbar />
        <ComStep1 v-if="step == 1"></ComStep1>
        <ComStep2 v-if="step == 2"></ComStep2>
        <div v-if="step == 3" class="p-5">
          <a href="/admin/home">
            <img src="/imgs/step/welcome.png" class="img-fluid" alt="">
          </a>
        </div>
        <div class="w-100 mt-5 mb-5 text-center">
        <button v-if="step < 3" @click="nextStep" class="btn btn-gradient-primary btn-lg">ต่อไป</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'guide',
    components: {
     navbar: require('../../components/Navbar').default,
     ComStep1: require('../Theme/index').default,
     ComStep2: require('../Setting/Banner').default,
    },
    data() {
      return {
        step: 1,
        image: '',
        newImage: '',
        username: '',
        email: '',
        phone: '',
        userId: ''
      }
    },
    computed: {},
    created() {
      
    },
    methods: {
      nextStep(){
        if(this.step < 3){
          this.step = this.step + 1
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.step-menu{
  background-color: #424242 !important;
  position: relative;
  color: #FFF;
}
.text-step{
  font-size: 1.2rem;
  &.active{
    color: rgb(49	,170	,221)
  }
}
</style>
