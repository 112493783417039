<template>
    <div class="container">
        <div class="row mb-5">
            <div class="col-12">
                <div class="page-header">
                <h1>โปรโมชั่น</h1>
                </div>
                <div class="alert alert-warning alert-dismissible fade show" role="alert">
                    หากกำหนดโปรโมชั่นเสร็จแล้ว ถ้าอยู่ในสถานะ <b>กำลังใช้งาน</b> จะไม่สามารถแก้ไขโปรโมชั่นได้อีก หรือถ้ายกเลิกโปรโมชั่นสามารถทำได้ กรุณาตรวจสอบให้ถี่ถ้วนก่อนทำการบันทึกข้อมูล
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <el-card class="custom-card mt-3">
                    <div slot="header" class="text-primary font-weight-bold">จัดการโปรโมชั่น</div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="name"> <b>ชื่อโปรโมชั่น</b>  <span class="required"></span></label>
                                <input id="name" v-model="promo.name" class="form-control"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row ">
                                <div class="col-12 pl-0"> <label><b>กำหนดเวลาที่เริ่ม-สิ้นสุดโปรโมชั่น</b></label>
                                    <div class="custom-control custom-switch float-right">
                                        <input type="checkbox" 
                                        id="selectDate"
                                        class="custom-control-input" 
                                        v-model="promo.selectDate" 
                                        >
                                        <label class="custom-control-label" for="selectDate" ></label>
                                    </div>
                                </div>
                                <div class="col-md-12 form-inline p-0 ">
                                    <input :disabled="!promo.selectDate" v-model="promo.startDate" type="datetime-local" class="form-control "/>
                                    <label class="pr-1 pl-1 text-center">ถึง</label>
                                    <input :disabled="!promo.selectDate" v-model="promo.expiredDate" type="datetime-local" class="form-control "/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label pr-5"><b>เงื่อนไขโปรโมชั่น</b></label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" v-model="promo.type" value="buy1free1">
                                    <label class="form-check-label" for="inlineRadio1">ซื้อ 1 แถม 1</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" v-model="promo.type" value="discount">
                                    <label class="form-check-label" for="inlineRadio2">ลดราคา</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" v-model="promo.type" value="deliveryfree">
                                    <label class="form-check-label" for="inlineRadio3">จัดส่งฟรี</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-card>
                <div class="custom-card mt-3 p-4">
                    <div slot="header" class="text-primary font-weight-bold">รายการสินค้าในโปรโมชั่น</div>
                    <div class="row">
                        <div class="col-6">
                            <form class="input-group" method="get">
                                <input type="text" class="form-control" placeholder="ค้นหาสินค้า" v-model="search" />
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="submit" @click.prevent="searchPromo">
                                    <i class="fas fa-search"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="col ml-auto">
                            <a href="#" data-toggle="modal" data-target="#exampleModalCenter" class="btn btn-gradient-success float-right" >
                            <i class="fas fa-plus mr-1"></i>เพิ่มสินค้า
                            </a>
                        </div>
                        <div class="col-12 mt-4">
                            <div class="card">
                                <div class="card-body w-100">
                                    <div slot="header" class="text-primary font-weight-bold">ตั่งค่าทั้งชุด</div>
                                    <div class="row">
                                        <div class="col-md-12 form-inline">
                                            <label class="pl-3 pr-3" for="">ตั้งส่วนลดอยู่ที่</label>
                                            <input type="number" id="inputDiscount" style="width:100px" name="inputDiscount" class="form-control " placeholder="บาท">
                                            <div class="p-3">หรือ</div>
                                            <input type="number" max="100" min="0" style="width:100px" id="inputDiscountPercent" name="inputDiscountPercent" class="form-control" placeholder="%">
                                            <label class="pl-3 pr-3" for="">จำกัดยอดซื้อ (ผู้ใช้งาน)</label>
                                            <input type="number" id="limitbuy" style="width:100px" name="limitbuy" class="form-control mr-4">
                                            <button class="btn btn-primary mr-4" @click.prevent="updateSet">อัพเดทที่เลือก</button>
                                            <button class="btn btn-primary" @click.prevent="updateSet">อัพเดททั้งหมด</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <div class="table-responsive">
                                <table class="table table-center table-bordered">
                                    <thead>
                                    <tr class="bg-light text-center">
                                        <th rowspan="2">
                                            <input type="checkbox" v-model="selectAll" @click="selectAllProducts" />
                                        </th>
                                        <th rowspan="2">รูปสินค้า</th>
                                        <th rowspan="2">ชื่อสินค้า</th>
                                        <th rowspan="2">ราคาเดิม (บาท)</th>
                                        <th colspan="2" class="border-bottom-0">ส่วนลด</th>
                                        <th rowspan="2">ราคาขาย (บาท)</th>
                                        <th rowspan="2">จำกัดยอดซื้อ</th>
                                        <th rowspan="2">จัดการ</th>
                                    </tr>
                                    <tr class="bg-light text-center">
                                        <th>(บาท)</th>
                                        <th>(%)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(product,index) in promo.showProducts" :key="index" class="text-center">
                                        <td class="text-center">
                                            <input type="checkbox" v-model="selectedProduct" :value="product.id" />
                                        </td>
                                        <td>
                                            <span class="avatar avatar-md" :style="
                                                `background-image: url(${product.images[0].url})`
                                                " v-if="product.images.length > 0"></span>
                                            <span class="avatar avatar-md" v-else>P</span>
                                        </td>
                                        <td>{{ product.titleTH }}</td>
                                        <td class="text-success">{{ product.netPrice.toLocaleString() }}</td>
                                        <td>
                                            <s class="text-dark">
                                                <span class="text-danger">{{ product.promotion.discountPrice.toLocaleString() }}</span>
                                            </s>
                                        </td>
                                        <td>{{ product.promotion.discountPercent }}%</td>
                                        <td>{{ product.promotion.netPrice.toLocaleString() }}</td>
                                        <td>{{ product.promotion.limit }}</td>
                                        <td class="text-center">
                                            <a @click="deleteProduct([product.id])" class="delete-product pointer">
                                                <i class="far fa-trash-alt fa-lg mr-1"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-12">
                            <Pagination v-if="promo.products.length > 1" :currentPage="currentPage" :lastPage="lastPage" ></Pagination>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
        <div class="modal fade bd-example-modal-lg" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg" >
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">เลือกสินค้าเพื่อจัดโปรโมชั่น</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body pb-0">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link  active" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">ค้นหาสินค้า</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link " id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">เลือกหมวดหมู่สินค้า</a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade pt-4" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <el-tree @check-change="handleCheckChange" :data="formatCategories" class="border p-1" show-checkbox node-key="id" :props="defaultProps" ref="category-list">
                                    </el-tree>
                                </div>
                                <div class="tab-pane fade show active pt-4" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="form-group input-group">
                                        <input type="text" v-model="inputModal" class="form-control" placeholder="ค้นหาสินค้า" />
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" @click="searchModal">
                                            <i class="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 form-inline mt-2">
                            <input @click="selectProducts" v-model="checkAll" value="true" id="selectall" type="checkbox" class="form-control">
                            <label class="ml-2" for="selectall">เลือกทั้งหมด</label>
                        </div>
                    </div>
                </div>
                <div class="modal-body border-top">
                    <div class="row modal-item" :class="{'loading' : loading}">
                        <div v-for="product in products" :key="product.id" class="col-md-2 col-sm-4 text-center">
                            <label :for="'check'+ product.id">
                                <div class="box-img" :class="{'active' : isActive(product.id) }">
                                    <img v-if="product.imageUrl != null" :src="product.imageUrl" class="img-fluid">
                                    <img v-else :src="'/imgs/product.png'" class="img-fluid">
                                    <input type="checkbox" :id="'check'+product.id" v-model="selectModal" :value="product">
                                </div>
                            </label>
                            <div class="text-center mb-2 box-title">
                                {{product.titleTH}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">ยกเลิก</button>
                    <button @click="addProductPromo" type="button" class="btn btn-primary" data-dismiss="modal">ยืนยัน</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setTimeout } from 'timers';
import Pagination from './Pagination';
export default {
    name: 'promotionCreate',
    components: {Pagination},
    props: {},
    data () {
        return {
            loading: false,
            search: '',
            laravelData: {},
            selectePromo: [],
            role: 0,
            selectedProduct: [],
            promo:{
                selectDate : false,
                name: '',
                type: null,
                dateStart: '',
                dateClose: '',
                products:[],
                showProducts:[],
            },
            products:[],
            selectAll:false,
            inputModal: '',
            selectModal: [],
            categories: {},
            defaultProps: {
                children: 'children',
                label: 'label',
                category_id: 'category_id',
            },
            selectCategory:[],
            selectSubCategory:[],
            checkAll: false,
            page: 5,
            currentPage: 1,
            lastPage: 1,
        }
    },
    computed: {
        formatCategories() {
            if (this.categories.data) {
            let categories = []
            this.categories.data.map(category => {
                categories.push({
                id: category.id,
                label: category.name,
                isMain: true,
                children: category.childrens.map(children => ({
                    id: children.id,
                    category_id: category.id,
                    isMain: false,
                    label: children.name
                }))
                })
            })
            return categories
            }
        },
    },
    created () {
        this.fetchCategoryList()
    },
    mounted(){
        $('.dropdown-menu a.dropdown-toggle').hover(function(e) {
        if (!$(this).next().hasClass('show')) {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        var $subMenu = $(this).next(".dropdown-menu");
        $subMenu.addClass('show');
        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
            $('.dropdown-submenu .show').removeClass("show");
        });
        return false;
        });
    },
    methods: {
        fetchPromoList() {
            this.search = ''
            axios
            .get('/api/admin/promotion',data)
            .then(result => (this.laravelData = result.data))
        },
        searchModal(){
            this.selectModal = []
            this.loading == true
            this.selectModal = []
            let data = {
                search : this.inputModal
            }
            axios.post('/api/admin/products/search',data)
            .then(res => this.products = res.data.data)
            .finally(() => (this.loading = false))
        },
        fetchCategoryList() {
            axios
            .get('/api/admin/categories')
            .then(result => (this.categories = result.data))
        },
        isActive(id){
            let valObj = this.selectModal.filter(function(elem){
                if(elem.id == id) return true;
            });
            if(valObj.length > 0)
            return true
        },
        handleCheckChange(data, checked, indeterminate) {
            // console.log(data, checked, indeterminate)
            if(data.isMain == true){
                if(this.selectCategory.length == 0){
                    if(checked == true){
                        this.selectCategory.push(data)
                        for (let index = 0; index < data.children.length; index++) {
                            if(this.selectSubCategory.length > 0){
                                let sub = this.selectSubCategory.findIndex(c => c.id == data.children[index].id)
                                if(sub < 0){
                                this.selectSubCategory.push(data.children[index])
                                }
                            }else{
                                this.selectSubCategory.push(data.children[index])
                            }
                        }
                        this.searchByCategory()
                    }else{
                        for (let index = 0; index < data.children.length; index++) {
                            if(this.selectSubCategory.length > 0){
                                let sub = this.selectSubCategory.findIndex(c => c.id == data.children[index].id)
                                if(sub >= 0){
                                    this.selectSubCategory.splice(sub, sub+ 1)
                                }
                            }
                        }
                        this.searchByCategory()
                    }
                }else{
                    if(checked == true){
                        let cat = this.selectCategory.findIndex(c => c.id == data.id)
                        if(cat < 0){
                            this.selectCategory.push(data)
                        }
                        for (let index = 0; index < data.children.length; index++) {
                            if(this.selectSubCategory.length > 0){
                                let sub = this.selectSubCategory.findIndex(c => c.id == data.children[index].id)
                                if(sub < 0){
                                this.selectSubCategory.push(data.children[index])
                                }
                            }else{
                                this.selectSubCategory.push(data.children[index])
                            }
                        }
                        this.searchByCategory()
                    }else{
                        let cat = this.selectCategory.findIndex(c => c.id == data.id)
                        if(cat >= 0){
                            this.selectCategory.splice(cat, cat+ 1)
                        }
                        if(indeterminate == false){
                            for (let index = 0; index < data.children.length; index++) {
                                if(this.selectSubCategory.length > 0){
                                    let sub = this.selectSubCategory.findIndex(c => c.id == data.children[index].id)
                                    if(sub >= 0){
                                        this.selectSubCategory.splice(sub, sub + 1)
                                    }
                                }
                            }
                            this.searchByCategory()
                        }
                    }
                }
            }else{
                if(this.selectSubCategory.length == 0){
                    if(checked == true){
                        let index = this.selectSubCategory.findIndex(c => c.id == data.id)
                        if(index < 0){
                            this.selectSubCategory.push(data)
                            this.searchByCategory()
                        }
                    }
                }else{
                    if(checked == true){
                        let index = this.selectSubCategory.findIndex(c => c.id == data.id)
                        if(index < 0){
                            this.selectSubCategory.push(data)
                            this.searchByCategory()
                        }
                    }else{
                        let index = this.selectSubCategory.findIndex(c => c.id == data.id)
                        if(index >= 0){
                            this.selectSubCategory.splice(index, index+ 1)
                            this.searchByCategory()
                        }
                    }
                }
            }
        },
        searchByCategory(){
            this.selectModal = []
            this.loading == true
            let data = {
                categoryId: this.selectCategory.map(cat => cat.id),
                subCategoryId: this.selectSubCategory.map(cat => cat.id),
            }
            axios.post('/api/admin/products/search/category',data)
            .then(res => this.products = res.data.data)
            .finally(() => (this.loading = false))
        },
        selectProducts(){
            if(this.checkAll != true){
                let products = this.products
                this.selectModal = products
            }else{
                this.selectModal = []
            }
        },
        addProductPromo(){
            let select = this.selectModal
            select.forEach(product => {
                let index = this.promo.products.findIndex(p => p.id == product.id)
                if(index < 0){
                    product.promotion = {
                        price: product.netPrice,
                        netPrice: product.netPrice,
                        discountPrice: 0,
                        discountPercent: 0,
                        limit: 1,
                    }
                    this.promo.products.push(product)
                }
            });
            this.selectModal = []
            $('#exampleModalCenter').modal('hide')
        },
        getProductPaginat(){
            let last = this.currentPage * this.page
            let first = (last - this.page  < 0)? 0 : last - this.page 
            this.promo.showProducts = this.promo.products.slice(first,last)
        },
        deleteProduct(ids){
            ids.forEach(id => {
                let index = this.promo.products.findIndex(p => p.id == id)
                if(index >= 0){
                    this.promo.products.splice( index ,1)
                }
            });
        },
        selectAllProducts(){
            if(this.selectAll != true){
                let products = this.promo.products.map(p => p.id)
                this.selectedProduct = products
            }else{
                this.selectedProduct = []
            }
        },
    },
    watch:{
        selectModal(val){
            if(val.length != this.products.length){
                this.checkAll = false
            }else if(val.length != 0){
                this.checkAll = true
            }
        },
        'promo.products'(){
            let getPage = Math.ceil(this.promo.products.length / this.page)
            if(getPage > 1){
                this.lastPage = getPage
            }else{
                this.currentPage = 1
                this.lastPage = 1
            }
            this.getProductPaginat()
        },
        currentPage(val){
            this.getProductPaginat()
        },
        selectedProduct(val){
            let products = this.promo.products.map(p => p.id)
            if(val.length != products.length){
                this.selectAll = false
            }else{
                this.selectAll = true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: .8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: .1rem;
  margin-right: .1rem;
}
.modal-item{
    max-height: 300px;
    overflow-y: scroll;
    &.loading{
        // opacity: .5;
    }
    .box-img{
        border: 1px solid #dcdfe6;
        position: relative;
        &:before,&:after{
            position: absolute;
            cursor: pointer;
            z-index: 1000;
            content: '';
            width: 100%;
            height: 100%;
            top: 0px;
        }
        &:after{
            top: auto;
            width: 25px;
            height: 25px;
            right: 2px;
            bottom: 1px;
            background-size: 50px;
            background-repeat: no-repeat;
            background-position: -11px;
        }
        &.active{
            &:before{
                background-color: rgba(143, 143, 138, 0.301);
            }
            &:after{
                background-image: url('/imgs/contents/icon-check-green.svg');
            }
        }
        input{
            position: absolute;
            opacity: 0;
        }
    }
    
    .box-title{
        line-height: 15px;
        padding: 5px 0px;
    }
}

</style>