<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header">
          <h1>เพิ่มผูู้ดูแล</h1>
        </div>
        <div class="custom-card">
          <form class="card-body" @submit.prevent="addNewAdmin">
            <div class="row no-gutters">
              <div class="col-md-2 col-sm-12">
                <ImageUpload></ImageUpload>
              </div>
              <div class="col-md-10 col-sm-12">
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label for="">ชื่อ</label>
                      <input v-model="firstname" type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label for="">นามสกุล</label>
                      <input v-model="lastname" type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-12">
                    <div class="form-group">
                      <label for="">เพศ</label>
                      <select
                        class="custom-select custom-select-lg"
                        v-model="gender"
                      >
                        <option disabled selected value="NONE"
                          >กรุณาเลือก</option
                        >
                        <option value="MALE">ชาย</option>
                        <option value="FEMALE">หญิง</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                      <label for="">เบอร์โทรศัพท์</label>
                      <input v-model="phone" type="tel" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label for=""
                        >อีเมล์ <span class="required"></span
                      ></label>
                      <input
                        type="email"
                        class="form-control form-control-lg"
                        v-model="email"
                      />
                      
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label for="">ที่อยู่</label>
                      <input v-model="address" type="text" class="form-control form-control-lg" />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label for="">
                        ชื่อผู้ใช้งาน <span class="required"></span>
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="username"
                      />
                      
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <label for="">ตำแหน่ง <span class="required"></span></label>
                    <select
                      class="custom-select custom-select-lg"
                      v-model="role">
                      <option disabled selected value="NONE">กรุณาเลือก</option>
                      <option value="ADMIN">ผู้ดูแล</option>
                      <option value="ROOT">ผู้ดูแลระบบ</option>
                    </select>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label>รหัสผ่าน <span class="required"></span></label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="password"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group text-center">
                  <button class="btn btn-gradient-primary btn-lg">
                    บันทึก
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ImageUpload from '@/components/ImageUpload'

  export default {
    name: 'createAdmin',
    components: { ImageUpload },
    props: {},
    data() {
      return {
        image: '',
        firstname: '',
        lastname: '',
        email: '',
        gender: 'NONE',
        phone: '',
        role: 'NONE',
        password: '',
        address: '',
        username: '',
      }
    },
    computed: {},
    created() {},
    methods: {
      handleUploadImageResult(image) {
        this.image = image
      },
      addNewAdmin() {
        let form = new FormData()
        form.append('username', this.username)
        form.append('firstname', this.firstname)
        form.append('lastname', this.lastname)
        form.append('email', this.email)
        form.append('password', this.password)
        form.append('role', this.role)
        form.append('address', this.address)
        form.append('gender', this.gender)
        form.append('image', this.image)

        axios.post(`/api/admin/admins`, form)
          .then(response => {
          this.$notify({
              type: 'success',
              title: 'สำเร็จ',
              message: 'เพิ่ม admin สำเร็จ'
            })
          this.$router.push('/admin/admins')
        })
        .catch(error => {
          return this.$notify({
              type: 'error',
              title: 'มีข้อผิดพลาดเกิดขึ้น',
              message: error.response.data.errors.message
            })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
}
</style>
