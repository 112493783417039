var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "admin-section admin-section--banner" }, [
    _c("div", { staticClass: "inner-wrap container item-hover" }, [
      _c("div", { staticClass: "row d-block" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            !_vm.hasBanner
              ? _c("img", {
                  staticClass: "w-100 d-block",
                  attrs: { src: "/imgs/contents/admin-banner.svg" }
                })
              : _vm._l(_vm.banners, function(banner) {
                  return _c("img", {
                    staticClass: "w-100 d-block img-fluid",
                    attrs: { src: banner.url, height: "280" }
                  })
                })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "admin-btns admin-btns--sectionedit" }, [
        _c(
          "a",
          {
            staticClass:
              "ui button white basic d-block mt-1 mb-1 d-xl-flex js-modal-btn",
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.$parent.openModal("#editBannerModal")
              }
            }
          },
          [
            _c("i", { staticClass: "d-none d-xl-block edit outline icon" }),
            _vm._v("แก้ไข\n      ")
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade edit-banner-modal",
        attrs: { tabindex: "-1", role: "dialog", id: "editBannerModal" }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-lg", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: "/api/admin/banners/upload",
                        "list-type": "picture-card",
                        multiple: true,
                        headers: _vm.headers,
                        "file-list": _vm.banners,
                        "before-remove": _vm.beforeRemove
                      }
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(1)
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("p", { staticClass: "modal-title color black h3" }, [
        _vm._v(
          "อัพโหลดแบนเนอร์ไฟล์ .JPG หรือ .PNG (ขนาดแนะนำ 1923 x 954 pixel)"
        )
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "a",
        {
          staticClass: "ui button red basic text",
          attrs: { "data-dismiss": "modal" }
        },
        [_c("span", { staticClass: "ui text large" }, [_vm._v("ยกเลิก")])]
      ),
      _vm._v(" "),
      _c("a", { staticClass: "ui button blue text" }, [
        _c(
          "span",
          { staticClass: "ui text large", attrs: { "data-dismiss": "modal" } },
          [_vm._v("บันทึก")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }