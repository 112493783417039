var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", { attrs: { for: _vm.elementId } }, [
      _c(
        "span",
        {
          staticClass: "avatar avatar-category cursor",
          style: { backgroundImage: "url(" + _vm.previewImage + ")" },
          attrs: { id: "previewImage" }
        },
        [
          _c("i", {
            staticClass: "fas fa-camera",
            class: { "d-none": _vm.hideIcon }
          })
        ]
      )
    ]),
    _vm._v(" "),
    _c("input", {
      staticClass: "d-none",
      attrs: { type: "file", id: _vm.elementId, name: "image" },
      on: { change: _vm.handleUploadImage }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }