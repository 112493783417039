var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "admin-section" },
    [
      _c("div", { staticClass: "inner-wrap container bg white pb-1 pb-lg-0" }, [
        _c("div", { staticClass: "admin-btns admin-btns--sectionedit" }, [
          _c(
            "a",
            {
              staticClass:
                "ui button white basic d-block mt-1 mb-1 d-xl-flex js-modal-btn",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.handleDeleteComponentModal($event)
                }
              }
            },
            [
              _c("i", {
                staticClass: "d-none d-xl-block trash alternate outline icon"
              }),
              _vm._v("ลบ\n      ")
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "admin-btns admin-btns--add-section-top circular ui icon button blue js-modal-btn",
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.$parent.showSectionModal(_vm.id, "top")
              }
            }
          },
          [_c("i", { staticClass: "plus icon" })]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "admin-btns admin-btns--add-section-bottom circular ui icon button blue js-modal-btn",
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.$parent.showSectionModal(_vm.id, "bottom")
              }
            }
          },
          [_c("i", { staticClass: "plus icon" })]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "products-list set-4 row" }, [
          _c("div", { staticClass: "products-list__column col-xl-4" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "item item-hover w-100",
                  class: { show: _vm.productSlot[0] }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "admin-item-btns",
                      on: {
                        click: function($event) {
                          _vm.currentSlot = 0
                        }
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#selectProductSlot-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [_c("i", { staticClass: "plus icon" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#selectProductSlot-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [_c("i", { staticClass: "edit outline icon" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#deleteProduct-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "trash alternate outline icon"
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "product type-e" }, [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "col-6 p-0 product__img" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _vm.productSlot[0]
                            ? _c("img", {
                                attrs: {
                                  src:
                                    "/storage/products/" +
                                    _vm.productSlot[0]["images"][0]["filename"]
                                }
                              })
                            : _c("img", { attrs: { src: "#" } })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6 product__content" }, [
                        _c(
                          "h5",
                          { staticClass: "ui text large bold text-truncate" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.productSlot[0]
                                  ? _vm.productSlot[0].titleTH
                                  : "Enchanted Rose Light"
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "ui text large bold [ color red-2 ] mb-0"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.productSlot[0]
                                    ? _vm.productSlot[0].netPrice
                                    : "0"
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ui text large line-through" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.productSlot[0]
                                    ? _vm.productSlot[0].price
                                    : "0"
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("div", [
                              _vm.productSlot[0]
                                ? _c("a", { staticClass: "ui yellow label" }, [
                                    _c(
                                      "span",
                                      { staticClass: "ui text color red-2" },
                                      [
                                        _vm._v(
                                          "ลด " +
                                            _vm._s(
                                              _vm.productSlot[0].discountPercent
                                            ) +
                                            "%"
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm._m(0)
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "item item-hover w-100",
                  class: { show: _vm.productSlot[1] }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "admin-item-btns",
                      on: {
                        click: function($event) {
                          _vm.currentSlot = 1
                        }
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#selectProductSlot-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [_c("i", { staticClass: "plus icon" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#selectProductSlot-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [_c("i", { staticClass: "edit outline icon" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#deleteProduct-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "trash alternate outline icon"
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "product type-e" }, [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "col-6 p-0 product__img" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _vm.productSlot[1]
                            ? _c("img", {
                                attrs: {
                                  src:
                                    "/storage/products/" +
                                    _vm.productSlot[1]["images"][0]["filename"]
                                }
                              })
                            : _c("img", { attrs: { src: "#" } })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6 product__content" }, [
                        _c(
                          "h5",
                          { staticClass: "ui text large bold text-truncate" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.productSlot[1]
                                  ? _vm.productSlot[1].titleTH
                                  : "Enchanted Rose Light"
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "ui text large bold [ color red-2 ] mb-0"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.productSlot[1]
                                    ? _vm.productSlot[1].netPrice
                                    : "0"
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ui text large line-through" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.productSlot[1]
                                    ? _vm.productSlot[1].price
                                    : "0"
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("div", [
                              _vm.productSlot[1]
                                ? _c("a", { staticClass: "ui yellow label" }, [
                                    _c(
                                      "span",
                                      { staticClass: "ui text color red-2" },
                                      [
                                        _vm._v(
                                          "ลด " +
                                            _vm._s(
                                              _vm.productSlot[1].discountPercent
                                            ) +
                                            "%"
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm._m(1)
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "products-list__column col-xl-4" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "item item-hover w-100",
                  class: { show: _vm.productSlot[2] }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "admin-item-btns",
                      on: {
                        click: function($event) {
                          _vm.currentSlot = 2
                        }
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#selectProductSlot-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [_c("i", { staticClass: "plus icon" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#selectProductSlot-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [_c("i", { staticClass: "edit outline icon" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#deleteProduct-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "trash alternate outline icon"
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "product type-e" }, [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "col-6 p-0 product__img" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _vm.productSlot[2]
                            ? _c("img", {
                                attrs: {
                                  src:
                                    "/storage/products/" +
                                    _vm.productSlot[2]["images"][0]["filename"]
                                }
                              })
                            : _c("img", { attrs: { src: "#" } })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6 product__content" }, [
                        _c(
                          "h5",
                          { staticClass: "ui text large bold text-truncate" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.productSlot[2]
                                  ? _vm.productSlot[2].titleTH
                                  : "Enchanted Rose Light"
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "ui text large bold [ color red-2 ] mb-0"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.productSlot[2]
                                    ? _vm.productSlot[2].netPrice
                                    : "0"
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ui text large line-through" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.productSlot[2]
                                    ? _vm.productSlot[2].price
                                    : "0"
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("div", [
                              _vm.productSlot[2]
                                ? _c("a", { staticClass: "ui yellow label" }, [
                                    _c(
                                      "span",
                                      { staticClass: "ui text color red-2" },
                                      [
                                        _vm._v(
                                          "ลด " +
                                            _vm._s(
                                              _vm.productSlot[2].discountPercent
                                            ) +
                                            "%"
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm._m(2)
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "item item-hover w-100",
                  class: { show: _vm.productSlot[3] }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "admin-item-btns",
                      on: {
                        click: function($event) {
                          _vm.currentSlot = 3
                        }
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#selectProductSlot-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [_c("i", { staticClass: "plus icon" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#selectProductSlot-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [_c("i", { staticClass: "edit outline icon" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#deleteProduct-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "trash alternate outline icon"
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "product type-e" }, [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "col-6 p-0 product__img" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _vm.productSlot[3]
                            ? _c("img", {
                                attrs: {
                                  src:
                                    "/storage/products/" +
                                    _vm.productSlot[3]["images"][0]["filename"]
                                }
                              })
                            : _c("img", { attrs: { src: "#" } })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6 product__content" }, [
                        _c(
                          "h5",
                          { staticClass: "ui text large bold text-truncate" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.productSlot[3]
                                  ? _vm.productSlot[3].titleTH
                                  : "Enchanted Rose Light"
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "ui text large bold [ color red-2 ] mb-0"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.productSlot[3]
                                    ? _vm.productSlot[3].netPrice
                                    : "0"
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ui text large line-through" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.productSlot[3]
                                    ? _vm.productSlot[3].price
                                    : "0"
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("div", [
                              _vm.productSlot[3]
                                ? _c("a", { staticClass: "ui yellow label" }, [
                                    _c(
                                      "span",
                                      { staticClass: "ui text color red-2" },
                                      [
                                        _vm._v(
                                          "ลด " +
                                            _vm._s(
                                              _vm.productSlot[3].discountPercent
                                            ) +
                                            "%"
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm._m(3)
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "products-list__column col-xl-4" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "item item-hover w-100",
                  class: { show: _vm.productSlot[4] }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "admin-item-btns",
                      on: {
                        click: function($event) {
                          _vm.currentSlot = 4
                        }
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#selectProductSlot-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [_c("i", { staticClass: "plus icon" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#selectProductSlot-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [_c("i", { staticClass: "edit outline icon" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#deleteProduct-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "trash alternate outline icon"
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "product type-e" }, [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "col-6 p-0 product__img" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _vm.productSlot[4]
                            ? _c("img", {
                                attrs: {
                                  src:
                                    "/storage/products/" +
                                    _vm.productSlot[4]["images"][0]["filename"]
                                }
                              })
                            : _c("img", { attrs: { src: "#" } })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6 product__content" }, [
                        _c(
                          "h5",
                          { staticClass: "ui text large bold text-truncate" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.productSlot[4]
                                  ? _vm.productSlot[4].titleTH
                                  : "Enchanted Rose Light"
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "ui text large bold [ color red-2 ] mb-0"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.productSlot[4]
                                    ? _vm.productSlot[4].netPrice
                                    : "0"
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ui text large line-through" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.productSlot[4]
                                    ? _vm.productSlot[4].price
                                    : "0"
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("div", [
                              _vm.productSlot[4]
                                ? _c("a", { staticClass: "ui yellow label" }, [
                                    _c(
                                      "span",
                                      { staticClass: "ui text color red-2" },
                                      [
                                        _vm._v(
                                          "ลด " +
                                            _vm._s(
                                              _vm.productSlot[4].discountPercent
                                            ) +
                                            "%"
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm._m(4)
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "item item-hover w-100",
                  class: { show: _vm.productSlot[5] }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "admin-item-btns",
                      on: {
                        click: function($event) {
                          _vm.currentSlot = 5
                        }
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#selectProductSlot-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [_c("i", { staticClass: "plus icon" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#selectProductSlot-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [_c("i", { staticClass: "edit outline icon" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.$parent.openModal(
                                "#deleteProduct-" + _vm.errors.vmId
                              )
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "trash alternate outline icon"
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "product type-e" }, [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "col-6 p-0 product__img" }, [
                        _c("div", { staticClass: "thumb" }, [
                          _vm.productSlot[5]
                            ? _c("img", {
                                attrs: {
                                  src:
                                    "/storage/products/" +
                                    _vm.productSlot[5]["images"][0]["filename"]
                                }
                              })
                            : _c("img", { attrs: { src: "#" } })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6 product__content" }, [
                        _c(
                          "h5",
                          { staticClass: "ui text large bold text-truncate" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.productSlot[5]
                                  ? _vm.productSlot[5].titleTH
                                  : "Enchanted Rose Light"
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "ui text large bold [ color red-2 ] mb-0"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.productSlot[5]
                                    ? _vm.productSlot[5].netPrice
                                    : "0"
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "ui text large line-through" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.productSlot[5]
                                    ? _vm.productSlot[5].price
                                    : "0"
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("div", [
                              _vm.productSlot[5]
                                ? _c("a", { staticClass: "ui yellow label" }, [
                                    _c(
                                      "span",
                                      { staticClass: "ui text color red-2" },
                                      [
                                        _vm._v(
                                          "ลด " +
                                            _vm._s(
                                              _vm.productSlot[5].discountPercent
                                            ) +
                                            "%"
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm._m(5)
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("select-product-slot-modal", {
        attrs: {
          categories: _vm.categories,
          currentSlot: _vm.currentSlot,
          id: "selectProductSlot-" + _vm.errors.vmId
        },
        on: { setProductToSlot: _vm.setProductToSlot }
      }),
      _vm._v(" "),
      _c("delete-product-modal", {
        attrs: {
          currentSlot: _vm.currentSlot,
          id: "deleteProduct-" + _vm.errors.vmId
        },
        on: { deleteProductFromSlot: _vm.deleteProductFromSlot }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-auto" }, [
      _c("div", { staticClass: "d-flex align-items-center ml-auto" }, [
        _c(
          "button",
          { staticClass: "ui circular red icon button d-inline-block p-2" },
          [_c("i", { staticClass: "icon-custom-add-cart large d-block" })]
        ),
        _vm._v(" "),
        _c("button", { staticClass: "ui circular default icon button p-1" }, [
          _c("i", { staticClass: "icon-custom-heart tiny" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-auto" }, [
      _c("div", { staticClass: "d-flex align-items-center ml-auto" }, [
        _c(
          "button",
          { staticClass: "ui circular red icon button d-inline-block p-2" },
          [_c("i", { staticClass: "icon-custom-add-cart large d-block" })]
        ),
        _vm._v(" "),
        _c("button", { staticClass: "ui circular default icon button p-1" }, [
          _c("i", { staticClass: "icon-custom-heart tiny" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-auto" }, [
      _c("div", { staticClass: "d-flex align-items-center ml-auto" }, [
        _c(
          "button",
          { staticClass: "ui circular red icon button d-inline-block p-2" },
          [_c("i", { staticClass: "icon-custom-add-cart large d-block" })]
        ),
        _vm._v(" "),
        _c("button", { staticClass: "ui circular default icon button p-1" }, [
          _c("i", { staticClass: "icon-custom-heart tiny" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-auto" }, [
      _c("div", { staticClass: "d-flex align-items-center ml-auto" }, [
        _c(
          "button",
          { staticClass: "ui circular red icon button d-inline-block p-2" },
          [_c("i", { staticClass: "icon-custom-add-cart large d-block" })]
        ),
        _vm._v(" "),
        _c("button", { staticClass: "ui circular default icon button p-1" }, [
          _c("i", { staticClass: "icon-custom-heart tiny" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-auto" }, [
      _c("div", { staticClass: "d-flex align-items-center ml-auto" }, [
        _c(
          "button",
          { staticClass: "ui circular red icon button d-inline-block p-2" },
          [_c("i", { staticClass: "icon-custom-add-cart large d-block" })]
        ),
        _vm._v(" "),
        _c("button", { staticClass: "ui circular default icon button p-1" }, [
          _c("i", { staticClass: "icon-custom-heart tiny" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-auto" }, [
      _c("div", { staticClass: "d-flex align-items-center ml-auto" }, [
        _c(
          "button",
          { staticClass: "ui circular red icon button d-inline-block p-2" },
          [_c("i", { staticClass: "icon-custom-add-cart large d-block" })]
        ),
        _vm._v(" "),
        _c("button", { staticClass: "ui circular default icon button p-1" }, [
          _c("i", { staticClass: "icon-custom-heart tiny" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }