<template>
  <section class="admin-section">
    <div class="inner-wrap container bg white pb-1 pb-lg-0">
      <div class="admin-btns admin-btns--sectionedit">
        <a
          class="ui button white basic d-block mt-1 mb-1 d-xl-flex js-modal-btn"
          @click.prevent="handleDeleteComponentModal"
        >
          <i class="d-none d-xl-block trash alternate outline icon"></i>ลบ
        </a>
      </div>
      <a
        class="admin-btns admin-btns--add-section-top circular ui icon button blue js-modal-btn"
        @click.prevent="$parent.showSectionModal(id, 'top')"
      >
        <i class="plus icon"></i>
      </a>
      <a
        class="admin-btns admin-btns--add-section-bottom circular ui icon button blue js-modal-btn"
        @click.prevent="$parent.showSectionModal(id, 'bottom')"
      >
        <i class="plus icon"></i>
      </a>
      <div class="products-list set-3 products-type-d row">
        <div class="col-lg-3">
          <div class="item item-hover" :class="{'show': productSlot[0]}">
            <div class="admin-item-btns" @click="currentSlot = 0">
              <a
                class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn"
                @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"
              >
                <i class="plus icon"></i>
              </a>
              <a
                class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn"
                @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"
              >
                <i class="edit outline icon"></i>
              </a>
              <a
                class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn"
                @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"
              >
                <i class="trash alternate outline icon"></i>
              </a>
            </div>
            <div class="product type-d py-2">
              <h5
                class="ui text large bold text-truncate"
              >{{ productSlot[0] ? productSlot[0].titleTH : 'Enchanted Rose Light' }}</h5>
              <div class="thumb">
                <img
                  :src="`/storage/products/${productSlot[0]['images'][0]['filename']}`"
                  v-if="productSlot[0]"
                />
                <img src="#" v-else />
              </div>
              <div class="d-flex align-items-center mt-3">
                <div>
                  <p
                    class="ui text large bold [ color red-2 ] mb-0"
                  >{{ productSlot[0] ? productSlot[0].netPrice : '0' }}</p>
                  <p
                    class="ui text large line-through"
                  >{{ productSlot[0] ? productSlot[0].price : '0' }}</p>
                </div>
                <div class="d-flex align-items-center ml-auto">
                  <button class="ui circular red icon button d-inline-block p-2">
                    <i class="icon-custom-add-cart large d-block"></i>
                  </button>
                  <button class="ui circular default icon button p-1">
                    <i class="icon-custom-heart tiny"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="item item-hover" :class="{'show': productSlot[1]}">
            <div class="admin-item-btns" @click="currentSlot = 1">
              <a
                class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn"
                @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"
              >
                <i class="plus icon"></i>
              </a>
              <a
                class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn"
                @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"
              >
                <i class="edit outline icon"></i>
              </a>
              <a
                class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn"
                @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"
              >
                <i class="trash alternate outline icon"></i>
              </a>
            </div>
            <div class="product type-d py-2">
              <h5
                class="ui text large bold text-truncate"
              >{{ productSlot[1] ? productSlot[1].titleTH : 'Enchanted Rose Light' }}</h5>
              <div class="thumb">
                <img
                  :src="`/storage/products/${productSlot[1]['images'][0]['filename']}`"
                  v-if="productSlot[1]"
                />
                <img src="#" v-else />
              </div>
              <div class="d-flex align-items-center mt-3">
                <div>
                  <p
                    class="ui text large bold [ color red-2 ] mb-0"
                  >{{ productSlot[1] ? productSlot[1].netPrice : '0' }}</p>
                  <p
                    class="ui text large line-through"
                  >{{ productSlot[1] ? productSlot[1].price : '0' }}</p>
                </div>
                <div class="d-flex align-items-center ml-auto">
                  <button class="ui circular red icon button d-inline-block p-2">
                    <i class="icon-custom-add-cart large d-block"></i>
                  </button>
                  <button class="ui circular default icon button p-1">
                    <i class="icon-custom-heart tiny"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="item item-hover" :class="{'show': productSlot[2]}">
            <div class="admin-item-btns" @click="currentSlot = 2">
              <a
                class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn"
                @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"
              >
                <i class="plus icon"></i>
              </a>
              <a
                class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn"
                @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"
              >
                <i class="edit outline icon"></i>
              </a>
              <a
                class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn"
                @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"
              >
                <i class="trash alternate outline icon"></i>
              </a>
            </div>
            <div class="product type-d py-2">
              <h5
                class="ui text large bold text-truncate"
              >{{ productSlot[2] ? productSlot[2].titleTH : 'Enchanted Rose Light' }}</h5>
              <div class="thumb">
                <img
                  :src="`/storage/products/${productSlot[2]['images'][0]['filename']}`"
                  v-if="productSlot[2]"
                />
                <img src="#" v-else />
              </div>
              <div class="d-flex align-items-center mt-3">
                <div>
                  <p
                    class="ui text large bold [ color red-2 ] mb-0"
                  >{{ productSlot[2] ? productSlot[2].netPrice : '0' }}</p>
                  <p
                    class="ui text large line-through"
                  >{{ productSlot[2] ? productSlot[2].price : '0' }}</p>
                </div>
                <div class="d-flex align-items-center ml-auto">
                  <button class="ui circular red icon button d-inline-block p-2">
                    <i class="icon-custom-add-cart large d-block"></i>
                  </button>
                  <button class="ui circular default icon button p-1">
                    <i class="icon-custom-heart tiny"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="item item-hover" :class="{'show': productSlot[3]}">
            <div class="admin-item-btns" @click="currentSlot = 3">
              <a
                class="circular ui icon button white basic mt-1 mb-1 btn-add js-modal-btn"
                @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"
              >
                <i class="plus icon"></i>
              </a>
              <a
                class="circular ui icon button white basic mt-1 mb-1 btn-edit js-modal-btn"
                @click.prevent="$parent.openModal(`#selectProductSlot-${errors.vmId}`)"
              >
                <i class="edit outline icon"></i>
              </a>
              <a
                class="circular ui icon button white basic mt-1 mb-1 btn-remove js-modal-btn"
                @click.prevent="$parent.openModal(`#deleteProduct-${errors.vmId}`)"
              >
                <i class="trash alternate outline icon"></i>
              </a>
            </div>
            <div class="product type-d py-2">
              <h5
                class="ui text large bold text-truncate"
              >{{ productSlot[3] ? productSlot[3].titleTH : 'Enchanted Rose Light' }}</h5>
              <div class="thumb">
                <img
                  :src="`/storage/products/${productSlot[3]['images'][0]['filename']}`"
                  v-if="productSlot[3]"
                />
                <img src="#" v-else />
              </div>
              <div class="d-flex align-items-center mt-3">
                <div>
                  <p
                    class="ui text large bold [ color red-2 ] mb-0"
                  >{{ productSlot[3] ? productSlot[3].netPrice : '0' }}</p>
                  <p
                    class="ui text large line-through"
                  >{{ productSlot[3] ? productSlot[3].price : '0' }}</p>
                </div>
                <div class="d-flex align-items-center ml-auto">
                  <button class="ui circular red icon button d-inline-block p-2">
                    <i class="icon-custom-add-cart large d-block"></i>
                  </button>
                  <button class="ui circular default icon button p-1">
                    <i class="icon-custom-heart tiny"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <select-product-slot-modal
      :categories="categories"
      :currentSlot="currentSlot"
      :id="`selectProductSlot-${errors.vmId}`"
      @setProductToSlot="setProductToSlot"
    ></select-product-slot-modal>
    <delete-product-modal
      :currentSlot="currentSlot"
      :id="`deleteProduct-${errors.vmId}`"
      @deleteProductFromSlot="deleteProductFromSlot"
    ></delete-product-modal>
  </section>
</template>

<script>
import SelectProductSlotModal from "./SelectProductSlotModal";
import DeleteProductModal from "./DeleteProductModal";
import { isNullOrUndefined } from "util";
export default {
  name: "product-list3",
  components: {
    SelectProductSlotModal,
    DeleteProductModal
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      productSlot: new Array(4),
      productSlotIds: new Array(4),
      // categories:[],
      showAddProduct: false,
      currentSlot: 0
    };
  },
  computed: {},
  created() {
    this.getTheme();
    // this.fetchCategoryList()
  },
  methods: {
    fetchCategoryList() {
      axios.get("/api/admin/categories").then(result => {
        this.categories = result.data.data;
        this.showAddProduct = true;
      });
    },
    getTheme() {
      axios.get(`/api/admin/themes/${this.id}`).then(res => {
        if (res.data.products != undefined) {
          this.productSlot = res.data.products;
          this.productSlotIds = this.productSlot.map(p =>
            isNullOrUndefined(p) ? null : p.id
          );
        }
      });
    },
    setProductToSlot(product, slot) {
      this.productSlot[slot] = product;
      this.productSlotIds[slot] = product;
      axios
        .post(`/api/admin/themes/${this.id}`, {
          data: this.productSlotIds,
          _method: "PATCH"
        })
        .then(res => {
          this.$notify.success("เพิ่มสินค้าในเนื้อหาสำเร็จ");
          this.getTheme();
          $(`#selectProductSlot-${this.errors.vmId}`).modal("hide");
        });
      this.showSelectProductDialog = false;
    },
    deleteProductFromSlot() {
      this.productSlot[this.currentSlot] = null;
      this.productSlotIds[this.currentSlot] = null;
      axios
        .post(`/api/admin/themes/${this.id}`, {
          data: this.productSlotIds,
          _method: "PATCH"
        })
        .then(res => {
          this.$notify.success("ลบสินค้าในเนื้อหาสำเร็จ");
          this.getTheme();
          $(`#deleteProduct-${this.errors.vmId}`).modal("hide");
        });
    },
    renderDetail(text) {
      var temporalDivElement = document.createElement("div");
      temporalDivElement.innerHTML = text;
      let result =
        temporalDivElement.textContent || temporalDivElement.innerText || "";
      return result.substr(0, 80);
    },
    handleDeleteComponentModal() {
      this.$emit("onDelete", this.id);
      this.$parent.openModal("#deleteSectionModal");
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
