var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "col-md-2 d-none d-md-block sidebar" }, [
    _c("div", { staticClass: "profile text-white text-center p-3" }, [
      _vm.admin.avatar
        ? _c("span", {
            staticClass: "avatar avatar-xxl",
            style:
              "background-image: url(/storage/avatars/admin/" + _vm.admin.avatar
          })
        : _c("span", { staticClass: "avatar avatar-xxl" }, [_vm._v("A")]),
      _vm._v(" "),
      _c("div", { staticClass: "pt-1 d-flex flex-column" }, [
        _c("span", { staticClass: "text-primary" }, [_vm._v("ยินดีต้อนรับ")]),
        _vm._v(" "),
        _c("span", [
          _vm._v("\n        " + _vm._s(_vm.admin.username) + "\n        "),
          _vm._m(0)
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "sidebar-sticky" }, [
      _c("ul", { staticClass: "nav flex-column" }, [
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                attrs: { to: { name: "admins.index" }, exact: "" }
              },
              [
                _c("i", { staticClass: "fas fa-home mr-1" }),
                _vm._v(" หน้าหลัก\n          "),
                _c("span", { staticClass: "sr-only" }, [_vm._v("(current)")])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                attrs: { to: { name: "settings.banner" } }
              },
              [
                _c("i", { staticClass: "fas fa-file-upload mr-1" }),
                _vm._v(" อัพโหลดโลโก้ แบนเนอร์\n        ")
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                attrs: { to: { name: "themes.index" } }
              },
              [
                _c("i", { staticClass: "fas fa-fill-drip mr-1" }),
                _vm._v(" เลือกธีมสี ธีมเว็ปไซต์\n        ")
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "collapse ml-4", attrs: { id: "products" } },
            [
              _c(
                "router-link",
                {
                  staticClass: "nav-link",
                  attrs: { to: { name: "categories.index" } }
                },
                [_vm._v("หมวดหมู่สินค้า")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "nav-link",
                  attrs: { to: { name: "products.create" } }
                },
                [_vm._v("เพิ่มสินค้า")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "nav-link",
                  attrs: { to: { name: "products.index" }, exact: "" }
                },
                [_vm._v("รายการสินค้า")]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                attrs: { to: { name: "orders.index" } }
              },
              [
                _c("span", { staticClass: "fas fa-box mr-1" }),
                _vm._v(" การสั่งซื้อ\n        ")
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                attrs: { to: { name: "delivery.index" } }
              },
              [
                _c("span", { staticClass: "fas fa-truck mr-1" }),
                _vm._v(" ตั้งค่าการจัดส่ง\n        ")
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                attrs: { to: { name: "contacts.index" } }
              },
              [
                _c("span", { staticClass: "far fa-address-card mr-1" }),
                _vm._v("\n          รายการที่ติดต่่อเข้ามา\n        ")
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                attrs: { to: { name: "users.index" } }
              },
              [
                _c("span", { staticClass: "fas fa-users mr-1" }),
                _vm._v(" ผู้ใช้งาน\n        ")
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              { staticClass: "nav-link", attrs: { to: "/admin/admins" } },
              [
                _c("span", { staticClass: "fas fa-user-shield mr-1" }),
                _vm._v(" ผู้ดูแล\n        ")
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _vm._m(5),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "collapse ml-4", attrs: { id: "settings" } },
            [
              _c(
                "router-link",
                {
                  staticClass: "nav-link",
                  attrs: { to: { name: "settings.website" } }
                },
                [_vm._v("ข้อมูลเว็ปไซต์")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "nav-link",
                  attrs: { to: { name: "settings.payment" } }
                },
                [_vm._v("วิธีการชำระเงิน")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "nav-link",
                  attrs: { to: { name: "settings.privacy" } }
                },
                [_vm._v("นโยบายการใช้งาน")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "nav-link",
                  attrs: { to: { name: "settings.social" } }
                },
                [_vm._v("โซเชียล")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "nav-link",
                  attrs: { to: { name: "settings.contact" } }
                },
                [_vm._v("ติดต่อเรา")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "nav-link",
                  attrs: { to: { name: "settings.address" } }
                },
                [_vm._v("ที่อยู่ในการจัดส่ง")]
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          href: "#editProfile",
          "data-toggle": "modal",
          "data-target": "#editProfile",
          "data-backdrop": "static"
        }
      },
      [_c("i", { staticClass: "fas fa-pen fa-xs" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "nav-link d-flex w-100 align-items-center",
        attrs: { href: "#products", "data-toggle": "collapse" }
      },
      [
        _c("i", { staticClass: "fas fa-store mr-1" }),
        _vm._v(" "),
        _c("span", [_vm._v("สินค้า")]),
        _vm._v(" "),
        _c("i", { staticClass: "ml-auto ion ion-ios-arrow-down" })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item" }, [
      _c("a", { staticClass: "nav-link", attrs: { href: "/admin/contents" } }, [
        _c("span", { staticClass: "fas fa-th-large mr-1" }),
        _vm._v(" ส่วนเนื้อหา\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item" }, [
      _c("a", { staticClass: "nav-link" }, [
        _c("span", { staticClass: "fas fa-bullhorn mr-1" }),
        _vm._v(" จัดการโปรโมชั่น\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item" }, [
      _c("a", { staticClass: "nav-link" }, [
        _c("span", { staticClass: "fab fa-product-hunt mr-1" }),
        _vm._v(" แพ็คเก็จ\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "nav-link d-flex w-100 align-items-center",
        attrs: { href: "#settings", "data-toggle": "collapse" }
      },
      [
        _c("i", { staticClass: "fas fa-cog mr-1" }),
        _vm._v(" "),
        _c("span", [_vm._v("ตั้งค่า")]),
        _vm._v(" "),
        _c("i", { staticClass: "ion ion-ios-arrow-down ml-auto" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }