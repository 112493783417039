<template>
  <div class="container">
    <div class="row no-gutters">
      <div class="col-12">
        <div class="page-header"><h1>ผู้ใช้งาน</h1></div>
        <div class="custom-card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2 col-sm-12 text-center">
                <span
                  class="avatar avatar-xxl"
                  :style="
                    `background-image: url(/storage/users/avatar/${
                      user.avatar
                    })`
                  "
                  v-if="user.avatar"
                ></span>
                <span class="avatar avatar-xxl" v-else>U</span>
              </div>
              <div class="col-md-10 col-sm-12">
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label for="">ชื่อ</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        readonly
                        :value="user.firstname"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label for="">นามสกุล</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        readonly
                        :value="user.lastname"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label for="">วันเกิด</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        readonly
                        :value="user.birthdate"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label for="">เพศ</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        readonly
                        :value="user.gender"
                      />
                    </div>
                  </div>
                  <!-- <div class="col-12">
                    <div class="form-group">
                      <label for="">ที่อยู่ในการออกใบกำกับภาษี</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        readonly
                        :value="user.taxAddress"
                      />
                    </div>
                  </div> -->
                  <div
                    class="col-12"
                    v-for="(address, i) in user.addresses"
                    :key="i"
                  >
                    <div class="form-group">
                      <label for="">ที่อยู่ในการจัดส่ง {{ i + 1 }}</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        readonly
                        :value="address.text"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label for="">เบอร์โทรศัพท์</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        readonly
                        :value="user.phone"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label for="">อีเมล์</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        readonly
                        :value="user.email"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'showUser',
  components: {},
  props: {},
  data() {
    return {
      user: {}
    }
  },
  computed: {},
  created() {
    this.fetchUser()
  },
  methods: {
    fetchUser() {
      axios
        .get(`/api/admin/users/${this.$route.params.id}`)
        .then(res => (this.user = res.data))
    }
  }
}
</script>

<style lang="scss" scoped></style>
