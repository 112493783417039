//internal variables
let options = { name: '', price: 0, discountPrice: 0, quantity: 0 }

const state = {
  skus: []
}

const getters = {
  skus: state => state.skus,
  sku1: state => (state.skus[0] != undefined ? state.skus[0] : []),
  sku2: state => (state.skus[1] != undefined ? state.skus[1] : []),
  enabled: state => state.skus.length > 0
}

const actions = {
  addSku({ commit }) {
    commit('addSku')
  }
}

const mutations = {
  addSku(state) {
    let option = Object.assign({}, options)
    state.skus.push({ name: '', options: [option] })
  },
  setName(state, data) {
    state.skus[data.index]['name'] = data.name
  },
  addOption(state, data) {
    let option = Object.assign({}, options)
    option.name = data.name

    state.skus[data.index]['options'].push(option)
  },
  updateOption(state, data) {
    state.skus[data.index]['options'][data.optionIndex]['name'] = data.name
  },
  deleteOption(state, data) {
    state.skus[data.index].options.splice(data.optionIndex, 1)
  },
  deleteSku(state, index) {
    state.skus.splice(index, 1)
  },
  updateSet(state, data) {
    state.skus.forEach(sku => {
      sku.options.forEach(option => {
        if (data.price != undefined) {
          option.price = data.price
        }
        if (data.discount != undefined) {
          option.discountPrice = data.discount
        }
        if (data.quantity != undefined) {
          option.quantity = data.quantity
        }
      })
    })
  },
  updateSkuOption(state, data) {
    state.skus[0]['options'][data.index][data.name] = data.value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
