<template>
  <div class="modal fade" role="dialog" tabindex="-1" :id="id">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
           <p class="modal-title ui text big color black text-center">สินค้า</p>
           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
             <span aria-hidden="true">&times;</span>
           </button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <i class="far fa-trash-alt color red-1 mb-3" style="font-size: 12rem;"></i>
            <p class="ui text large color black text-center">คุณต้องการลบสินค้าหรือไม่</p>
          </div>
        </div>
        <div class="modal-footer">
          <a class="ui button red basic text" data-dismiss="modal"><span class="ui text large">ยกเลิก</span></a>
          <a class="ui button blue text"><span class="ui text large" @click.prevent="handleDeleteProduct">บันทึก</span></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isNullOrUndefined } from 'util'

export default {
  name: 'deleteProductModal',
  components: {},
  props: {
    id: {
      type: String,
      default: 'deleteProductModal-1'
    },
    currentSlot: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {}
  },
  computed: {},
  created () {
  },
  methods: {
    handleDeleteProduct () {
      this.$emit('deleteProductFromSlot')
      // let data = this.$parent.productSlot.map(p => isNullOrUndefined(p) ? null : p.id)
      // data[this.currentSlot] = null
      // axios.post(`/api/admin/themes/${this.$parent.id}`, {data, _method: 'PATCH'})
      //   .then(res => this.$notify.success('ลบสินค้าออกจากเนื้อหาสำเร็จ'))
      // console.log(this.$parent.themes)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
