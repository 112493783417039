var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "custom-card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "row" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-8 col-sm-12" }, [
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search,
                        expression: "search"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.search },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.search = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group-append" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-gradient-primary",
                        on: { click: _vm.fetchOrders }
                      },
                      [_c("i", { staticClass: "fas fa-search" })]
                    )
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "table-responsive" },
              [
                _c(
                  "table",
                  { staticClass: "table table-center table-bordered" },
                  [
                    _c("thead", [
                      _c("tr", { staticClass: "bg-light" }, [
                        _c(
                          "th",
                          {
                            staticClass: "text-center",
                            staticStyle: {
                              "border-right-color": "transparent",
                              "vertical-align": "middle"
                            }
                          },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.selectAll,
                                callback: function($$v) {
                                  _vm.selectAll = $$v
                                },
                                expression: "selectAll"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          { staticClass: "text-left", attrs: { colspan: "8" } },
                          [
                            _c("div", { staticClass: "form-inline" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-outline-danger btn-sm",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.deleteManyOrder($event)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "far fa-trash-alt mr-1"
                                  }),
                                  _vm._v("ลบ")
                                ]
                              )
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm._m(2)
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.orders.data, function(order) {
                        return _c(
                          "tr",
                          { key: order.id, staticClass: "text-center" },
                          [
                            _c("td", { staticClass: "text-center" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedOrder,
                                    expression: "selectedOrder"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: order.id,
                                  checked: Array.isArray(_vm.selectedOrder)
                                    ? _vm._i(_vm.selectedOrder, order.id) > -1
                                    : _vm.selectedOrder
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.selectedOrder,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = order.id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.selectedOrder = $$a.concat([
                                            $$v
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.selectedOrder = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.selectedOrder = $$c
                                    }
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(order.orderNO))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(order.fullname))]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm
                                        .$moment(order.createdAt)
                                        .format("DD/MMMM/YYYY HH:mm:ss"),
                                      expression:
                                        "$moment(order.createdAt).format('DD/MMMM/YYYY HH:mm:ss')"
                                    }
                                  ]
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$moment(order.createdAt).fromNow()
                                    )
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  staticClass: "el-dropdown-link btn btn-sm",
                                  class: [
                                    order.payStatus != "ACCEPT"
                                      ? "btn-danger"
                                      : "btn-success"
                                  ]
                                },
                                [
                                  order.payStatus != "ACCEPT"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                          ยังไม่ชำระ\n                        "
                                        )
                                      ])
                                    : _c("span", {}, [_vm._v("ชำระแล้ว")])
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  staticClass: "el-dropdown-link btn btn-sm",
                                  class: _vm.setStatusBtnClass(order.status)
                                },
                                [
                                  order.status == "CREATE"
                                    ? _c("span", [_vm._v("รอดำเนินการ")])
                                    : order.status == "ACCEPT"
                                    ? _c("span", [_vm._v("พร้อมจัดส่ง")])
                                    : order.status == "SHIPPING"
                                    ? _c("span", [_vm._v("กำลังจัดส่ง")])
                                    : order.status == "FINISH"
                                    ? _c("span", [_vm._v("เสร็จสิ้น")])
                                    : order.status == "CANCEL"
                                    ? _c("span", [_vm._v("ยกเลิก")])
                                    : _c("span", [_vm._v("สถานะการจัดส่ง")])
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "btn btn-primary btn-sm",
                                    attrs: {
                                      to: {
                                        name: "orders.show",
                                        params: { id: order.id }
                                      }
                                    }
                                  },
                                  [_vm._v("รายละเอียด")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _vm._v(" "),
                _c("pagination", {
                  staticClass: "justify-content-end",
                  attrs: { data: _vm.orders },
                  on: { "pagination-change-page": _vm.fetchOrders }
                })
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header mb-2" }, [
      _c("h1", [_vm._v("การสั่งซื้อ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4 col-sm-12" }, [
      _c("select", { staticClass: "custom-select" }, [
        _c("option", [_vm._v("เลขที่ใบสั่งซื้อ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "bg-light text-center" }, [
      _c("th"),
      _vm._v(" "),
      _c("th", [_vm._v("เลขที่ออเดอร์")]),
      _vm._v(" "),
      _c("th", [_vm._v("ชื่อ - สกุล")]),
      _vm._v(" "),
      _c("th", [_vm._v("วันที่สั่งซื้อ")]),
      _vm._v(" "),
      _c("th", [_vm._v("สถานะการชำระเงิน")]),
      _vm._v(" "),
      _c("th", [_vm._v("สถานะ")]),
      _vm._v(" "),
      _c("th", [_vm._v("รายละเอียด")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }