var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    class: { SkeletonBox: true, "no-animate": !_vm.animated },
    style: {
      height: _vm.height,
      width: _vm.computedWidth,
      borderRadius: "" + _vm.rounded
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }