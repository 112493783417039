<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-white p-0 shadow-sm">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto align-items-center">
          <li class="nav-item">
            <div class="d-flex flex-column h-100 text-right" :style="'line-height:17px;'">
              <div class="text-primary">แพ็คเก็จของคุณ</div>
              <div>{{package.name}} / {{(package.expiry_date == 30)? 'รายเดือน' : 'รายปี' }}</div>
              <div
                :class="[ package.expired_day > 4  ? 'text-success' : 'text-danger']"
              >เหลือ {{package.expired_day}} วัน</div>
            </div>
          </li>
          <li class="nav-item ml-2 bg-dark">
            <a
              class="d-flex flex-column h-100 sign-out px-2 py-1 text-white"
              href="#"
              @click="logout"
            >
              <div class="text-center">
                <i class="fas fa-sign-out-alt"></i>
              </div>
              <div>ออกจากระบบ</div>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "navbar",
  components: {},
  props: {},
  data() {
    return {
      package: {
        name: "",
        expiry_date: 30
      }
    };
  },
  computed: {},
  created() {
    axios
      .get("/api/admin/package")
      .then(res => {
        this.package = res.data.data.data;
      })
      .catch(error => {});
  },
  methods: {
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => (location.href = "/admin/login"));
    }
  }
};
</script>

<style lang="scss" scoped>
.sign-out {
  text-decoration: none;
}
</style>
