<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header mb-2">
          <h1>การสั่งซื้อ</h1>
        </div>
        <div class="custom-card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <select class="custom-select">
                  <option>เลขที่ใบสั่งซื้อ</option>
                </select>
              </div>
              <div class="col-md-8 col-sm-12">
                <div class="input-group">
                  <input v-model="search" type="text" class="form-control">
                  <div class="input-group-append">
                    <a @click="fetchOrders" class="btn btn-gradient-primary"><i class="fas fa-search"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-center table-bordered">
                <thead>
                  <tr class="bg-light">
                    <th style="border-right-color: transparent; vertical-align:middle;" class="text-center">
                      <el-checkbox v-model="selectAll"></el-checkbox>
                    </th>
                    <th colspan="8" class="text-left">
                      <div class="form-inline">
                        <a @click.prevent="deleteManyOrder" class="btn btn-outline-danger btn-sm"><i class="far fa-trash-alt mr-1"></i>ลบ</a>
                        <!-- <select name="" id="" class="custom-select custom-select-sm mx-1">
                          <option value="">รอดำเนินการ</option>
                          <option value="">พร้อมจัดส่ง</option>
                          <option value="">กำลังจัดส่ง</option>
                          <option value="">เสร็จสิ้น</option>
                        </select>
                        <select name="" id="" class="custom-select custom-select-sm">
                          <option value="">รอการชำระ</option>
                          <option value="">ชำระแล้ว</option>
                        </select> -->
                      </div>
                    </th>
                  </tr>
                  <tr class="bg-light text-center">
                    <th></th>
                    <th>เลขที่ออเดอร์</th>
                    <th>ชื่อ - สกุล</th>
                    <th>วันที่สั่งซื้อ</th>
                    <th>สถานะการชำระเงิน</th>
                    <th>สถานะ</th>
                    <th>รายละเอียด</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="order in orders.data" :key="order.id" class="text-center">
                    <td class="text-center">
                      <input type="checkbox" v-model="selectedOrder" :value="order.id" />
                    </td>
                    <td>{{ order.orderNO }}</td>
                    <td>{{ order.fullname }}</td>
                    <td>
                      <span v-tooltip="$moment(order.createdAt).format('DD/MMMM/YYYY HH:mm:ss')">{{ $moment(order.createdAt).fromNow() }}</span>
                    </td>
                    <td>
                        <span class="el-dropdown-link btn btn-sm" :class="[(order.payStatus != 'ACCEPT') ? 'btn-danger' : 'btn-success']">
                          <span v-if="order.payStatus != 'ACCEPT'">
                            ยังไม่ชำระ
                          </span>
                          <span v-else class="">ชำระแล้ว</span>
                        </span>
                    </td>
                    <td>
                        <span class="el-dropdown-link btn btn-sm" :class="setStatusBtnClass(order.status)">
                          <span v-if="order.status == 'CREATE'">รอดำเนินการ</span>
                          <span v-else-if="order.status == 'ACCEPT'">พร้อมจัดส่ง</span>
                          <span v-else-if="order.status == 'SHIPPING'">กำลังจัดส่ง</span>
                          <span v-else-if="order.status == 'FINISH'">เสร็จสิ้น</span>
                          <span v-else-if="order.status == 'CANCEL'">ยกเลิก</span>
                          <span v-else>สถานะการจัดส่ง</span>
                        </span>
                        <!-- <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="CREATE">รอดำเนินการ</el-dropdown-item>
                          <el-dropdown-item command="ACCEPT">พร้อมจัดส่ง</el-dropdown-item>
                          <el-dropdown-item command="SHIPPING">กำลังจัดส่ง</el-dropdown-item>
                          <el-dropdown-item command="FINISH">เสร็จสิ้น</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </td>
                    <td>
                      <router-link :to="{name: 'orders.show', params: {id: order.id}}" class="btn btn-primary btn-sm">รายละเอียด</router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
              <pagination :data="orders" @pagination-change-page="fetchOrders" class="justify-content-end"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'order',
  components: {
  },
  props: {},
  data () {
    return {
      filter: '',
      search: '',
      orders: {},
      selectedOrder:[],
    }
  },
  computed: {
    selectAll: {
        get: function() {
          return this.orders.data
            ? this.selectedOrder.length == this.orders.data.length &&
                this.selectedOrder.length > 0
            : false
        },
        set: function(value) {
          var selected = []

          if (value) {
            this.orders.data.forEach(function(order) {
              selected.push(order.id)
            })
          }
          return (this.selectedOrder = selected)
        }
      }
  },
  created () {
    this.fetchOrders()
  },
  methods: {
    fetchOrders (page = 1) {
      axios.get('/api/admin/orders?page='+page+ '&search='+ this.search).then(res => this.orders = res.data)

    },
    handleUpdateStatus (status, order) {
      axios.patch(`/api/admin/orders/${order.id}`, {
        _method: 'PATCH',
        status
      }).then(res => {
        order.status = status
        this.$notify.success('แก้ไขสถานะออเดอร์สำเร็จ')
      }).catch(err => {
        if(err.response.status >= 400) {
          this.$notify.error(err.response.data.message)
        }
      })
    },
    handleUpdatePaymentStatus (status, payment) {
      if (payment != undefined) {
        axios.post(`/api/admin/payments/${payment.id}`, {
          _method: 'PATCH',
          status
        }).then(res => {
          payment.status = status
          this.$notify.success('แก้ไขสถานะการชำระเงินสำเร็จ')
        }).catch(err => {
          if (err.response.status >= 400) {
            this.$notify.error(err.response.data.message)
          }
        })
      }

    },
    setStatusBtnClass (status) {
      switch (status) {
        case 'CREATE':
        case 'ACCEPT':
          return {'btn-danger': true}
          break
        case 'SHIPPING':
          return {'btn-warning': true}
          break
        case 'FINISH':
          return {'btn-success': true}
          break
        default:
          return {'btn-danger': true}
          break
      }
    },
    deleteManyOrder(){
      if (this.selectedOrder.length > 0) {
          this.$confirm(`ต้องการลบออเดอร์จำนวน ${
              this.selectedOrder.length
            } รายการ ใช่หรือไม่`, 'ลบ', {
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            dangerouslyUseHTMLString: true,
            type: 'warning'
            }).then(() => {
          // }).then(confirm => {
            if (confirm) {
              let form = new FormData()
              form.append('_method', 'DELETE')
              this.selectedOrder.forEach(id => form.append('orders[]', id))
              axios.post('/api/admin/orders', form).then(res => {
                this.fetchOrders()
                this.$notify.success({
                  title: 'Success',
                  message: 'ลบสำเร็จ'
                })
              })
            }
          })
        }
    }
  }
}
</script>

<style lang="scss" scoped>
  .dropdown .dropdown-menu {
    animation-name: _fade;
    animation-duration: .5s;
  }
  @keyframes _fade {
    from {
      opacity: .2;
    }
    to {
      opacity: 1;
    }
  }
</style>
