<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header"><h1>โซเชียล</h1></div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="card-title">เพิ่ม URL ร้านค้า</h4>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label class="d-flex align-items-center">
                <i
                  class="fab fa-facebook fa-2x mr-2"
                  style="color: #3b5999;"
                ></i>
                <span>URL Facebook</span>
                <small class="text-muted">
                  (เช่น www.facebook.com/store.me)</small
                >
              </label>
              <input
                type="text"
                class="form-control form-control-lg"
                v-model="facebook"
              />
            </div>
            <div class="form-group">
              <label class="d-flex align-items-center">
                <i
                  class="fab fa-instagram fa-2x mr-2"
                  style="color: #e4405f;"
                ></i>
                <span>URL Instagram</span>
                <small class="text-muted align-self-center">
                  (เช่น www.instagram.com/store.me)</small
                >
              </label>
              <input class="form-control form-control-lg" v-model="instagram" />
            </div>
            <div class="form-group">
              <label class="d-flex align-items-center">
                <i class="fab fa-line fa-2x mr-2" style="color: #00c300;"></i>
                <span>ID Line</span>
              </label>
              <input class="form-control form-control-lg" v-model="line" />
            </div>
          </div>
        </div>
        <div class="mt-4 text-center">
          <a
            class="btn  btn-lg btn-gradient-primary"
            href="#"
            role="button"
            @click.prevent="updateSiteSetting"
            >บันทึก</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'socialSetting',
  components: {},
  props: {},
  data() {
    return {
      facebook: '',
      instagram: '',
      line: ''
    }
  },
  computed: {},
  created() {
    this.fetchAppData()
  },
  methods: {
    fetchAppData() {
      axios.get('/api/admin/applications').then(res => {
        this.facebook = res.data.data.facebook
        this.instagram = res.data.data.instagram
        this.line = res.data.data.line
      })
    },
    updateSiteSetting() {
      let form = new FormData()
      form.append('facebook', this.facebook)
      form.append('instagram', this.instagram)
      form.append('line', this.line)
      form.append('_method', 'patch')
      axios
        .post('/api/admin/applications', form)
        .then(res => this.fetchAppData())
    }
  }
}
</script>

<style lang="scss" scoped></style>
