var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "custom-card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h4", { staticClass: "card-title text-primary" }, [
              _vm._v("รายการสั่งซื้อ " + _vm._s(_vm.order.orderNO))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-5 form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("ชื่อ - สกุล")]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { type: "text", disabled: "" },
                    model: {
                      value: _vm.order.fullname,
                      callback: function($$v) {
                        _vm.$set(_vm.order, "fullname", $$v)
                      },
                      expression: "order.fullname"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-5 form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("เบอร์โทรศัพท์")
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { type: "text", disabled: "" },
                    model: {
                      value: _vm.order.phoneNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.order, "phoneNumber", $$v)
                      },
                      expression: "order.phoneNumber"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2 form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("สถานะ")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticClass: "order-select",
                      attrs: {
                        value: _vm.order.status,
                        placeholder: "กรุณาเลือก"
                      },
                      on: { change: _vm.updateOrderStatus }
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          disabled: _vm.order.status != "CREATE",
                          value: "CREATE",
                          label: "รอดำเนินการ"
                        }
                      }),
                      _vm._v(" "),
                      _vm.order.deliveryType == "carrier"
                        ? _c("el-option", {
                            attrs: {
                              disabled:
                                _vm.order.payStatus == "CREATE" ||
                                _vm.order.status != "CREATE",
                              value: "ACCEPT",
                              label: "พร้อมจัดส่ง"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.order.deliveryType == "carrier"
                        ? _c("el-option", {
                            attrs: {
                              disabled:
                                _vm.order.payStatus == "CREATE" ||
                                _vm.order.status != "ACCEPT",
                              value: "SHIPPING",
                              label: "กำลังจัดส่ง"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.order.deliveryType == "carrier"
                        ? _c("el-option", {
                            attrs: {
                              disabled:
                                _vm.order.payStatus == "CREATE" ||
                                _vm.order.status != "SHIPPING",
                              value: "FINISH",
                              label: "เสร็จสิ้น"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.order.deliveryType == "atStore"
                        ? _c("el-option", {
                            attrs: {
                              disabled: _vm.order.status != "CREATE",
                              value: "FINISH",
                              label: "เสร็จสิ้น"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: {
                          disabled: _vm.order.status != "CREATE",
                          value: "CANCEL",
                          label: "ยกเลิก"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.order.deliveryType == "carrier"
                ? _c(
                    "div",
                    { staticClass: "col-md-5 form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("ที่อยู่ในการจัดส่ง")
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 3,
                          resize: "none",
                          disabled: ""
                        },
                        model: {
                          value: _vm.addressTo,
                          callback: function($$v) {
                            _vm.addressTo = $$v
                          },
                          expression: "addressTo"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-5 form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("ข้อความ(ยกเลิก)")
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      resize: "none",
                      disabled: ""
                    },
                    model: {
                      value: _vm.order.detail,
                      callback: function($$v) {
                        _vm.$set(_vm.order, "detail", $$v)
                      },
                      expression: "order.detail"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.order.deliveryType == "carrier" &&
              _vm.order.status != "CREATE" &&
              _vm.order.status != "CANCEL"
                ? _c(
                    "div",
                    { staticClass: "col-md-2 form-group text-center" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("พิมพ์ใบปะหน้า")
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "pointer",
                          attrs: { target: "_bank", href: _vm.order.print }
                        },
                        [
                          _c("img", {
                            staticClass: "img-fluid",
                            attrs: {
                              src:
                                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAHNQAABzUB3AtqBwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAOVSURBVHic7d3LaxNRFAbw70ybVBERC65EBSX4QrFQwewE0YWIgoILl7roQ3z+Fa50V7Molm4FkdKlG3cibakPIqhFaUG704gWm6SZ4yKiyUyCMnXuPTbfb3kn3Dnk4+bO7e3MAERERMlIWh33F95qWn1bMD2YS+W7C9LolJJjIMYwEGO6XZ0ord9cV1zNiRwhxjAQYxiIMQzEGAZiDAMxhoEYw0CMYSDGMBBjGIgxDMQYBmIMAzGGgRjDQIxhIMYwEGMYiDEMxBgGYkzb/wTZf7+YXfcpc1EglwTYpcBml4WtNQJ8VtU5CYLREKWxmYH+apvPxeXvvt5alWASQF+qVXYoEcysVGqnZ6/s+Rg7Fm04OvZ+3bdK9SlUDropr2PNlirIz13NlRsbY3PIt+WVIYbhRN+mHh2INsYndcEFJ+UQRCX2Xbe6ytrroBaq2xdtaBXIBgeFUN3GaAPXIcYwEGMYiDEMZHUWBHpOJdyiEm4JVM5CMLeaDp3dsLMGLZTD7KGXwzs+N7Q9PDAy/7gnqDwHsC1JpxwhCQn0RiQMAMDPtptJ+2UgCWUz4aN2x1SCtsf+hIEkVFqutb3nsLscJr4fkYEktL4re6zdsVoWJ5L2y0CSu3VgZD62R5QfLfYCuJ20U15lJbenJ6g8O1x4cyObCR+tLHVJLaPHqytyBwmvsAAGslrbFfKgXO0CssC/eHQMf7KMYSDGMBBjGIgxDMQYBmIMAzGGgRjDQIxhIMYwEGP4zMW/xGcudigGYgwDMYaBGMNAjPG+Y9hfeHsG9T3onZ5LeSfA9anB3KTPIvyOEFUB5C78hwEAO1VRqNfkj99ARBSiFa81NApQhYjX9574n0MU1wBZ9F0GIIuiuOK7Cu9zyPRgbgLAhO86rPA/QqhJq0CWnFfRub5GG+KBqL5yUgpBgGK0LT6HBDIOxWEnFcHpOsTEOqNRCB2PtsVGSKmMUYi+cFKR23WIiXVGg9nl3uq9aGMskLmruXIm1JMAZlMvSUQVGqZ+nl/nQ+h7nQHUHz5Tq9ZOFc/vj63BWl5lPRna/eF7b+WIQocATAMot/rcPykOuOxmHSKLAgynf562ylCdEpGBEF/yrZ4EBDh80+da2zHkmz47BAMxhoEY4/1vWdwPacb9kN9MrFO4H9KI+yHgfkiE9zmE+yHN/I8QasJAjGEgxjAQYxiIMc6uslzdX/G/4wgxhoEYw0CIiIjWiB8rUvfKP33DGwAAAABJRU5ErkJggg=="
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "custom-card mt-5" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-bordered mb-0" }, [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.order.products, function(product) {
                      return _c("tr", { key: product.id }, [
                        _c("td", { staticClass: "align-middle text-center" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: product.image,
                                "data-lightbox": "product-" + product.id
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: product.image,
                                  width: "48",
                                  height: "48"
                                }
                              })
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(_vm._s(product.title))
                          ]),
                          _vm._v(" "),
                          product.sku != null
                            ? _c("span", {}, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      product.sku.sku1Name != null
                                        ? product.sku.sku1Name + " : "
                                        : ""
                                    ) +
                                    " " +
                                    _vm._s(
                                      product.sku.skuSelect1 != null
                                        ? product.sku.skuSelect1
                                        : ""
                                    ) +
                                    " \n                        " +
                                    _vm._s(
                                      product.sku.sku2Name != null
                                        ? ", " + product.sku.sku2Name + " : "
                                        : ""
                                    ) +
                                    " " +
                                    _vm._s(
                                      product.sku.skuSelect2 != null
                                        ? product.sku.skuSelect2
                                        : ""
                                    ) +
                                    " \n                      "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(_vm._s(product.amount))
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              product.netPrice
                                ? product.netPrice.toLocaleString()
                                : "-"
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              product.total
                                ? product.total.toLocaleString()
                                : ""
                            )
                          )
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "text-right bg-light border-bottom-0",
                          attrs: { colspan: "4" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.order.shippingName) +
                              " (ราคาขนส่ง " +
                              _vm._s(_vm.order.shippingFeeCheck) +
                              " )"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right bg-light border-bottom-0" },
                        [
                          _c("span", {}, [
                            _vm._v(_vm._s(_vm.order.shippingFee))
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", {
                        staticClass: "border-0",
                        attrs: { colspan: "4" }
                      }),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right bg-light border-bottom-0" },
                        [
                          _vm._v(" ยอดรวม "),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              _vm._s(
                                _vm.order.totalPrice
                                  ? _vm.order.totalPrice.toLocaleString()
                                  : ""
                              )
                            )
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "custom-card mt-5 mb-5" }, [
          _vm._m(3),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-bordered" }, [
                _vm._m(4),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", { staticClass: "text-center" }, [
                    _vm.order.payType == "TRANFER"
                      ? _c("td", { staticClass: "text-center" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.order.transferSlip,
                                target: "_bank",
                                "data-lightbox": "payments"
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "img-fluid",
                                attrs: {
                                  src: _vm.order.transferSlip,
                                  width: "100"
                                }
                              })
                            ]
                          )
                        ])
                      : _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n                    Online\n                  "
                          )
                        ]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm
                                .$moment(_vm.order.createdAt)
                                .format("DD/MMMM/YYYY HH:mm:ss"),
                              expression:
                                "$moment(order.createdAt).format('DD/MMMM/YYYY HH:mm:ss')"
                            }
                          ]
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$moment(_vm.order.createdAt).fromNow())
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm.order.admin != null
                      ? _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.order.admin) +
                              "\n                  "
                          )
                        ])
                      : _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n                    ไม่มี\n                  "
                          )
                        ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticStyle: { width: "205px" } },
                      [
                        _vm.order.payType == "TRANFER"
                          ? _c(
                              "el-select",
                              {
                                attrs: { placeholder: "สถานะการชำระเงิน" },
                                on: {
                                  change: function($event) {
                                    _vm.updatePayStatus()
                                  }
                                },
                                model: {
                                  value: _vm.payStatus,
                                  callback: function($$v) {
                                    _vm.payStatus = $$v
                                  },
                                  expression: "payStatus"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    value: "CREATE",
                                    label: "รอการอนุมัติ"
                                  }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { value: "ACCEPT", label: "อนุมัติ" }
                                })
                              ],
                              1
                            )
                          : _c(
                              "el-select",
                              {
                                staticClass: "text-success",
                                attrs: { placeholder: "สถานะการชำระเงิน" }
                              },
                              [
                                _c("el-option", {
                                  attrs: { value: "ACCEPT", label: "อนุมัติ" }
                                })
                              ],
                              1
                            )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "cancelModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "exampleModalLabel",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(5),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12 form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("ข้อความ(ยกเลิก)")
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { type: "textarea", rows: 3, resize: "none" },
                      model: {
                        value: _vm.order.detail,
                        callback: function($$v) {
                          _vm.$set(_vm.order, "detail", $$v)
                        },
                        expression: "order.detail"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" }
                  },
                  [_vm._v("ยกเลิก")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        _vm.cancelOrder()
                      }
                    }
                  },
                  [_vm._v("บันทึก")]
                )
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h1", [_vm._v("รายละเอียดการสั่งซื้อ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title text-primary" }, [
        _vm._v("รายละเอียดสินค้า")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "bg-light text-center" }, [
      _c("tr", [
        _c(
          "th",
          { staticClass: "align-middle text-center", attrs: { width: "5%" } },
          [_vm._v("รูปสินค้า")]
        ),
        _vm._v(" "),
        _c("th", { attrs: { width: "20%" } }, [_vm._v("ชื่อสินค้า")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "5%" } }, [_vm._v("จำนวน")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { width: "5%" } }, [
          _vm._v("ราคา")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { width: "10%" } }, [
          _vm._v("ราคารวม")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title text-primary" }, [
        _vm._v("รายละเอียดการชำระเงิน")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "bg-light" }, [
      _c("tr", { staticClass: "text-center" }, [
        _c("th", [_vm._v("ประเภทการชำระ")]),
        _vm._v(" "),
        _c("th", [_vm._v("วันที่ชำระ")]),
        _vm._v(" "),
        _c("th", [_vm._v("ผู้อนุมัติ")]),
        _vm._v(" "),
        _c("th", [_vm._v("สถานะ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
        [_vm._v("Cancel order")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }