<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header">
          <h1>รายการที่ติดต่อเข้ามา</h1>
        </div>
        <div class="table-responsive">
          <table class="table table-center table-bordered">
            <thead>
              <tr class="bg-light">
                <th class="border-right-0">
                  <input type="checkbox" v-model="selectAll" />
                </th>
                <th colspan="6" class="border-left-0">
                  <a href="#" class="btn btn-danger btn-sm" @click.prevent="deleteManyContact"><i class="far fa-trash-alt mr-1"></i>ลบ</a>
                </th>
              </tr>
              <tr class="bg-light">
                <th></th>
                <th>ชื่อเรื่อง</th>
                <th>ชื่อ-นามสกุล</th>
                <th width="10%">อีเมล์</th>
                <th width="10%">เบอร์โทรศัพท์</th>
                <th width="10%">วันที่</th>
                <th width="10%">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="contact in contacts.data" :key="contact.id">
                <td>
                  <input type="checkbox" v-model="selectedContact" :value="contact.id" />
                </td>
                <td>{{ contact.title }}</td>
                <td>{{ contact.fullname }}</td>
                <td>{{ contact.email }}</td>
                <td>{{ contact.phone }}</td>
                <td>
                  <span v-tooltip="$moment(contact.created_at).format('DD/MMMM/YYYY HH:mm:ss')">{{ $moment(contact.created_at).fromNow() }}</span>
                </td>
                <td class="text-center">
                  <div class="dropdown">
                    <a href="#" class="link-unstyled border-0" data-toggle="dropdown">
                      <span class="avatar avatar-sm"><i class="fas fa-ellipsis-h"></i></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#contactDetail" data-toggle="modal" data-target="#contactDetail" @click="setSelectContact(contact)" class="dropdown-item text-primary">
                        <i class="far fa-eye mr-1"></i>ดู
                      </a>
                      <a href="#" class="dropdown-item text-danger" @click.prevent="deleteContact(contact.id)"><i class="far fa-trash-alt mr-1"></i>ลบ</a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination :data="contacts" @pagination-change-page="fetchContactList" class="justify-content-end"></pagination>
        </div>
      </div>
    </div>
    <div class="modal fade" id="contactDetail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h5 class="modal-title" id="exampleModalLabel">
              <b class="text-dark">เรื่อง</b> {{ selectContact.title }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ selectContact.detail }}</p>
            <dl class="row">
              <dt class="col-sm-3 text-dark">ผู้ติดต่อ</dt>
              <dd class="col-sm-9">{{ selectContact.fullname }}</dd>
              <dt class="col-sm-3 text-dark">อีเมล์</dt>
              <dd class="col-sm-9">{{ selectContact.email }}</dd>
              <dt class="col-sm-3 text-dark">เบอร์โทรศัพท์</dt>
              <dd class="col-sm-9">{{ selectContact.phone }}</dd>
              <dt class="col-sm-3 text-dark">วันที่</dt>
              <dd class="col-sm-9">{{ selectContact.created_at }}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'contact',
    components: {},
    props: {},
    data() {
      return {
        contacts: {},
        selectContact: {},
        selectedContact: []
      }
    },
    computed: {
      selectAll: {
        get: function() {
          return this.contacts.data
            ? this.selectedContact.length == this.contacts.data.length
            : false
        },
        set: function(value) {
          var selected = []

          if (value) {
            this.contacts.data.forEach(function(user) {
              selected.push(user.id)
            })
          }
          return (this.selectedContact = selected)
        }
      }
    },
    created() {
      this.fetchContactList()
    },
    methods: {
      fetchContactList() {
        axios.get(`/api/admin/contacts`).then(res => {
          this.contacts = res.data
          
        })
      },
      setSelectContact(contact) {
        this.selectContact = contact
      },
      deleteContact(contactId) {
        axios
          .delete(`/api/admin/contacts/${contactId}`)
          .then(res => {
            this.fetchContactList()
          })
      },
      deleteManyContact() {
        let form = new FormData()
        form.append('_method', 'DELETE')
        this.selectedContact.forEach(contact =>
          form.append('contacts[]', contact)
        )
        axios
          .post('/api/admin/contacts', form)
          .then(res => this.fetchContactList())
      }
    }
  }
</script>

<style lang="scss" scoped></style>
