<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-header">
          <h1>รายการสินค้า</h1>
        </div>
        <div class="custom-card mt-3">
          <div class="card-header">
            <div class="row">
              <div class="col-6 mx-auto">
                <form class="input-group">
                  <input type="text" class="form-control" placeholder="ค้นหาสินค้า" v-model="search" />
                  <div class="input-group-append">
                    <button class="btn btn-primary" @click.prevent="searchProduct">
                      <i class="fas fa-search"></i>
                    </button>
                    <button class="btn btn-danger" @click.prevent="resetSearch">
                      <i class="fas fa-redo mr-1"></i>ล้างค่า
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-center table-bordered">
                <thead>
                  <tr class="bg-light">
                    <th style="border-right-color: transparent; vertical-align:middle;" class="text-center">
                      <input type="checkbox" v-model="selectAll" />
                    </th>
                    <th colspan="8" class="text-left">
                      <div class="d-flex">
                        <div>
                          <a href="#" class="btn btn-outline-danger btn-sm mr-3" @click.prevent="deleteManyProduct">
                            <i class="far fa-trash-alt mr-1"></i>ลบ
                          </a>
                        </div>
                        <div>
                          <select class="custom-select custom-select-sm" @change="toggleEnableManyProduct">
                            <option selected disabled>กรุณาเลือก</option>
                            <option :value="true">แสดงสินค้า</option>
                            <option :value="false">ไม่แสดงสินค้า</option>
                          </select>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr class="bg-light text-center">
                    <th></th>
                    <th>รูปสินค้า</th>
                    <th>ชื่อสินค้า</th>
                    <th>ราคาขาย (บาท)</th>
                    <th>ราคาปกติ (บาท)</th>
                    <th>ส่วนลด</th>
                    <th>จำนวนสต๊อค</th>
                    <th>สถานะสินค้า</th>
                    <th>จัดการ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="product in laravelData.data" :key="product.id" class="text-center">
                    <td class="text-center">
                      <input type="checkbox" v-model="selectedProduct" :value="product.id" />
                    </td>
                    <td>
                      <span class="avatar avatar-md" :style="
                          `background-image: url(${product.images[0].url})`
                        " v-if="product.images.length > 0"></span>
                      <span class="avatar avatar-md" v-else>P</span>
                    </td>
                    <td>{{ product.titleTH }}</td>
                    <td class="text-success">{{ product.netPrice.toLocaleString() }}</td>
                    <td>
                      <s class="text-dark">
                        <span class="text-danger">{{ product.price.toLocaleString() }}</span>
                      </s>
                    </td>
                    <td>{{ product.discountPercent }}%</td>
                    <td>{{ product.quantity }}</td>
                    <td>
                      <span class="badge badge-success" v-if="product.enabled">แสดงสินค้า</span>
                      <span class="badge badge-dark" v-else>ไม่แสดงสินค้า</span>
                    </td>
                    <td class="text-center">
                      <div class="dropdown">
                        <a href="#" class="link-unstyled border-0" data-toggle="dropdown">
                          <span class="avatar avatar-sm"><i class="fas fa-ellipsis-h"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <router-link :to="'/admin/products/'+product.id+'/edit'" class="dropdown-item text-primary">
                            <i class="far fa-edit mr-1"></i>แก้ไข
                          </router-link>
                          <a @click="deleteProduct(product.id)" href="#" class="dropdown-item text-danger"><i class="far fa-trash-alt mr-1"></i>ลบ</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <pagination :data="laravelData" @pagination-change-page="getResults" class="justify-content-end"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'productList',
    components: {},
    props: {},
    data() {
      return {
        search: '',
        laravelData: {},
        selectedProduct: []
      }
    },
    computed: {
      selectAll: {
        get: function() {
          return this.laravelData.data
            ? this.selectedProduct.length == this.laravelData.data.length &&
                this.selectedProduct.length > 0
            : false
        },
        set: function(value) {
          var selected = []

          if (value) {
            this.laravelData.data.forEach(function(product) {
              selected.push(product.id)
            })
          }
          return (this.selectedProduct = selected)
        }
      }
    },
    created() {
      this.getResults()
    },
    methods: {
      getResults(page = 1) {
          let url = '/api/admin/products?page=' + page
        if(this.search != ''){
          url = url + '&search='+ this.search
        }
        axios.get(url )
        .then(response => {
          this.laravelData = response.data
        })
      },
      resetSearch(){
        this.search = ''
        this.getResults()
      },
      searchProduct() {
        this.getResults()
      },
      toggleEnableManyProduct() {
        axios
          .post('/api/admin/products/enabled', {
            products: this.selectedProduct,
            enabled: event.target.value
          })
          .then(res => this.getResults())
      },
      deleteProduct(id){
        axios.delete('/api/admin/products/'+ id).then(res => {
          this.getResults()
          this.$notify.success({
            title: 'Success',
            message: 'ลบสินค้าสำเร็จ'
          })
        })
      },
      deleteManyProduct() {
        if (this.selectedProduct.length > 0) {
          this.$confirm(`ต้องการลบสินค้าจำนวน ${
              this.selectedProduct.length
            } ชิ้น ใช่หรือไม่`, 'ลบสินค้า', {
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            dangerouslyUseHTMLString: true,
            type: 'warning'
            }).then(() => {
          // }).then(confirm => {
            if (confirm) {
              let form = new FormData()
              form.append('_method', 'DELETE')
              this.selectedProduct.forEach(id => form.append('products[]', id))
              axios.post('/api/admin/products', form).then(res => {
                this.getResults()
                this.$notify.success({
                  title: 'Success',
                  message: 'ลบสินค้าสำเร็จ'
                })
              })
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped></style>
