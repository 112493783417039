<template>
  <section class="admin-section">
    <div class="inner-wrap container bg white pt-2 pb-2 item-hover">
      <div class="admin-btns admin-btns--sectionedit">
        <a class="ui button white basic d-block mt-1 mb-1 d-xl-flex js-modal-btn" @click.prevent="$parent.openModal(`#editStepModal-${id}`)"><i class="d-none d-xl-block edit outline icon"></i>แก้ไข</a>
        <a class="ui button white basic d-block mt-1 mb-1 d-xl-flex js-modal-btn" @click.prenvent="handleDeleteComponentModal"><i class="d-none d-xl-block trash alternate outline icon"></i>ลบ</a>
      </div>
      <a class="admin-btns admin-btns--add-section-top circular ui icon button blue js-modal-btn" @click.prevent="$parent.showSectionModal(id, 'top')"><i class="plus icon"></i></a>
      <a class="admin-btns admin-btns--add-section-bottom circular ui icon button blue js-modal-btn" @click.prevent="$parent.showSectionModal(id, 'bottom')"><i class="plus icon"></i></a>
      <div class="row p-4"><img class="w-100 h-100" src="/imgs/contents/step-1-mb.svg"></div>
    </div>
    <div class="modal fade" role="dialog" tabindex="-1" :id="`editStepModal-${id}`">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title color black h3">เลือกรูปแบบ</p>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 steps-list">
                <div class="row align-items-center mt-2">
                  <div class="col-2">
                    <p class="ui text big color black">รูปแบบที่ 1</p>
                  </div>
                  <div class="col ui checkbox">
                    <input type="radio" name="step-style" v-model="selected" value="1" id="style-1">
                    <label class="d-block" for="style-1"><img class="w-100" src="/imgs/contents/step-style-1.png"></label>
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <div class="col-2">
                    <p class="ui text big color black">รูปแบบที่ 2</p>
                  </div>
                  <div class="col ui checkbox">
                    <input type="radio" name="step-style" v-model="selected" value="2" id="style-2">
                    <label class="d-block" for="style-2"><img class="w-100" src="/imgs/contents/step-style-2.png"></label>
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <div class="col-2">
                    <p class="ui text big color black">รูปแบบที่ 3</p>
                  </div>
                  <div class="col ui checkbox">
                    <input type="radio" name="step-style" v-model="selected" value="3" id="style-3">
                    <label class="d-block" for="style-3"><img class="w-100" src="/imgs/contents/step-style-3.png"></label>
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <div class="col-2">
                    <p class="ui text big color black">รูปแบบที่ 4</p>
                  </div>
                  <div class="col ui checkbox">
                    <input type="radio" name="step-style" v-model="selected" value="4" id="style-4">
                    <label class="d-block" for="style-4"><img class="w-100" src="/imgs/contents/step-style-4.png"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a class="ui button red basic text" data-dismiss="modal"><span class="ui text large">ยกเลิก</span></a>
            <button class="ui button blue text d-flex align-items-center" @click.prevent="updateData" :disabled="onSubmit">
              <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" v-show="onSubmit"></span>
              <span class="ui text large">บันทึก</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'step',
    components: {},
    props: {
      themeData: {
        type: Object,
        default: () => ({selected: "1"})
      },
      id: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        onSubmit: false,
        selected: "1"
      }
    },
    computed: {},
    created() {
      if (this.themeData) {
        this.selected = this.themeData.selected
      }
    },
    methods: {
      updateData () {
        axios.post(`/api/admin/themes/${this.id}`, {
          _method: 'PATCH',
          data: {selected: this.selected}
        })
        .then((res) => {
          // this.onSubmit = false
          this.$parent.getTheme()
          $(`#editStepModal-${this.id}`).modal('hide')
        })
        .catch(err => {
          this.$message.error('มีข้อผิดพลาดบางอย่างเกิดขึ้น กรุณาลองใหม่อีกครั้ง')
        }).finally(() => this.onSubmit = false)
      },
      handleDeleteComponentModal () {
        this.$emit('onDelete', this.id)
        this.$parent.openModal('#deleteSectionModal')
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
